@use '../../../styles/mixins.scss';
@use '../../../styles/colors.scss';

.case-studies-related {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2rem;
  gap: 5rem;

  @include mixins.lg {
    flex-direction: row;
    gap: 1rem;
  }

  .case-studies-related__company-case-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: colors.$mainBackgroundColor;
    width: 100%;
    height: 250px;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);

    @include mixins.lg {
      width: calc(100% / 3 - 1rem);
    }
  }
  .case-studies-related__company-logo-container {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .case-studies-related__company-logo {
      width: 70%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .case-studies-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0;
    opacity: 0;
    transition: 0.2s ease-in-out;

    .case-studies-content-container__title {
      font-size: 1.6rem;
      font-weight: 700;
      color: colors.$mainFontColor;
      margin-bottom: 1rem;
    }

    .case-studies-content-container__read-more {
      font-size: 1.3rem;
      font-weight: 300;
      color: colors.$secondThemeColor;
      margin-bottom: 1rem;
    }
  }

  .case-studies-content-container--active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    background-color: colors.$inputColor;
    padding: 2rem;
    opacity: 1;
    transition: 0.2s ease-in-out;

    .case-studies-content-container__title {
      font-size: 1.6rem;
      font-weight: 700;
      color: colors.$mainFontColor;
      margin-bottom: 1rem;
    }

    .case-studies-content-container__read-more {
      font-size: 1.3rem;
      font-weight: 300;
      color: colors.$secondThemeColor;
      margin-bottom: 1rem;
    }
  }

  .case-studies-related__no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    color: colors.$mainFontColor;
    margin-top: 2rem;
  }

  .case-studies-related__no-results-text {
    font-size: 2.4rem;
    font-weight: 600;
    color: colors.$mainFontColor;
  }
}
