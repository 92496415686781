@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.features__movie-tutorial {
  width: 90vw;
  height: 100%;
  max-width: 1200px;
  padding-bottom: 3rem;

  .movie-tutorial__text {
    font-size: clamp(2rem, 2.5vw, 2.2rem);
    font-weight: 700;
    text-align: center;
    margin: 5rem 0;
  }

  .movie-tutorial__video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    .movie-tutorial__video {
      position: absolute;
      width: 100%;
      height: 95%;
      border: 2px solid colors.$borderBottomLinesColor;
    }
  }
}
