@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.solutions-stats {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: colors.$solutionsStatsBackgroundColor;

  .solutions-stats-container {
    width: 90vw;
    max-width: 1200px;
    padding: 10rem 0 5rem 0;

    .solutions-stats__title {
      font-size: clamp(1.6rem, 2.5vw, 3.2rem);
      color: colors.$mainFontColor;
      text-align: center;
      margin-bottom: 4rem;
    }

    .solutions-stats-list {
      display: flex;
      flex-direction: column;
      gap: 4rem;

      @include mixins.md {
        flex-direction: row;
      }

      .solutions-stats-list-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 4rem 2rem;
        gap: 2rem;
        border-radius: 0.8rem;

        @include mixins.md {
          min-height: 250px;
        }

        @include mixins.lg {
          min-height: 230px;
        }

        @include mixins.xl {
          min-height: 210px;
        }

        .solutions-stats-list-item__icon-container {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;

          .solutions-stats-list-item__icon {
            width: 50px;
            height: 50px;
            color: colors.$secondThemeColor;
          }
        }

        .solutions-stats-list-item__content {
          font-size: clamp(1.4rem, 2vw, 1.6rem);
          text-align: center;
          font-weight: 500;

          span {
            font-weight: 700;
          }

          span.numbers {
            font-size: clamp(1.6rem, 2vw, 2rem);
          }

          a {
            text-decoration: underline;
            color: colors.$mainFontColor;
            transition: 0.2s ease-in-out;
          }

          a:hover {
            color: colors.$firstThemeColor;
          }

          img {
            height: 100%;
            max-width: 100%;
            max-height: 80px;
          }
        }
      }
    }
  }
}
