@use './breakpoints.scss';

// Medium devices
@mixin md {
  @media (min-width: #{breakpoints.$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{breakpoints.$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{breakpoints.$screen-xl-min}) {
    @content;
  }
}

// XXL devices
@mixin xxl {
  @media (min-width: #{breakpoints.$screen-xxl-min}) {
    @content;
  }
}

@mixin sliderXxl {
  @media (min-width: #{breakpoints.$screen-sliderXxl-min}) {
    @content;
  }
}

// MOBILE
@mixin mobileSm {
  @media (min-width: #{breakpoints.$mobile-sm-min}) {
    @content;
  }
}

@mixin mobileMd {
  @media (min-width: #{breakpoints.$mobile-md-min}) {
    @content;
  }
}

@mixin mobileLg {
  @media (min-width: #{breakpoints.$mobile-lg-min}) {
    @content;
  }
}
