@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.solutions-logos__title-container {
  margin: 5rem 0 2rem 0;
  .solutions-logos__title {
    font-size: clamp(1.6rem, 2.5vw, 3.2rem);
  }
}

.solutions-logos {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .solutions-logos-container {
    width: auto;
    position: relative;
    margin-bottom: 5rem;

    .solutions-logos__slider {
      animation: scroll 25s linear infinite;
      display: flex;
      align-items: center;
      width: calc(300px * 10);
      .solutions-logos__slide {
        width: 300px;
        .solutions-logos__logo {
          width: 300px;
        }
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-300px * 5));
  }
}
