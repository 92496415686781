@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.text-input {
  background-color: colors.$brilliance;
  width: 65%;
  padding: 1.5rem 1.5rem;
  font-family: 'Open Sans', sans-serif;
  border-radius: 0.4rem;
  border: 1px solid colors.$spanishGrey;

  &::placeholder {
    color: colors.$heroPlaceholderColor;
    font-weight: 300;
  }
  &:focus {
    outline: none;
  }
}
