@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.become-partner-brochure {
  width: 100%;
  .become-partner-brochure__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;

    @include mixins.md {
      padding: 10rem 0;
    }

    .become-partner-brochure__picture--mobile {
      display: flex;
      justify-content: center;
      width: 100%;

      @include mixins.md {
        display: none;
      }
    }

    .become-partner-brochure__picture--desktop {
      display: none;
      width: 100%;

      @include mixins.md {
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: 900px;
      }

      .become-partner-brochure__image {
        width: 90%;
        max-width: 1200px;
      }
    }

    .brochure-info {
      margin-top: 4rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mixins.md {
        margin-top: 8rem;
      }

      .brochure-info__text {
        font-size: 1.6rem;
        font-weight: 300;
        color: colors.$mainFontColor;
        margin-bottom: 1.5rem;
      }

      .brochure-info__button {
        width: 90%;
        border: none;
        background-color: colors.$secondThemeColor;
        border-radius: 0.2rem;

        @include mixins.md {
          max-width: 240px;
        }

        .brochure-info__button-link {
          display: block;
          padding: 1rem 0;
          width: 100%;
          text-transform: uppercase;
          font-weight: 700;
          color: colors.$mainBackgroundColor;
          font-size: 1.4rem;
        }
      }
    }
  }
}
