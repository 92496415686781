@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.blog-articles-page {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .blog-articles-page__header {
    .blog-articles-page__header-text {
      color: colors.$mainFontColor;
      font-weight: 700;
      font-size: clamp(2.4rem, 3.5vw, 4.2rem);
      text-align: center;
      margin-top: 3rem;

      @include mixins.lg {
        margin-top: 0;
        margin-bottom: 2rem;
        text-align: left;
      }
    }
  }

  .blog-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;

    @include mixins.md {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    @include mixins.lg {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .blog-list__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 3rem 0;

    .blog-list__button {
      min-width: 100px;
      padding: 1rem;
      background-color: colors.$secondThemeColor;
      border: none;
      border: 2px solid transparent;
      border-radius: 0.4rem;
      font-family: 'Open Sans', sans-serif;
      color: colors.$mainBackgroundColor;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: colors.$mainBackgroundColor;
        border: 2px solid colors.$secondThemeColor;
        color: colors.$secondThemeColor;
      }
    }
    .blog-list__button:disabled {
      opacity: 0.3;

      &:hover {
        cursor: auto;
        background-color: colors.$secondThemeColor;
        color: colors.$mainBackgroundColor;
        border: 2px solid transparent;
      }
    }
  }
}
