@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.signed-up-page {
  color: colors.$mainFontColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .signed-up-page-container {
    width: 100%;
    margin: 8rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .signed-up-page__content {
      display: flex;
      width: 90%;
      max-width: 1200px;
      justify-content: center;
      margin: 0 0 5rem 0;

      .signed-up-page__content-text {
        width: 100%;
        text-align: center;

        .signed-up-page__title {
          font-size: clamp(2.4rem, 3vw, 4rem);
          margin-bottom: 2rem;
        }

        .signed-up-page__text {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;
          margin-bottom: 2rem;
        }

        .signed-up-page__text--unsub {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;

          a {
            color: colors.$secondThemeColor;
          }
        }

        .signed-up-page__text--deadline {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;
          margin-top: 2rem;
          font-weight: 700;

          span {
            color: colors.$secondThemeColor;
          }
        }

        @include mixins.md {
          text-align: left;
        }
        @include mixins.lg {
          margin: 7rem 0;
        }
      }
      .signed-up-page__content-icon-container {
        display: none;

        @include mixins.md {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .signed-up-page__content-icon {
            width: 50%;
            color: colors.$secondThemeColor;
          }
        }
      }
    }
  }
}
