@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.why-cux-awards {
  width: 100%;
  height: 100%;
  background-color: colors.$pricingQuestionsHeaderBackgroundColor;
  display: flex;
  justify-content: center;

  .why-cux-awards-container {
    width: 90%;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    margin: 6rem;
    color: colors.$mainFontColor;

    @include mixins.md {
      padding-top: 2rem;
    }

    .why-cux-awards__header {
      width: 100%;
      text-align: center;
      margin-bottom: 3rem;

      .why-cux-awards__header-title {
        font-weight: 700;
        font-size: clamp(1.8rem, 2.5vw, 2.2rem);
        margin-bottom: 3rem;
      }

      .why-cux-awards__header-subtitle {
        font-weight: 300;
        font-size: 1.6rem;
        text-align: left;

        @include mixins.md {
          text-align: center;
        }
      }
    }
  }

  .why-cux-awards__awards-list-container {
    width: 100%;

    .why-cux-awards__awards-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      @include mixins.md {
        gap: 1rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }

      .award-item {
        width: 100%;
        background-color: colors.$mainBackgroundColor;

        @include mixins.md {
          width: 49%;
          margin-bottom: 1rem;
        }

        .award-item__logo-container {
          width: 100%;
          height: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3rem 0;
          position: relative;

          &::after {
            position: absolute;
            content: url('/awards_ribbon.svg');
            top: -16px;
            right: 0px;
            transform: scale(0.5);

            @include mixins.md {
              top: 0;
              right: 20px;
              transform: scale(1);
            }
          }

          @include mixins.md {
            height: 240px;
          }

          .award-item__logo {
            width: 70%;
            height: 100%;
            object-fit: contain;
          }

          .award-item__logo--small {
            width: 50%;
          }
        }

        .award-item__content-container {
          border-top: 1px solid colors.$borderBottomLinesColor;
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include mixins.md {
            height: 180px;
          }

          @include mixins.lg {
            height: 130px;
          }

          .award-item-wrapper {
            @include mixins.md {
              display: flex;
              flex-direction: column;
              height: 120px;
            }

            .award-item__status,
            .award-item__secondary-text {
              font-weight: 300;
              font-size: 1.2rem;

              @include mixins.md {
                font-size: 1.6rem;
              }
            }
            .award-item__competition {
              font-weight: 700;
              font-size: 1.6rem;

              @include mixins.md {
                font-size: 2rem;
              }
            }
          }

          .award-item__content-container-read-more {
            margin-top: 1rem;

            .award-item__read-more {
              color: colors.$secondThemeColor;
              font-weight: 300;
              font-size: 1rem;
              display: flex;
              align-items: center;
              gap: 0.5rem;

              @include mixins.md {
                font-size: 1.4rem;
                // line-height: 200%;
              }

              .award-item__read-more-icon {
                width: 5px;
                height: 10px;
              }
            }
          }
        }
      }
    }
  }
}
