@use '../../../../../styles/colors.scss';
@use '../../../../../styles/mixins.scss';

.pricing {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: colors.$mainFontColor;
  font-weight: 300;
  height: 100%;
  width: 100%;

  @include mixins.lg {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }

  .pricing__pricing-counter {
    width: 100%;

    @include mixins.lg {
      max-width: 380px;
    }

    @include mixins.xl {
      min-height: 350px;
    }

    .pricing-counter-container {
      width: 100%;
      padding: 2rem;
      border: 1px solid rgb(230, 230, 230);
      border-radius: 0.5rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;

      @include mixins.lg {
        min-height: 450px;
      }

      .pricing-counter__step-text {
        span {
          color: #09d2d4;
          font-weight: 700;
        }
      }
    }
  }

  .pricing__pricing-summary {
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;

    @include mixins.lg {
      min-height: 430px;
      max-width: 300px;
    }
    @include mixins.xl {
      max-width: 100%;
    }

    .pricing-summary-container {
      display: flex;
      flex-direction: column;
      background-color: colors.$firstThemeColor;
      width: 100%;
      height: 100%;
      padding: 2rem;
      border-radius: 0.5rem;
      color: $mainBackgroundColor;

      @include mixins.lg {
        min-height: 450px;
        max-width: 300px;
      }
      @include mixins.xl {
        max-width: 300px;
      }

      .summary__price-button {
        background-color: colors.$secondThemeColor;
        border: none;
        border: 2px solid transparent;
        font-family: 'Open Sans', sans-serif;
        border-radius: 0.5rem;
        color: $mainBackgroundColor;
        margin: 2rem 0;
        transition: 0.2s ease-in-out;

        &:hover {
          color: colors.$secondThemeColor;
          background-color: $mainBackgroundColor;
          border: 2px solid colors.$secondThemeColor;
          cursor: pointer;
        }

        .summary__price-button-link {
          display: block;
          width: 100%;
          height: 100%;
          padding: 1rem 0.6rem;
          text-transform: uppercase;
        }
      }
    }
  }
}

.pricing-text-container {
  margin: 5rem 0 5rem 0;
  color: colors.$mainFontColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include mixins.lg {
    margin: 7rem 0 5rem 0;
  }

  @include mixins.xl {
    margin: 10rem 0 7rem 0;
    text-align: left;
    align-items: flex-start;
  }

  .pricing-text__text {
    font-weight: 400;
    font-size: clamp(2.4rem, 4vw, 4.2rem);
  }

  span {
    font-weight: 700;
  }

  .pricing-text__button {
    background-color: colors.$secondThemeColor;
    border: none;
    border: 2px solid transparent;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0.5rem;
    color: colors.$mainBackgroundColor;
    margin: 2rem 0;
    transition: 0.2s ease-in-out;

    &:hover {
      color: colors.$secondThemeColor;
      background-color: colors.$mainBackgroundColor;
      border: 2px solid colors.$secondThemeColor;
      cursor: pointer;
    }

    .pricing-text__link {
      display: block;
      width: 100%;
      height: 100%;
      padding: 1rem 1rem;
      text-transform: uppercase;
    }
  }
}

.pricing-header {
  .pricing-header__text {
    color: colors.$mainFontColor;
    font-weight: 700;
    font-size: clamp(2.4rem, 3.5vw, 4.2rem);
    text-align: center;
    margin-bottom: 3rem;

    @include mixins.xl {
      text-align: left;
      margin-bottom: 5rem;
    }
  }
}
