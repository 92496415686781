@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.features-tabs {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  padding: 6rem 0 0 0;
  color: colors.$mainFontColor;

  @include mixins.lg {
    padding: 6rem 0;
  }

  .features-tabs-container {
    width: 90vw;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .features-tabs-mobile-container {
      @include mixins.lg {
        display: none;
      }

      .features-tab-item {
        width: 100%;
        height: 100%;
        // max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4rem;

        .features-tab-item__title {
          width: 100%;
          text-align: left;
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 2rem;
        }

        .features-tab-item__content {
          width: 100%;
          text-align: left;
          font-size: 1.6rem;
          font-weight: 300;
          margin-bottom: 2rem;
        }

        .features-tab-item__picture {
          width: 100%;
          height: 100%;

          .features-tab-item__image {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .features-tabs-desktop-container {
      display: none;
    }

    @include mixins.lg {
      .features-tabs-desktop-container {
        display: block;
        width: 100%;
        position: relative;

        .features-tabs-desktop-container__tab-container {
          width: 100%;
          display: flex;
          gap: 5rem;

          .features-desktop-tab-item {
            width: 45%;
            margin-bottom: 2rem;

            .features-tab-item__title {
              font-size: 2rem;
              font-weight: 700;
              transition: 0.2s ease-in-out;
              margin-bottom: 1rem;
            }

            .features-tab-item__content {
              font-size: 1.6rem;
              font-weight: 300;
            }

            &:hover {
              cursor: default;

              .features-tab-item__title {
                color: colors.$secondThemeColor;
              }
            }
          }

          .features-desktop-tab-item-image {
            width: 50%;
            position: absolute;
            right: 0;
            top: 0;
            display: none;

            .features-tab-item__picture {
              width: 50%;
              // max-height: 400px;

              .features-tab-item__image {
                width: 100%;
                max-width: 500px;
                height: 100%;
                max-height: 450px;
              }
            }
          }
          .features-desktop-tab-item-image--active {
            display: block;
            animation: showAnswer 0.3s ease-in-out forwards;
          }
        }

        .features-desktop-tabs__features-desktop-tab-item.features-desktop-tab-item.features-desktop-tab-item--active {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: -12px;
            top: 0;
            width: 5px;
            height: 100%;
            background-color: colors.$secondThemeColor;
            animation: showBullet 0.3s ease-in-out forwards;
          }

          .features-tab-item__title {
            color: colors.$secondThemeColor;
          }
        }
      }
    }
  }
}

@keyframes showImage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes showBullet {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}
