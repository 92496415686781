@use '../../styles/mixins.scss';
@use '../../styles/colors.scss';

.solutions {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  color: colors.$mainFontColor;
  overflow: hidden;

  @include mixins.lg {
    padding-top: 4rem;
  }

  .solutions-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .breadcrumbs {
      width: 90vw;
      max-width: 1200px;
      text-align: left;
      font-size: clamp(1.3rem, 2vw, 1.5rem);
      font-weight: 300;
      margin-bottom: 2rem;
      text-align: left;

      @include mixins.lg {
        margin-top: 0;
      }
    }

    .solutions-container__header {
      display: flex;
      gap: 1.5rem;
      max-width: 1200px;
      width: 90vw;

      .solutions-container__header-icon {
        width: clamp(2.5rem, 3vw, 4rem);
        color: colors.$secondThemeColor;
      }

      .solutions__header-title {
        font-size: clamp(2rem, 2.5vw, 3rem);
        font-weight: 700;
      }
    }
    .solutions-container__top-content {
      display: flex;
      flex-direction: column;
      width: 90vw;
      max-width: 1200px;

      @include mixins.md {
        flex-direction: row;
        gap: 8rem;
      }

      .solutions__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: clamp(1.4rem, 1.5vw, 1.6rem);
        font-weight: 300;
        margin-top: 3rem;
        line-height: 150%;
        width: 90vw;
        max-width: 1200px;

        .solutions__description-content {
          width: 100%;
          margin: 2rem 0 3rem 0;

          span {
            font-weight: 700;
          }

          br {
            display: block;
            margin-top: 3rem;
            content: '';
          }
        }
        .solutions__description-badges {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 3rem;
          height: 100%;
          margin-top: 4rem;

          @include mixins.md {
            justify-content: flex-start;
            align-items: center;
            width: 50%;
            margin-top: 0;
          }

          .solutions__medal-image {
            width: calc(90vw / 3 - 2rem);
            max-width: 160px;

            @include mixins.md {
              width: calc(100% / 3 - 2rem);
              max-width: 130px;
            }
          }
        }
      }
      .features__rating {
        @include mixins.md {
          width: 40%;
        }
      }
    }
    .solutions-content {
      width: 90vw;
      max-width: 1200px;

      .solutions-content__top-text {
        font-size: clamp(1.6rem, 2vw, 2.4rem);
        margin: 5rem 0;
        font-weight: 300;
      }
    }
  }
}

// Solutions main page

.solutions-page {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .solutions-page-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 20rem 0;

    @include mixins.lg {
      flex-direction: row;
      align-items: flex-start;
      padding: 17rem 0 20rem 0;
    }

    .solutions-page__links-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      width: 100%;
      text-align: center;
      margin-bottom: 5rem;
      flex-wrap: wrap;

      @include mixins.lg {
        text-align: left;
        flex-direction: row;
        justify-content: space-between;
        margin: 10rem 0 0 0;
      }

      .solutions-page__link {
        width: 100%;
        font-size: clamp(1.6rem, 2.5vw, 2.2rem);
        font-weight: 300;
        border: 1px solid colors.$boxesBorderColor;
        border-radius: 0.8rem;
        padding: 2rem 1rem;
        color: colors.$mainFontColor;
        text-align: center;
        transition: 0.2s ease-in-out;

        @include mixins.lg {
          width: calc(100% / 3.3);
          margin-bottom: 3rem;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$blogPreviewHoverColor;
          color: colors.$secondThemeColor;
          border: 1px solid colors.$secondThemeColor;
        }
      }
    }

    .solutions-page__content {
      width: 100%;
      color: colors.$mainFontColor;

      .solutions-page__content-container {
        .solutions-page__content-breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .solutions-page__content-breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .solutions-page__header {
          display: flex;
          align-items: center;
          margin-bottom: 5rem;
          gap: 2rem;

          .solutions-page__header-icon {
            width: 30px;
            height: 30px;
            color: colors.$secondThemeColor;
          }

          .solutions-page__header-text {
            font-size: 4rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
