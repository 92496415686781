@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.become-partner-members {
  width: 100%;
  background-color: colors.$becomePartnerFaqPerksBackground;
  display: flex;
  justify-content: center;
  padding: 5rem 0;

  @include mixins.md {
    padding: 10rem 0;
  }

  .become-partner-members__container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 3rem;
    max-width: 1200px;
    background-color: colors.$mainBackgroundColor;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    .become-partner-members__top-text {
      font-size: clamp(2rem, 2.5vw, 2.2rem);
      font-weight: 700;
      text-align: center;

      .become-partner-members__top-text--color {
        color: colors.$secondThemeColor;
        margin-bottom: 2rem;
        white-space: nowrap;

        @include mixins.md {
          white-space: wrap;
        }
      }
    }

    .become-partner-members__logos-container {
      width: 100%;
      max-width: 500px;
      display: flex;
      gap: 5vw;
      justify-content: center;
      align-items: center;

      .become-partner-members__logo-picture {
        width: 100%;
        max-width: 150px;
        display: flex;
        justify-content: center;
        margin-top: 5rem;

        .become-partner-members__logo {
          width: 100%;
        }
      }
    }

    .members-info {
      margin-top: 5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mixins.md {
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
      }

      .members-info__text {
        font-size: 1.6rem;
        font-weight: 300;
        color: colors.$mainFontColor;
        margin-bottom: 1.5rem;

        @include mixins.md {
          margin-bottom: 0;
        }
      }

      .members-info__button {
        width: 100%;
        border: none;
        background-color: colors.$secondThemeColor;
        border-radius: 0.2rem;
        max-width: 200px;

        .members-info__button-link {
          display: block;
          padding: 1rem 0;
          width: 100%;
          text-transform: uppercase;
          font-weight: 700;
          color: colors.$mainBackgroundColor;
          font-size: 1.4rem;
        }
      }
    }
  }
}
