@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.newsletter {
  background-color: colors.$firstThemeColor;
  color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  width: 100%;

  .newsletter__container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 3rem 0;
    width: 90%;

    @include mixins.lg {
      flex-direction: row;
    }

    .newsletter__text-container {
      margin-bottom: 2rem;
      width: 100%;

      @include mixins.lg {
        margin-bottom: 0;
        width: 50%;
      }

      .newsletter__text {
        font-size: clamp(2rem, 2.7rem, 3.2rem);
        font-weight: 400;
        text-align: center;

        @include mixins.lg {
          text-align: left;
        }

        .newsletter__text--bold {
          font-weight: 700;
        }
      }
    }
  }
  .newsletter-form__container {
    width: 100%;

    @include mixins.lg {
      width: 50%;
    }

    .newsletter-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .newsletter-form__required-input-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 2rem;
        width: 100%;

        @include mixins.md {
          flex-direction: row;
        }

        .newsletter-form__input-second-phone-number {
          display: none;
        }

        .newsletter-form__input {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$mainBackgroundColor;
          color: colors.$mainBackgroundColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$mainBackgroundColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }

        .newsletter-form__input--error {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$newsletterErrorFormColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }
      }
      .newsletter-form__input-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 2rem;
        width: 100%;

        @include mixins.md {
          flex-direction: row;
        }

        .newsletter-form__input-second-phone-number {
          display: none;
        }

        .newsletter-form__input {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$mainBackgroundColor;
          color: colors.$mainBackgroundColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$mainBackgroundColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }

        .newsletter-form__input--error {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$newsletterErrorFormColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }
      }
      .newsletter-form__marketing-offers {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .marketing-offers__checkbox-container {
          align-items: center;
          background-color: transparent;
          border: 1px solid colors.$mainBackgroundColor;
          display: flex;
          height: 20px;
          justify-content: center;
          position: relative;
          width: 20px;
          &:hover {
            cursor: pointer;
          }

          .marketing-offers__checkbox {
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
          .marketing-offers__checkbox-icon {
            display: none;
            left: 0;
            position: absolute;
            top: 0;
          }
          .marketing-offers__checkbox-icon--active {
            display: block;
            height: 18px;
            width: 18px;
          }
        }

        .marketing-offers__checkbox-container--error {
          align-items: center;
          background-color: transparent;
          border: 1px solid colors.$newsletterErrorFormColor;
          display: flex;
          height: 20px;
          justify-content: center;
          position: relative;
          width: 20px;
          &:hover {
            cursor: pointer;
          }
          .marketing-offers__checkbox {
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
          .marketing-offers__checkbox-icon {
            display: none;
            left: 0;
            position: absolute;
            top: 0;
          }
          .marketing-offers__checkbox-icon--active {
            display: block;
            height: 18px;
            width: 18px;
          }
        }

        .marketing-offers__text {
          width: 90%;
          display: inline;

          span {
            text-decoration: underline;
          }

          @include mixins.md {
            width: 95%;
          }
        }

        .marketing-offers__text--error {
          width: 90%;
          color: colors.$newsletterErrorFormColor;
          display: inline;

          span {
            text-decoration: underline;
          }

          @include mixins.md {
            width: 95%;
          }
        }
      }
      .newsletter-form__submit-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .newsletter-form__submit {
          align-items: center;
          background: colors.$secondThemeColor;
          border-radius: 0.5rem;
          display: flex;
          justify-content: center;
          margin-top: 1.5rem;
          position: relative;

          .newsletter-form__submit-input {
            background-color: transparent;
            border-radius: 0.5rem;
            border: none;
            color: colors.$mainBackgroundColor;
            font-size: 1.4rem;
            height: 100%;
            padding: 1rem 3rem 1rem 0.8rem;
            text-transform: uppercase;
            width: 100%;

            &:hover {
              box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
              cursor: pointer;
            }
          }
          .newsletter-form__submit-icon {
            position: absolute;
            right: 10%;
            width: 1.2rem;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
