.to-the-top-button {
  display: none;
}
.to-the-top-button--active {
  display: block;
  position: fixed;
  background-color: colors.$secondThemeColor;
  bottom: 40px;
  right: 25px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: none;
  z-index: 2;

  &:hover {
    cursor: pointer;
  }

  .to-the-top-button__icon {
    width: 20px;
    height: 30px;
    padding-top: 0.4rem;
    color: colors.$mainBackgroundColor;
  }
}
