@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.pricing-hero {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .pricing-hero-container {
    width: 90%;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    padding-top: 6rem;

    @include mixins.md {
      padding-top: 2rem;
    }
  }
}

.pricing-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 5rem;

  .pricing-content__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pricing-content__header-text {
      font-size: clamp(2rem, 2.5vw, 3rem);
      color: colors.$mainFontColor;
      text-align: center;
    }
  }
}

.pricing-content {
  .pricing-content__details-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: colors.$pricingDetailsColor;
    margin-top: 2rem;

    @include mixins.md {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      width: 100%;
    }

    .pricing-details-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      max-width: 170px;

      .pricing-details-item__icon {
        width: 100%;
        max-width: 20px;
        height: 100%;
        max-height: 15px;
      }

      .pricing-details-item__text {
        width: 100%;
        height: auto;
        font-size: 1.2rem;
        font-weight: 300;
        white-space: nowrap;
      }
    }
  }
}
