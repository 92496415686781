@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.home-page-slider {
  position: relative;
  .home-page-slider-wrapper {
    align-items: center;
    background-color: colors.$firstThemeColor;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10rem 0;
    width: 100vw;

    .home-page-slider-main-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .home-page-slider__header {
        width: 90vw;
        height: 100%;
        margin-bottom: 5rem;
        max-width: 1200px;
        width: 90%;

        .home-page-slider__header-text {
          color: colors.$mainBackgroundColor;
          font-size: clamp(2rem, 2.5vw, 2.6rem);
          font-weight: 700;
          text-align: center;
        }
        .home-page-slider__header-subtitle-text {
          color: colors.$mainBackgroundColor;
          font-size: clamp(1.6rem, 2vw, 2rem);
          font-weight: 300;
          text-align: center;

          @include mixins.lg {
            padding: 0 200px;
          }

          @include mixins.xl {
            padding: 0 250px;
          }
        }
      }

      .home-page-slider-container {
        display: flex;
        height: 100%;
        justify-content: center;
        width: 90vw;
        max-width: 2000px;

        @include mixins.lg {
          width: 100vw;
        }

        .home-page-slider__home-page-slider-item {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 5rem;
          margin-bottom: 6rem;
          text-align: center;
          background-color: colors.$mainBackgroundColor;
          border-radius: 2rem 2rem 2rem 0;
          box-shadow: 0px 4px 21px rgba(5, 147, 148, 0.25);
          position: relative;
          z-index: 1;
          padding: 1.5rem 2.5rem;

          &::after {
            content: '';
            position: absolute;
            bottom: -17px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;
            border-left: 80px solid colors.$mainBackgroundColor;
            z-index: -1;
          }

          @include mixins.md {
            gap: 0;
            text-align: left;
          }

          .home-page-slider-item__home-page-slider-content {
            color: colors.$mainFontColor;
            font-size: 1.5rem;

            .home-page-slider-author {
              display: flex;
              gap: 1rem;

              .home-page-slider-author__picture {
                align-items: center;
                background-color: #ffffff;
                border-radius: 50%;
                display: flex;
                height: 48px;
                justify-content: center;
                overflow: hidden;
                width: 48px;

                .home-page-slider-author__image {
                  height: 100%;
                  max-height: 120px;
                  max-width: 120px;
                  position: relative;
                  width: 100%;
                }
              }

              .home-page-slider-author__text-container {
                color: colors.$mainFontColor;

                .home-page-slider-author__author-text {
                  text-align: left;
                  font-size: 1.4rem;
                  font-weight: 700;
                }

                .home-page-slider-author__position-text {
                  font-size: 1.2rem;
                  font-weight: 400;
                  text-align: left;
                }

                .home-page-slider-author__stars-container {
                  display: flex;
                  gap: 0.1rem;

                  .home-page-slider-author__stars {
                    width: 12px;
                    height: 12px;
                    color: #ff492c;
                  }
                }
              }
            }

            .home-page-slider-review {
              .home-page-slider-review__home-page-slider-content {
                font-size: 1.6rem;
                font-weight: 700;
                text-align: left;
                margin-top: 2rem;
              }
            }

            .home-page-slider-g2 {
              display: flex;
              margin-top: 1.5rem;

              .home-page-slider-g2__logo {
                width: 27px;
                height: 27px;
              }
              .home-page-slider-g2__text-content {
                margin-left: 0.5rem;
                font-size: 1rem;
                font-weight: 400;

                .home-page-slider-g2__posted-text {
                  color: #919191;
                }
                .home-page-slider-g2__link {
                  color: #0e6cff;
                }
              }
            }
          }

          .home-page-slider-item__home-page-slider-content {
            width: 100%;
          }

          .home-page-slider-content__home-page-slider-content {
            font-size: 1.6rem;
            font-weight: 300;
            margin-top: 2rem;
          }
        }
      }

      .swiper-pagination-bullet {
        background-color: colors.$mainBackgroundColor;
        height: 12px;
        width: 12px;
        position: relative;
        z-index: 999;
      }

      .home-page-slider__arrows {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        gap: 12rem;
        z-index: 10;

        @include mixins.lg {
          bottom: 55%;
          gap: 35vw;
        }

        @include mixins.sliderXxl {
          gap: 750px;
        }

        .home-page-slider__arrows--prev,
        .home-page-slider__arrows--next {
          width: 100%;
          height: 100%;
          border: none;
          background-color: transparent;

          &:hover {
            cursor: pointer;
          }

          .home-page-slider__arrows-icon {
            width: 15px;
            color: colors.$mainBackgroundColor;
          }
        }
      }

      @include mixins.lg {
        .swiper-slide:not(.swiper-slide-next) {
          transition: 0.2s ease-in-out;
          filter: blur(0.2rem);
          transform: scale(0.85);

          @include mixins.lg {
            max-width: 650px;
          }
        }

        .swiper-wrapper {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }

  .medals-box {
    width: 100%;
    height: auto;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 90%;

    .medals-box__medals-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
      background-color: colors.$mainBackgroundColor;
      box-shadow: 0px 2.18855px 10.9428px rgba(0, 0, 0, 0.1);
      padding: 1rem 2rem;
      width: 90%;
      border-radius: 12px;
      max-width: 1000px;

      .medals-container__medals-item {
        width: 15vw;

        @include mixins.md {
          width: auto;
        }
      }
    }
  }
}
