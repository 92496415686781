@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.pricing-details {
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  width: 100%;
  color: colors.$mainFontColor;
  padding: 3rem 0 13rem 0;
  scroll-margin-top: 120px;

  .pricing-details-container {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

// MOBILE

.pricing-details-title--mobile {
  text-align: center;
  color: colors.$mainFontColor;
  margin: 3rem 0 4rem 0;

  @include mixins.lg {
    display: none;
  }

  .pricing-details-title__title--mobile {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .pricing-details-title__subtitle--mobile {
    font-size: 1.8rem;
    font-weight: 300;
  }
}

.pricing-details--mobile {
  width: 100%;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);

  @include mixins.lg {
    display: none;
  }

  .pricing-details-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 16rem;
    left: 0;
    z-index: 3;

    @include mixins.mobileLg {
      top: 13.3rem;
    }

    .pricing-details-for-who {
      display: flex;
      width: 100%;

      .pricing-details-for-who__business,
      .pricing-details-for-who__enterprise {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 50%;
        height: 120px;
        border-radius: 0.6rem 0.6rem 0 0;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);

        .pricing-details-for-who__icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: clamp(4.4rem, 2.5vw, 5.4rem);
          height: clamp(4.4rem, 2.5vw, 5.4rem);
          border-radius: 50%;

          .pricing-details-for-who__icon {
            width: 2rem;
          }
        }
        .pricing-details-for-who__text {
          font-size: 2rem;
          font-weight: 700;
        }
      }

      .pricing-details-for-who__business {
        background-color: colors.$mainBackgroundColor;

        .pricing-details-for-who__icon-container {
          background-color: colors.$whyCuxRatingSetupColor;

          .pricing-details-for-who__icon {
            color: colors.$mainBackgroundColor;
          }
        }
        .pricing-details-for-who__text {
          color: colors.$mainFontColor;
        }
      }

      .pricing-details-for-who__enterprise {
        border: 1px solid colors.$secondThemeColor;
        border-bottom: none;

        .pricing-details-for-who__icon-container {
          background-color: colors.$secondThemeColor;

          .pricing-details-for-who__icon {
            color: colors.$mainBackgroundColor;
          }
        }

        .pricing-details-for-who__text {
          color: colors.$mainFontColor;
          text-align: center;
        }
      }

      .business-secondary {
        .pricing-details-for-who__icon-container {
          background-color: colors.$firstThemeColor;
        }
      }
    }
  }

  .pricing-details-list {
    width: 100%;

    .pricing-details-list__topic {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 1px solid colors.$pricingSeparatingLine;
      position: sticky;
      top: calc(16rem + 120px);
      background-color: colors.$mainBackgroundColor;
      z-index: 2;

      @include mixins.mobileLg {
        top: calc(13.3rem + 120px);
      }
    }

    .pricing-details-list-item {
      display: flex;
      flex-direction: column;

      .pricing-details-list-item__detail-container {
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        width: 100%;
        padding: 2.5rem;
        font-size: 1.4rem;
        color: colors.$mainFontColor;

        .pricing-details-list-item__detail-container {
          width: 90%;
        }

        .pricing-details-list-item__detail-tooltip-container {
          position: relative;
          width: 10%;
          display: flex;
          align-items: center;

          .pricing-details-list-item__detail-icon {
            width: 15px;
            height: 15px;
            max-width: 15px;
            color: #c4c4c4;
          }
          .pricing-details-list-item__tooltip {
            display: none;
            position: absolute;
            width: 200px;
            height: auto;
            top: 20px;
            right: 15px;
            padding: 0.5rem 1.5rem;
            background-color: rgba(0, 0, 0, 0.8);
            color: colors.$mainBackgroundColor;
            font-weight: 300;
            border-radius: 0.6rem;
            font-size: 1.3rem;
            z-index: 3;
          }

          .pricing-details-list-item__detail-icon:hover
            + .pricing-details-list-item__tooltip {
            display: block;
          }
        }
      }
    }
  }

  .pricing-details-list-item__details-desc-box-container {
    width: 100%;
    display: flex;

    .details-desc-box {
      width: 50%;
      min-height: 65px;
      max-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      .details-desc-box__icon {
        width: 100%;
        max-width: 30px;
        height: 100%;
        max-height: 20px;
        color: colors.$firstThemeColor;
      }

      .details-desc-box__icon--xmark {
        max-width: 27px;
        color: colors.$secondThemeColor;
      }

      .details-desc-box__text {
        font-size: 1.4rem;
        font-weight: 400;
        color: colors.$mainFontColor;
      }
    }

    .details-desc-box--business {
      background-color: colors.$seashell;
    }
    .details-desc-box--enterprise {
      background-color: colors.$pricingBoxesEnterpriseColor;
      border-left: 1px solid colors.$secondThemeColor;
      border-right: 1px solid colors.$secondThemeColor;
    }
    .details-desc-box--business-plus {
      background-color: colors.$placeboSky;
    }
  }

  .pricing-details-buttons {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: 2rem 0;
    padding: 0 0.5rem;

    .pricing-details-buttons__button {
      width: 50%;
      border: none;
      border-radius: 0.2rem;

      .pricing-details-buttons__button-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.3rem;
      }
    }

    .pricing-details-buttons__button--start {
      background-color: colors.$secondThemeColor;
      color: colors.$mainBackgroundColor;
    }

    .pricing-details-buttons__button--book {
      background-color: colors.$secondThemeColor;
      color: colors.$mainBackgroundColor;

      .pricing-details-buttons__button-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .pricing-details-buttons__button-link-icon {
          max-width: 17px;
        }
      }
    }
  }
}

// DESKTOP
.pricing-details--desktop {
  display: none;

  @include mixins.lg {
    width: 100%;
    display: flex;
  }

  .pricing-details-info-column {
    width: calc(100% / 3);
    border-top: 1px solid transparent;

    .pricing-details-top {
      color: colors.$mainFontColor;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: sticky;
      top: 130px;
      z-index: 3;
      background-color: colors.$mainBackgroundColor;
      border-bottom: 1px solid colors.$pricingSeparatingLine;

      .pricing-details-top__title {
        font-size: 2.2rem;
        font-weight: 700;
      }
      .pricing-details-top__subtitle {
        font-size: 1.8rem;
        font-weight: 300;
      }
    }

    .pricing-details-list {
      .pricing-details-list__topic {
        color: colors.$mainFontColor;
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        background-color: colors.$mainBackgroundColor;

        .pricing-details-list__topic-text {
          padding-left: 3.5rem;
          font-size: 2rem;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .details-list-item {
        width: 100%;
        height: 65px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding: 0 3.5rem;
        font-size: 1.6rem;
        columns: colors.$mainFontColor;
        font-weight: 300;

        .pricing-details-list-item__detail-tooltip-container {
          position: relative;
          width: 10%;
          display: flex;
          align-items: center;

          .pricing-details-list-item__detail-icon {
            width: 15px;
            height: 15px;
            max-width: 15px;
            color: #c4c4c4;
          }
          .pricing-details-list-item__tooltip {
            display: none;
            position: absolute;
            width: 200px;
            height: auto;
            top: 15px;
            left: 15px;
            padding: 0.5rem 1.5rem;
            background-color: rgba(0, 0, 0, 0.8);
            color: colors.$mainBackgroundColor;
            font-weight: 300;
            border-radius: 0.6rem;
            font-size: 1.3rem;
            z-index: 3;
          }

          .pricing-details-list-item__detail-icon:hover
            + .pricing-details-list-item__tooltip {
            display: block;
          }
        }
      }
      .details-list-item:nth-child(odd) {
        background-color: colors.$pricingBoxesBusinessColor;
      }
      .details-list-item:nth-child(even) {
        background-color: colors.$mainBackgroundColor;
      }
    }
  }

  .pricing-details-business-column {
    width: calc(100% / 3);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
    border-top: 1px solid transparent;

    .pricing-business-top {
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.6rem 0.6rem 0 0;
      background-color: colors.$mainBackgroundColor;
      position: sticky;
      top: 130px;
      z-index: 3;
      border-bottom: 1px solid colors.$pricingSeparatingLine;

      .pricing-business-top__icon-container {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background-color: colors.$whyCuxRatingSetupColor;
        color: colors.$mainBackgroundColor;
        display: flex;
        justify-content: center;
        align-items: center;

        .pricing-business-top__icon {
          width: 24px;
        }
      }
      .pricing-business-top__icon-container--enterprise {
        background-color: colors.$firstThemeColor;
      }
      .pricing-business-top__text {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    .pricing-details-list {
      .details-list-item {
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        columns: colors.$mainFontColor;
        font-weight: 300;
      }
      .details-list-item:nth-child(odd) {
        background-color: colors.$seashell;
      }
      .details-list-item:nth-child(even) {
        background-color: colors.$eyeBall;
      }

      .details-desc-box__icon {
        width: 24px;
        color: colors.$firstThemeColor;
      }

      .details-desc-box__icon--xmark {
        color: colors.$secondThemeColor;
      }

      .pricing-details-list__topic {
        height: 65px;
        position: sticky;
        top: 260px;
        background-color: colors.$mainBackgroundColor;
      }
    }

    .pricing-details-button {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      .pricing-details-button__button {
        display: flex;
        width: 80%;
        background-color: colors.$secondThemeColor;
        border: none;
        border-radius: 0.2rem;

        .pricing-details-button__button-link {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          height: 45px;
          color: colors.$mainBackgroundColor;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 1.4rem;

          .pricing-details-button__button-link-icon {
            width: 14px;
          }
        }
      }
    }
  }

  .pricing-details-enterprise-column {
    width: calc(100% / 3);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
    border: 1px solid colors.$secondThemeColor;
    border-radius: 0.6rem;

    .pricing-enterprise-top {
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      background-color: #fff;
      border-bottom: 1px solid #d8d8d8;
      border-radius: 0.6rem 0.6rem 0 0;
      position: sticky;
      top: 130px;
      z-index: 3;

      .pricing-enterprise-top__icon-container {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background-color: colors.$secondThemeColor;
        color: colors.$secondThemeColor;
        display: flex;
        justify-content: center;
        align-items: center;

        .pricing-enterprise-top__icon {
          width: 24px;
          color: colors.$mainBackgroundColor;
        }
      }
      .pricing-enterprise-top__text {
        font-size: 2rem;
        font-weight: 700;
        color: colors.$mainFontColor;
      }
    }

    .pricing-details-button {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .pricing-details-button__button {
        display: flex;
        width: 80%;
        background-color: colors.$secondThemeColor;
        border: none;
        border-radius: 0.2rem;

        .pricing-details-button__button-link {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          height: 45px;
          color: colors.$mainBackgroundColor;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 1.4rem;

          .pricing-details-button__button-link-icon {
            width: 18px;
          }
        }
      }
    }
  }

  .pricing-details-list {
    .details-list-item {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      columns: colors.$mainFontColor;
      font-weight: 300;
    }
    .details-list-item:nth-child(odd) {
      background-color: colors.$pricingBoxesEnterpriseColor;
    }
    .details-list-item:nth-child(even) {
      background-color: colors.$snow;
    }

    .details-desc-box__icon {
      width: 24px;
      color: colors.$firstThemeColor;
    }
    .details-desc-box__icon--xmark {
      max-width: 24px;
      color: colors.$secondThemeColor;
    }

    .pricing-details-list__topic {
      height: 65px;
      border-top: 1px solid colors.$pricingSeparatingLine;
      border-bottom: 1px solid colors.$pricingSeparatingLine;
      position: sticky;
      top: 260px;
      background-color: colors.$mainBackgroundColor;
      z-index: 2;
    }
  }
}
.details-list-item--enterprise:nth-child(odd) {
  background-color: colors.$placeboSky !important;
}
.details-list-item--enterprise:nth-child(even) {
  background-color: colors.$deutziaWhite !important;
}
