@use '../../styles/colors.scss';
@use '../../styles/mixins.scss';

.knowledge-base-article {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: colors.$mainFontColor;
  margin-top: 2rem;

  .knowledge-base-article-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-width: 1200px;
    margin: 3rem 0;

    @include mixins.lg {
      gap: 3rem;
      flex-direction: row;
    }

    .knowledge-base-article__list {
      width: 30%;
    }
    .knowledge-base-article__content {
      width: 100%;

      .knowledge-base-article__content-breadcrumbs {
        font-size: clamp(1.3rem, 2vw, 1.5rem);
        font-weight: 300;
        margin: 5rem 0 2rem 0;

        @include mixins.lg {
          margin-top: 0;
        }

        .knowledge-base-article__content-breadcrumbs-link {
          color: colors.$secondThemeColor;
        }

        .knowledge-base-article__content-breadcrumbs-link:last-child {
          text-decoration: underline;
        }
      }

      @include mixins.lg {
        width: 60%;
      }

      .knowledge-base-article__content-title {
        font-size: 3.2rem;
        font-weight: 600;
      }
      .knowledge-base-article__content-text {
        font-size: 1.6rem;
        font-weight: 300;
        margin-top: 3rem;

        ul {
          list-style: disc;
          margin-left: 4rem;

          li {
            margin: 1rem 0;
          }
        }

        ol {
          position: relative;
          margin-left: 4rem;

          li {
            margin: 1rem 0;
          }
        }

        h2 {
          font-size: clamp(2.2rem, 2.5vw, 3rem);
          font-weight: 500;
          margin: 2rem 0;
        }

        h3 {
          font-size: clamp(1.6rem, 2.5vw, 2.2rem);
          font-weight: 500;
          margin: 2rem 0;
          color: colors.$secondThemeColor;
        }

        a {
          color: colors.$secondThemeColor;
        }

        img {
          display: block;
          margin: 2rem auto;
          max-width: 100%;
          object-fit: cover;
        }

        pre {
          width: 100%;
          height: 100%;
          background-color: #f9f9f9;
          padding: 2rem 0;
          margin: 2rem 0;
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;

          code {
            color: #262626;
          }
        }

        p {
          margin: 2rem 0;
        }
      }
    }
  }
}

.knowledge-base {
  width: 100%;
  display: flex;
  justify-content: center;
  color: colors.$mainFontColor;
  margin-top: 2rem;

  .knowledge-base-container {
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    margin-top: 3rem;

    @include mixins.lg {
      flex-direction: row;
      justify-content: space-between;
    }

    .knowledge-base__search {
      width: 100%;
      font-size: clamp(2.3rem, 3vw, 4.2rem);
      margin: 2rem 0;
      text-align: center;

      @include mixins.lg {
        margin: 0;
        width: 60%;
      }

      .breadcrumbs {
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
        font-size: clamp(1.3rem, 2vw, 1.5rem);
        font-weight: 300;
        margin: 5rem 0 2rem 0;

        @include mixins.lg {
          margin-top: 0;
        }
      }

      .knowledge-base__search-text {
        font-weight: 500;

        @include mixins.lg {
          text-align: left;
          font-weight: 700;
        }
      }

      .knowledge-base-form {
        .knowledge-base-form__search-input {
          width: 100%;
          height: 40px;
          margin: 2rem 0;
          background-color: colors.$inputColor;
          border: none;
          font-family: 'Open Sans', sans-serif;
          border-radius: 0.4rem;
          font-size: clamp(1.3rem, 2.5vw, 1.5rem);
          padding-left: 2rem;
          color: colors.$mainFontColor;

          &:focus {
            outline: none;
          }
        }
      }

      .knowledge-base__search-results-container {
        width: 100%;
        margin-top: 2rem;

        .important-questions-preview-title {
          font-size: clamp(2rem, 3vw, 3.2rem);
          margin-bottom: 3rem;
          font-weight: 600;
        }

        .important-questions-preview {
          border: 2px solid colors.$boxesBorderColor;
          margin-bottom: 2rem;
          border-radius: 0.5rem;
          transition: 0.2s ease-in-out;

          .important-questions-preview__text {
            font-size: clamp(1.6rem, 2.5vw, 2rem);
            padding: 2rem 1rem;
            font-weight: 300;
          }

          &:hover {
            cursor: pointer;
            border: 2px solid colors.$secondThemeColor;
            color: colors.$secondThemeColor;
          }
        }
      }
    }
  }
}
