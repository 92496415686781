.legal-page {
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  .legal-page-container {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 3rem 0 5rem 0;
    color: colors.$mainFontColor;

    .legal-page___header {
      margin-bottom: 5rem;
      .legal-page___header-text {
        font-size: 4rem;
        font-weight: 600;
      }
    }

    .legal-page__legal-page-content {
      display: flex;

      .legal-page-content__legal-page-text {
        padding: 2.4rem;
      }
    }
  }
}
