@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.become-partner-slider {
  align-items: center;
  background-color: colors.$firstThemeColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5rem 0 10rem 0;
  width: 100vw;
  @include mixins.lg {
    padding: 0;
  }

  .become-partner-slider-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .become-partner-testimonials-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.lg {
      display: none;
    }

    .become-partner-testimonials-mobile__header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;

      .become-partner-testimonials-mobile__header-text {
        color: colors.$mainBackgroundColor;
        font-size: 2rem;
        align-self: flex-start;
        margin-top: 1rem;
        font-weight: 700;
      }
    }

    .partner-testimonials {
      width: 100%;
      display: flex;
      gap: 4rem;
      flex-direction: column;
      align-items: center;

      .partner-testimonials__review-container {
        width: 90vw;

        .partner-testimonials__review-author-box {
          width: 100%;
          background-color: colors.$becomePartnerAuthorReviewBackground;
          display: flex;
          gap: 0.5rem;
          border-radius: 0.6rem;
          padding: 1.1rem 1.8rem;
          position: relative;

          &::after {
            content: '';
            background: url(../../../public/partner_medal.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 80px;
            height: 100px;
            position: absolute;
            top: -10px;
            right: -10px;
          }

          .partner-testimonials__image-container {
            .partner-testimonials__image {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          .partner-testimonials__author-content {
            color: colors.$mainBackgroundColor;

            .partner-testimonials__author {
              font-weight: 600;
            }
          }
        }

        .partner-testimonials__review-box {
          background-color: colors.$mainBackgroundColor;
          margin-top: 2.5rem;
          padding: 3rem 1.5rem;
          border-radius: 2rem;
          box-shadow: 0px 4px 21px rgba(5, 147, 148, 0.25);
          margin-left: 1.5rem;
          position: relative;
          z-index: 1;

          &::after {
            content: '';
            position: absolute;
            top: -2.9rem;
            left: -2rem;
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;
            border-right: 80px solid colors.$mainBackgroundColor;
            transform: rotate(32deg);
            z-index: -1;
          }

          .partner-testimonials__review-text {
            font-size: 1.8rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  .become-partner-testimonials-desktop {
    width: 90vw;
    max-width: 1200px;
    display: none;

    @include mixins.lg {
      display: block;
      position: relative;

      .partner-testimonials {
        width: 50%;
        height: 700px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: colors.$mainBackgroundColor;
        padding: 7rem 3rem 0 3rem;
        position: relative;

        .partner-testimonials__header {
          display: flex;
          margin-bottom: 10rem;

          .partner-testimonials__header-text {
            font-size: 2.6rem;
            font-weight: 700;
            margin-top: 0.5rem;
          }
        }

        .partner-testimonials__review-container {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          .partner-testimonials__review-author-box {
            width: 100%;
            background-color: colors.$becomePartnerAuthorReviewBackground;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.6rem;
            padding: 2rem 1.8rem;
            position: relative;
            margin-bottom: 3rem;
            filter: blur(4px);
            transition: 0.2s ease-in-out;
            transform: scale(0.7);
            transform-origin: center left;

            &::after {
              content: '';
              background: url(../../../public/partner_medal.svg);
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px;
              height: 140px;
              position: absolute;
              top: -10px;
              right: -10px;
            }

            .partner-testimonials__image-container {
              .partner-testimonials__image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
            }

            .partner-testimonials__author-content {
              color: colors.$mainBackgroundColor;

              .partner-testimonials__author {
                font-size: 1.5rem;
                font-weight: 700;
              }

              .partner-testimonials__position {
                font-size: 1.3rem;
              }
            }
          }

          .partner-testimonials__review-author-box--active {
            width: 100%;
            background-color: colors.$becomePartnerAuthorReviewBackground;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.6rem;
            padding: 1.5rem 1.8rem;
            position: relative;
            margin-bottom: 3rem;
            filter: blur(0);
            transform: scale(1);

            &::after {
              content: '';
              background: url(../../../public/partner_medal.svg);
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px;
              height: 140px;
              position: absolute;
              top: -15px;
              right: -10px;
            }
          }

          .partner-testimonials__review-box {
            display: none;
          }

          .partner-testimonials__review-box--active {
            display: block;
            position: absolute;
            top: 33%;
            left: 110%;
            width: 80%;
            background-color: colors.$mainBackgroundColor;
            padding: 3rem 1.5rem;
            border-radius: 2rem;
            box-shadow: 0px 4px 21px rgba(5, 147, 148, 0.25);
          }

          .partner-testimonials__review-text {
            font-size: 1.8rem;
            font-weight: 700;
            position: relative;
            z-index: 1;

            &::after {
              content: '';
              position: absolute;
              top: -5.9rem;
              left: -3rem;
              width: 0;
              height: 0;
              border-top: 50px solid transparent;
              border-bottom: 50px solid transparent;
              border-right: 80px solid colors.$mainBackgroundColor;
              transform: rotate(32deg);
              z-index: -1;
            }
          }
        }
      }

      .counter-dots {
        display: flex;
        gap: 0.7rem;
        justify-content: space-between;
        width: auto;
        padding: 0.8rem;
        background-color: colors.$firstThemeColor;
        position: absolute;
        bottom: 70px;
        border-radius: 45px;
        left: 48%;

        .counter-dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: colors.$mainBackgroundColor;
          opacity: 0.5;
          transition: 0.2s ease-in-out;
        }

        .counter-dot--active {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: colors.$mainBackgroundColor;
          opacity: 1;
        }
      }
    }
  }
}
