@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.home-page-video {
  width: 100%;
  height: 100%;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$whiteout;

  .home-page-video-container {
    width: 90vw;
    max-width: 1200px;

    @include mixins.lg {
      width: 50%;
      max-width: 800px;
    }

    .home-page-video__text {
      font-size: clamp(2rem, 2.5vw, 3.2rem);
      font-weight: 800;
      text-align: center;
      margin: 5rem 0;
      color: colors.$mainFontColor;
    }

    .home-page-video__video-container {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      z-index: 2;

      @include mixins.lg {
        &::before {
          content: url('/blob.svg');
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 100px;
          left: -50px;
          z-index: -1;
        }
        &::after {
          content: url('/blob.svg');
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: -100px;
          left: 450px;
          z-index: -1;
          transform: scale(0.8);
        }
      }
      @include mixins.xxl {
        &::before {
          content: url('/blob.svg');
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 200px;
          left: -80px;
          z-index: -1;
        }
        &::after {
          content: url('/blob.svg');
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: -100px;
          left: 530px;
          z-index: -1;
          transform: scale(0.8);
        }
      }

      .home-page-video__video {
        position: absolute;
        width: 100%;
        height: 95%;
      }
    }
  }
}
