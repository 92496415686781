@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

$animationSpeed: 60s;

@keyframes _scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.logo-slider-wrapper {
  padding-bottom: 1rem;

  .logo-slider__header {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .logo-slider__header-text {
      font-size: clamp(1.2rem, 2.5vw, 1.8rem);
      font-weight: 300;
      color: colors.$mainFontColor;

      span {
        font-weight: 700;
        color: colors.$secondThemeColor;
      }
    }
  }

  .logo-slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 60%;

    .logo-slider__slider-track {
      display: inline-block;
      animation: _scroll $animationSpeed linear infinite;
      white-space: nowrap;
      &:hover {
        animation-play-state: paused;
      }
    }
    .slider-track__slide {
      width: 2385px;
      min-width: calc(2385px * 2);
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-slider__slider-logo {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
