@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.walkthrough {
  width: 100%;
  height: 100%;
  background-color: colors.$whiteout;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0;
  position: relative;

  @include mixins.lg {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .walktrough-container {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    @include mixins.lg {
      flex-direction: row;
      justify-content: space-between;
      background-image: url('/avocado_hi.svg');
      background-repeat: no-repeat;
      background-position: bottom left;
      padding: 15rem 0;
    }

    .walkthrough-text-content {
      width: 100%;

      @include mixins.lg {
        width: 45%;
      }

      .walkthrough-text-content__title {
        font-size: clamp(2rem, 2.5vw, 3.2rem);
        font-weight: 800;
        color: colors.$mainFontColor;
        margin-bottom: 3rem;
      }
      .walkthrough-text-content__subtitle {
        font-size: 1.6rem;
        font-weight: 300;
        color: colors.$mainFontColor;
        margin-bottom: 2rem;
      }
      .walkthrough-text-content__arrow-text {
        color: colors.$secondThemeColor;
        display: flex;
        align-items: center;
        font-size: clamp(1.6rem, 2vw, 1.8rem);
        font-weight: 600;

        @include mixins.lg {
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      .walkthrough-text-content__icon {
        width: 16px;
        height: 10px;

        @include mixins.lg {
          transform: rotate(270deg);
          width: 24px;
          height: 20px;
          animation: arrow 2s infinite;
        }
      }
    }

    .walkthrough__walkthrough-content {
      width: 100%;

      @include mixins.lg {
        width: 55%;
        display: none;
      }

      .walkthrough__walkthrough-content-container {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;

        @include mixins.lg {
          &::before {
            content: url('/blob.svg');
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 300px;
            left: -50px;
            z-index: -1;
          }
          &::after {
            content: url('/blob.svg');
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: -100px;
            left: 450px;
            z-index: -1;
            transform: scale(0.8);
          }
        }

        .sl-embed {
          display: flex;
          width: 100%;
          height: 100%;
          position: relative;
          padding-bottom: 75.2%;

          .sl-demo {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }

    .walkthrough__walkthrough-thumbnail-content {
      width: 100%;
      display: none;

      @include mixins.lg {
        width: 50%;
        display: block;
      }

      .walkthrough__walkthrough-thumbnail-content-container {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        transition: opacity 0.5s ease;

        @include mixins.lg {
          &::before {
            content: url('/blob.svg');
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 300px;
            left: -50px;
            z-index: -1;
          }
          &::after {
            content: url('/blob.svg');
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: -100px;
            left: 450px;
            z-index: -1;
            transform: scale(0.8);
          }
        }

        .walkthrough__walkthrough-thumbnail-image {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          transition: 0.2s ease-in-out;

          &:hover {
            cursor: pointer;
            transform: scale(1.01);
          }
        }

        .walkthrough__walkthrough-thumbnail-play-box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 130px;
          border-radius: 6px;
          background-color: colors.$mainBackgroundColor;

          .walkthrough-thumbnail-play-box__text {
            font-size: 1.4rem;
            font-weight: 700;
            color: colors.$mainFontColor;
          }

          .walkthrough-thumbnail-play-box__play-icon {
            width: 45px;
            height: 45px;
            background-color: colors.$secondThemeColor;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            animation: scale 1s infinite;

            .walkthrough-thumbnail-play-box__icon {
              width: 50%;
              color: colors.$mainBackgroundColor;
              margin-left: 3px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .walkthrough-modal {
    display: none;

    @include mixins.lg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      .walkthrough-modal__content {
        width: 100%;
        max-width: 1300px;
        height: 100%;
        max-height: 700px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        transform: scale(0.9);

        .walkthrough-modal-iframe {
          width: 90%;
          height: 90%;
          object-fit: cover;

          .walkthrough-modal-iframe__nav {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            position: fixed;
            top: -40px;
            right: 130px;
            z-index: 3;

            .walkthrough-modal-iframe__icon {
              width: 28px;
              height: 28px;
              cursor: pointer;
              color: colors.$mainFontColor;
              background-color: colors.$mainBackgroundColor;
              border-radius: 50%;
              padding: 0.2rem;
            }
          }

          .sl-embed {
            padding-bottom: calc(77.66% - 29px);
            transform: scale(0.9);

            .sl-demo {
              position: absolute;
              top: -15%;
              left: 0;
              width: 100%;
              height: 100%;
              border: none;
            }
          }
        }
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes arrow {
  0% {
    margin-left: 0.5rem;
  }
  50% {
    margin-left: 2rem;
  }
  100% {
    margin-left: 0.5rem;
  }
}
