.breadcrumbs {
  font-size: clamp(1.3rem, 2vw, 1.5rem);
  font-weight: 300;
  margin: 1rem 0 2rem 0;
  text-transform: capitalize;

  .breadcrumbs-link {
    color: colors.$secondThemeColor;
  }

  .breadcrumbs-link:not(:first-child)::before {
    content: '>';
    margin: 0 0.5rem;
    color: #262626;
    text-decoration: none;
  }
}
