@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.privacy-policy {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .privacy-policy-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 4rem 0;

    @include mixins.lg {
      flex-direction: row;
      align-items: flex-start;
    }

    .privacy-policy__links-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;
      margin-bottom: 5rem;

      @include mixins.lg {
        text-align: left;
        width: 30%;
      }

      .privacy-policy__link {
        width: 100%;
        font-size: 1.6rem;
        font-weight: 300;
        border-bottom: 1px solid colors.$borderBottomLinesColor;
        padding: 2rem 1rem;
        color: colors.$mainFontColor;

        &:hover {
          cursor: pointer;
          background-color: colors.$blogPreviewHoverColor;
          color: colors.$secondThemeColor;
        }

        @include mixins.lg {
          width: 80%;
        }
      }
      .privacy-policy__link--active {
        font-weight: 700;
      }
    }

    .privacy-policy__content {
      width: 100%;
      color: colors.$mainFontColor;

      @include mixins.lg {
        width: 70%;
      }

      .privacy-policy__content-container {
        .privacy-policy__content-breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .privacy-policy__content-breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .privacy-policy__header {
          .privacy-policy__header-text {
            font-size: 4rem;
            font-weight: 600;
            margin-bottom: 5rem;
          }
        }
      }

      .privacy-policy__content-headers {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
      }
      .privacy-policy__content-text {
        font-size: 1.6rem;
        font-weight: 300;
        margin-bottom: 2rem;
      }
    }
  }
}
