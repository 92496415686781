@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.successBanner {
  align-items: center;
  background-color: colors.$firstThemeColor;
  color: colors.$mainBackgroundColor;
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 0;
  text-transform: uppercase;
  width: 100%;

  @include mixins.lg {
    height: 50px;
  }

  .successBanner-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    height: 100%;
    justify-content: center;
    max-width: 1200px;
    width: 90%;

    @include mixins.lg {
      height: 50px;
    }

    .successBanner__text {
      font-size: clamp(1.2rem, 1.5vw, 1.4rem);
      font-weight: 600;
      text-align: center;
    }

    .successBanner__button {
      background-color: transparent;
      border-radius: 0.4rem;
      border: none;
      border: 1px solid colors.$mainBackgroundColor;
      color: colors.$mainBackgroundColor;
      font-family: 'Open Sans', sans-serif;
      font-size: 1.3rem;
      font-weight: 600;
      padding: 0.6rem;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: colors.$mainBackgroundColor;
        border: 1px solid transparent;
        color: colors.$firstThemeColor;
        cursor: pointer;
      }
    }
  }
}
