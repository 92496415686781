$becomePartnerAuthorReviewBackground: #059394;
$becomePartnerFaqPerksBackground: #f9f9f9;
$blogAnchorLinksBackground: #f8f8f8;
$blogPreviewHoverColor: #fbfbfb;
$borderBottomLinesColor: #f3f3f3;
$bottomMenuBackgroundColor: #2d363c;
$bottomMenuColorText: #fdfdfe;
$compareTableBackgroundColor: #f9f9f9;
$contactErrorFormColor: #ff3333;
$contactPageBoxColor: #f9f9f9;
$contactPageSendingStateColor: #cf1448;
$contactQuoteIconColor: #e8e8e8;
$contactStarColor: #ff492c;
$featuresFontColor: #757575;
$featuresProfitsBackgroundColor: #f8f8f8;
$featuresSolutionsBoxesBackgroundColor: #f9f9f9;
$footerBackgroundColor: #293136;
$heroInputColor: #fafafa;
$heroPlaceholderColor: #c3c3c3;
$inputColor: #f4f4f4;
$mainPageTabsSecondaryBackgroundColor: #fbfbfb;
$navbarDropdownBorderTopColor: #e8e8e8;
$navbarDropdownDescriptionFontColor: #707070;
$navbarHamburgerIconColor: #858585;
$newsletterErrorFormColor: #ff3333;
$pricingBoxesBusinessColor: #f9f9f9;
$pricingBoxesEnterpriseColor: #ffeef3;
$pricingDetailsColor: #777777;
$pricingQuestionsHeaderBackgroundColor: #f8f8f8;
$pricingSeparatingLine: #d8d8d8;
$solutionsStatsBackgroundColor: #f8f8f8;
$statisticsBackgroundColor: #f8f8f8;
$subscribedExpectationsBackgroundcolor: #f8f8f8;
$testimonialsBackgroundcolor: #f8f8f8;
$whyCuxRatingSetupColor: #ff6e26;
$whyCuxRatingSpace: #f8f8f8;

// universal colors
$boxesBorderColor: #dddddd;
$brilliance: #fdfdfd;
$cascadingWhite: #f6f6f6;
$firstThemeColor: #09d2d4;
$luckyGray: #777777;
$mainBackgroundColor: #ffffff;
$mainFontColor: #262626;
$secondThemeColor: #fc2a65;
$secondThemeColorDark: #e11a52;
$secondaryFontColor: #757575;
$spanishGrey: #979797;
$whiteout: #fbfbfb;
$doctor: #f9f9f9;
$orochimaru: #d9d9d9;
$explosiveGrey: #c4c4c4;
$seashell: #fff4ed;
$eyeBall: #fffbf9;
$placeboSky: #ebfbfc;
$deutziaWhite: #f8fefe;
$snow: #fff9fa;
