@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.compare-tabs {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  padding: 6rem 0 0 0;
  color: colors.$mainFontColor;

  @include mixins.lg {
    padding: 6rem 0;
  }

  .compare-tabs-container {
    width: 90vw;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .compare-tabs-mobile-container {
      @include mixins.lg {
        display: none;
      }

      .compare-tab-item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4rem;
        padding-bottom: 4rem;
        border-bottom: 1px solid colors.$borderBottomLinesColor;

        .compare-tab-item__title {
          width: 100%;
          text-align: left;
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 2rem;
        }

        .compare-tab-item__content {
          width: 100%;
          text-align: left;
          font-size: 1.6rem;
          font-weight: 300;
          margin-bottom: 2rem;
        }

        .compare-tab-item__button {
          width: 100%;
          border: none;
          background-color: colors.$secondThemeColor;
          margin: 3rem 0 6rem 0;
          border-radius: 0.2rem;

          .compare-tab-item__button-link {
            display: block;
            width: 100%;
            padding: 1rem 0;
            letter-spacing: 0.1rem;
            font-weight: 600;
            color: colors.$mainBackgroundColor;
          }
        }

        .compare-mobile-table {
          width: 100%;
          display: flex;
          flex-direction: column;

          .compare-mobile-table__compare-mobile-table-top {
            display: flex;
            width: 100%;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);

            .compare-mobile-table__compare-mobile-table-top-item {
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 70px;
              border-radius: 0.6rem 0.6rem 0 0;
              box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
            }

            .compare-mobile-table__compare-mobile-table-top-item:first-child {
              border: 1px solid colors.$secondThemeColor;
              border-bottom: none;
            }
          }

          .compare-mobile-perks {
            display: flex;
            flex-direction: column;
            width: 100%;

            .compare-mobile-perks__compare-perk-item {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 70px;
              border-bottom: 1px solid colors.$borderBottomLinesColor;
              border-left: 1px solid colors.$secondThemeColor;

              .compare-mobile-perks__perk {
                font-size: 1.4rem;
                font-weight: 300;
                color: colors.$mainFontColor;
              }
            }

            .compare-mobile-perks__ratings {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;

              .compare-mobile-perks__rating-item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                font-size: 1.6rem;
                height: 70px;
                gap: 0.5rem;

                .compare-mobile-perks__rating-item-icon {
                  width: 15px;
                  height: 15px;
                }
              }

              .compare-mobile-perks__rating-item:first-child {
                font-weight: 700;
                background-color: colors.$compareTableBackgroundColor;
                border-left: 1px solid colors.$secondThemeColor;
                border-right: 1px solid colors.$secondThemeColor;
              }
              .compare-mobile-perks__rating-item:last-child {
                color: colors.$secondaryFontColor;
                font-weight: 300;
                border-radius: 0 0 0.6rem 0.6rem;
              }
            }
          }

          .compare-mobile-perks:nth-last-child(2) {
            .compare-mobile-perks__ratings:last-child {
              .compare-mobile-perks__rating-item:first-child {
                border-bottom: 1px solid colors.$secondThemeColor;
                border-radius: 0 0 0.6rem 0.6rem;
                box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
              }

              .compare-mobile-perks__rating-item:last-child {
                border-radius: 0 0 0.6rem 0.6rem;
                box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
              }
            }
          }

          .compare-table-g2 {
            display: flex;
            box-shadow: 0px 4px 32px rgba(38, 38, 38, 0.1);
            border-radius: 0 0 0.6rem 0.6rem;
            padding: 2rem 4rem;
            gap: 1rem;

            .compare-table-g2__logo-container {
              .compare-table-g2__logo {
                width: 32px;
                height: 32px;
              }
            }

            .compare-table-g2__g2-text-container {
              font-size: 1.2rem;
              color: colors.$secondaryFontColor;

              .compare-table-g2__g2-text-link {
                color: colors.$firstThemeColor;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .compare-tabs-desktop-container {
      display: none;
    }

    @include mixins.lg {
      .compare-tabs-desktop-container {
        display: block;
        width: 100%;
        min-height: 700px;
        position: relative;

        .compare-tabs-desktop-container__tab-container {
          width: 100%;
          display: flex;
          gap: 5rem;

          .compare-desktop-tab-item {
            width: 40%;
            margin-bottom: 3rem;
            position: relative;

            .compare-tab-item__title {
              font-size: 2rem;
              font-weight: 700;
              transition: 0.1s ease-in-out;
              margin: 0.5rem 0;
            }

            .compare-tab-item__content {
              height: 0;
              display: none;
              font-size: 1.6rem;
              font-weight: 300;
            }

            &:hover {
              cursor: default;

              .compare-tab-item__title {
                color: colors.$secondThemeColor;
              }
            }

            .compare-tab-item__button {
              display: block;
              height: 0;
              border: none;
              background-color: colors.$secondThemeColor;
              border-radius: 0.2rem;

              .compare-tab-item__button-link {
                display: block;
                padding: 1rem;
                letter-spacing: 0.1rem;
                font-weight: 600;
                color: colors.$mainBackgroundColor;
              }
            }

            &::after {
              content: '';
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: -1.5rem;
              left: 0;
              background-color: colors.$borderBottomLinesColor;
            }
          }

          .compare-desktop-tab-item-table {
            width: 50%;
            position: absolute;
            right: 0;
            top: 0;
            display: none;

            .compare-desktop-table {
              width: 100%;
              box-shadow: 10px 20px 30px rgba(38, 38, 38, 0.05);

              .compare-desktop-table-top {
                display: flex;

                .compare-desktop-table-top__space {
                  width: 40%;
                }

                .compare-desktop-table-top__cux {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30%;
                  height: 70px;
                  border-radius: 0.6rem 0.6rem 0 0;
                  border: 1px solid colors.$secondThemeColor;
                  border-bottom: none;
                }

                .compare-desktop-table-top__other {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30%;
                  height: 70px;
                }
              }

              .compare-desktop-table__compare-desktop-table-item {
                width: 100%;
                display: flex;
                font-size: 1.6rem;
                color: colors.$mainFontColor;

                .compare-table-perks {
                  display: flex;
                  align-items: center;
                  width: 40%;
                  font-weight: 300;
                  height: 70px;
                  padding-left: 2rem;
                }

                .compare-table-cux {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 700;
                  width: 30%;
                  height: 70px;
                  border-left: 1px solid colors.$secondThemeColor;
                  border-right: 1px solid colors.$secondThemeColor;
                  gap: 0.5rem;

                  .compare-table-cux__icon {
                    width: 15px;
                    height: 15px;
                  }
                }

                .compare-table-other {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: colors.$secondaryFontColor;
                  font-weight: 300;
                  width: 30%;
                  height: 70px;
                }
              }

              .compare-desktop-table__compare-desktop-table-item:nth-child(even) {
                .compare-table-perks,
                .compare-table-cux {
                  background-color: colors.$compareTableBackgroundColor;
                }
              }
              .compare-desktop-table__compare-desktop-table-item:last-child {
                .compare-table-cux {
                  border-radius: 0 0 0.6rem 0.6rem;
                  border: 1px solid colors.$secondThemeColor;
                  border-top: none;
                }
              }
            }
          }
          .compare-desktop-tab-item-table--active {
            display: block;
            animation: showAnswer 0.01s ease-in-out forwards;
          }

          .compare-desktop-tab-item__g2 {
            display: flex;
            box-shadow: 0px 4px 32px rgba(38, 38, 38, 0.1);
            border-radius: 0 0 0.6rem 0.6rem;
            padding: 2rem 4rem;
            gap: 1rem;

            .compare-table-g2__logo-container {
              .compare-table-g2__logo {
                width: 32px;
                height: 32px;
              }
            }

            .compare-table-g2__g2-text-container {
              .compare-table-g2__g2-text {
                font-size: 1.2rem;
                .compare-table-g2__g2-text-link {
                  color: colors.$firstThemeColor;
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .compare-desktop-tabs__compare-desktop-tab-item.compare-desktop-tab-item.compare-desktop-tab-item--active {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: -12px;
            top: 0;
            width: 5px;
            height: 100%;
            background-color: colors.$secondThemeColor;
            animation: showBullet 0.01s ease-in-out forwards;
          }

          .compare-tab-item__title {
            color: colors.$secondThemeColor;
            margin: 0 0 1rem 0;
          }

          .compare-tab-item__content {
            height: 0;
            display: block;
            font-size: 1.6rem;
            font-weight: 300;
            animation: showContent 0.01s ease-in-out forwards;
          }

          .compare-tab-item__button {
            display: block;
            height: auto;
            border: none;
            background-color: colors.$secondThemeColor;
            margin: 1rem 0;
            border-radius: 0.2rem;
            animation: showButton 0.01s ease-in-out forwards;

            .compare-tab-item__button-link {
              display: block;
              padding: 1rem;
              letter-spacing: 0.1rem;
              font-weight: 600;
              color: colors.$mainBackgroundColor;
            }
          }
        }
      }
    }
  }
}

@keyframes showImage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes showBullet {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@keyframes showButton {
  0% {
    height: 0;
  }

  100% {
    height: 35px;
  }
}

@keyframes showContent {
  0% {
    height: 0;
  }

  100% {
    height: auto;
  }
}
