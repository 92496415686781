@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.home-page-stories-slider {
  position: relative;
  .home-page-stories-slider-wrapper {
    align-items: center;
    background-color: colors.$whiteout;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10rem 0;
    width: 100vw;

    .home-page-stories-slider-main-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .home-page-stories-slider__header {
        width: 90vw;
        height: 100%;
        margin-bottom: 5rem;
        max-width: 1200px;
        width: 90%;

        .home-page-stories-slider__header-text {
          color: colors.$mainFontColor;
          font-size: clamp(2rem, 2.5vw, 2.6rem);
          font-weight: 700;
          text-align: center;
        }
        .home-page-stories-slider__header-subtitle-text {
          color: colors.$mainBackgroundColor;
          font-size: clamp(1.6rem, 2vw, 2rem);
          font-weight: 300;
          text-align: center;

          @include mixins.lg {
            padding: 0 200px;
          }

          @include mixins.xl {
            padding: 0 250px;
          }
        }
      }

      .home-page-stories-slider-container {
        display: flex;
        height: 100%;
        justify-content: center;
        width: 90vw;
        max-width: 2000px;

        @include mixins.lg {
          width: 100vw;
        }

        .home-page-stories-slider__home-page-stories-slider-item {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 5rem;
          margin-bottom: 6rem;
          text-align: center;
          background-color: colors.$mainBackgroundColor;
          border-radius: 4px;
          position: relative;
          z-index: 1;
          padding: 8rem 2rem;

          @include mixins.md {
            gap: 0;
            text-align: left;
          }

          .home-page-stories-slider-item__home-page-stories-slider-content {
            color: colors.$mainFontColor;
            margin: 0 auto;
            font-size: 1.5rem;

            .home-page-stories-slider-review {
              .home-page-stories-slider-review__home-page-stories-slider-content {
                font-size: clamp(1.2rem, 2.5vw, 2.4rem);
                font-weight: 700;
                text-align: center;
                margin-top: 2rem;
              }
            }
          }

          .home-page-stories-slider-item__home-page-stories-slider-content {
            width: 100%;
          }

          .home-page-stories-slider-content__home-page-stories-slider-content {
            font-size: 1.6rem;
            font-weight: 300;
          }
        }
      }

      .stories-trial {
        margin-top: 5rem;

        @include mixins.lg {
          margin-top: 3rem;
        }

        .stories-trial__text {
          font-size: 1.6rem;
          font-weight: 300;
          color: colors.$mainFontColor;
          text-align: center;
          margin-bottom: 5rem;
        }

        .stories-trial__buttons {
          display: flex;
          justify-content: center;
          gap: 2rem;
        }
      }

      .stories-case-studies {
        display: flex;
        justify-content: center;
        margin: 2rem 0 5rem 0;
        position: relative;

        .hp-case-studies-box__left-arm {
          position: absolute;
          width: auto;
          height: auto;
          bottom: 80px;
          left: -20px;
          z-index: 2;

          @include mixins.md {
            left: -50px;
          }
        }
        .hp-case-studies-box__right-arm {
          position: absolute;
          width: auto;
          height: auto;
          bottom: 80px;
          right: -20px;
          z-index: 2;

          @include mixins.md {
            right: -50px;
          }
        }

        .hp-case-studies-box {
          position: absolute;
          bottom: 0;
          min-width: 290px;
          left: calc(50% - 145px);
          background-color: colors.$mainBackgroundColor;
          height: auto;
          border-radius: 8px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          padding: 3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;

          @include mixins.md {
            min-width: 350px;
            left: calc(50% - 175px);
          }

          .hp-case-studies-box__text {
            font-size: clamp(2rem, 2.5vw, 2.4rem);
            color: colors.$mainFontColor;
            font-weight: 800;
            text-align: center;

            span {
              color: colors.$secondThemeColor;
            }
          }

          .hp-case-studies-box__button {
            border: none;
            text-transform: uppercase;
            font-size: 1.4rem;
            color: colors.$mainFontColor;
            background-color: colors.$orochimaru;
            padding: 1.25rem 3rem;
            font-weight: 600;
            border-radius: 4px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .swiper-pagination-bullet {
        background-color: colors.$secondThemeColor;
        height: 12px;
        width: 12px;
        position: relative;
        z-index: 999;

        @include mixins.lg {
          bottom: 20px;
        }
      }

      .home-page-stories-slider__arrows {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        gap: 22rem;
        z-index: 10;

        @include mixins.lg {
          bottom: 55%;
          gap: 35vw;
        }

        @include mixins.sliderXxl {
          gap: 750px;
        }

        .home-page-stories-slider__arrows--prev,
        .home-page-stories-slider__arrows--next {
          width: 100%;
          height: 100%;
          border: none;
          background-color: transparent;

          &:hover {
            cursor: pointer;
          }

          .home-page-stories-slider__arrows-icon {
            width: 15px;
            color: colors.$mainFontColor;
          }
        }
      }

      @include mixins.lg {
        .swiper-slide:not(.swiper-slide-next) {
          transition: 0.2s ease-in-out;
          filter: blur(0.2rem);
          opacity: 0.5;
          transform: scale(0.85);

          @include mixins.lg {
            max-width: 650px;
          }
        }

        .swiper-wrapper {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
}
