@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.pricing-switcher {
  display: flex;
  justify-content: center;
  background: linear-gradient(
    0deg,
    colors.$pricingQuestionsHeaderBackgroundColor 60%,
    colors.$mainBackgroundColor 40%
  );
  width: 100%;
  min-height: 50px;
  padding-bottom: 2rem;

  .pricing-switcher-box {
    display: flex;
    gap: 30px;
    background-color: colors.$mainBackgroundColor;
    padding: 1.5rem;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;

    .pricing-switcher-box__arrow-container,
    .pricing-switcher-box__text-container {
      display: none;
    }

    .pricing-switcher-box__arrow-container--active {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 240%;
      left: 20%;
      transform: rotate(90deg);

      @include mixins.md {
        transform: rotate(0);
        top: 10%;
        left: 95%;
      }
    }
    .pricing-switcher-box__text-container--active {
      display: block;
      position: absolute;
      top: 150%;
      left: 55%;
      color: colors.$mainFontColor;
      width: 100%;
      font-size: 1.2rem;

      @include mixins.md {
        top: 30%;
        left: 115%;
      }
    }

    .pricing-switcher-box__button--check {
      position: absolute;
      background-color: colors.$secondThemeColor;
      width: 105px;
      height: 50px;
      border-radius: 50px;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .pricing-switcher-box__button--check-yearly {
      position: absolute;
      background-color: colors.$secondThemeColor;
      width: 105px;
      height: 50px;
      border-radius: 50px;
      top: 0;
      left: calc(100% - 105px);
      z-index: 0;
    }

    .pricing-switcher-box__button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 600;
      width: 75px;
      z-index: 2;
      color: colors.$explosiveGrey;

      &:hover {
        cursor: pointer;
      }
    }
    .pricing-switcher-box__button--active {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 600;
      width: 75px;
      z-index: 2;
      color: colors.$mainBackgroundColor;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.pricing-switcher-box__button--check,
.pricing-switcher-box__button--check-yearly {
  transition: left 0.3s ease;
}

.pricing-plans {
  background-color: colors.$pricingQuestionsHeaderBackgroundColor;
  display: flex;
  justify-content: center;
  width: 100%;
  color: colors.$mainFontColor;

  .pricing-plans-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 3rem;

    @include mixins.md {
      gap: 1rem;
      flex-direction: row;
      align-items: space-between;
      flex-wrap: wrap;
      padding: 3rem 0;
      width: 90%;
    }
    @include mixins.lg {
      gap: 1rem;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding: 3rem 0;
      width: 90%;
    }
  }
}

.plan-container {
  width: 90vw;
  background-color: colors.$mainBackgroundColor;
  padding: 2.5rem 0;
  border-radius: 0.6rem;
  filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.1));

  @include mixins.md {
    width: 49%;
    padding: 0;
  }
  @include mixins.lg {
    width: 90vw;
    padding: 2.5rem 0;
  }

  .pricing-top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid colors.$pricingSeparatingLine;
    height: 200px;

    @include mixins.lg {
      height: 350px;
    }

    @include mixins.xl {
      height: auto;
    }

    .pricing-top-content__icon-background {
      width: clamp(4.4rem, 2.5vw, 5.4rem);
      height: clamp(4.4rem, 2.5vw, 5.4rem);
      border-radius: 50%;
      background-color: colors.$firstThemeColor;
      display: flex;
      align-items: center;
      justify-content: center;

      .pricing-top-content__icon {
        width: clamp(2.2rem, 2.5vw, 2.3rem);
        min-width: 2.2rem;
        color: colors.$mainBackgroundColor;
      }

      .icon-trial {
        margin-left: 5px;
      }
    }

    .icon-background--trial {
      background-color: colors.$contactStarColor;
    }
    .icon-background--business {
      background-color: colors.$whyCuxRatingSetupColor;
    }

    .pricing-top-content__text--who {
      font-size: 2rem;
      font-weight: 700;
      margin-top: 1rem;
    }
    .pricing-top-content__text--desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: colors.$secondaryFontColor;
      text-align: center;
      padding: 0 2rem;
      margin: 0.5rem 0 1.5rem 0;
    }
  }

  .pricing-benefits-content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-bottom: 1px solid colors.$pricingSeparatingLine;

    @include mixins.md {
      min-height: 700px;
    }

    @include mixins.lg {
      min-height: 550px;
    }

    @include mixins.xl {
      min-height: 500px;
    }

    .pricing-benefits-content__item {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .pricing-benefits-content__icon {
        width: 15px;
        min-width: 15px;
        color: colors.$firstThemeColor;
      }

      .pricing-benefits-content__icon-xmark {
        width: 15px;
        min-width: 15px;
        color: colors.$secondThemeColor;
      }

      .pricing-benefits-content__text {
        font-size: 1.5rem;
        font-weight: 400;
        color: colors.$mainFontColor;
      }

      .pricing-benefits-content__text-secondary {
        font-size: 1.2rem;
        font-weight: 300;
        color: colors.$secondaryFontColor;
      }

      .pricing-benefits-content__icon--blue {
        color: colors.$firstThemeColor;
      }

      .pricing-benefits-content__text--blue {
        color: colors.$firstThemeColor;
        font-weight: 600;
      }
    }

    .pricing-benefits-content__item-container {
      display: flex;
      flex-direction: column;
    }
  }

  .plan-container__pricing-value-content {
    margin-top: 1.5rem;

    .pricing-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 2rem;

      .pricing-value__strike {
        font-size: 1.6rem;
        font-weight: 300;
        text-decoration: line-through;
      }
      .pricing-value__text {
        font-size: 1.6rem;
        font-weight: 300;
      }
      .pricing-value__text-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mixins.md {
          height: 90px;
        }

        @include mixins.lg {
          height: 70px;
        }

        .pricing-value__price {
          font-weight: 700;
          font-size: 2.2rem;
        }
      }

      .pricing-value__button {
        width: 100%;
        border: none;
        border-radius: 0.6rem;
        background-color: colors.$secondThemeColor;
        margin-top: 2rem;

        @include mixins.lg {
          margin-top: 0;
        }

        .pricing-value__button--link {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          padding: 1rem;
          font-family: 'Open Sans', sans-serif;
          color: colors.$mainBackgroundColor;
          text-transform: uppercase;
          font-size: 1.3rem;
          font-weight: 600;

          .pricing-value__button--link-icon {
            width: 1.6rem;
          }
        }
      }

      .pricing-value__compare-link {
        display: none;
        color: colors.$secondaryFontColor;
        text-decoration: underline;

        @include mixins.md {
          display: block;
          margin-top: 1.5rem;
        }
      }

      .pricing-value__compare-link--hidden {
        visibility: hidden;
      }
    }
  }
}

.plan-container--enterprise {
  padding-top: 0;

  .pricing-top-content {
    background-color: colors.$secondThemeColor;
    padding-top: 2.5rem;
    height: 200px;

    @include mixins.lg {
      height: 375px;
    }

    @include mixins.xl {
      height: auto;
    }

    .pricing-top-content__text--who,
    .pricing-top-content__text--desc {
      color: colors.$mainBackgroundColor;
    }

    .pricing-top-content__icon-background {
      background-color: colors.$mainBackgroundColor;

      .pricing-top-content__icon {
        color: colors.$secondThemeColor;
      }
    }
  }
}

.plan-container--normal {
  margin-top: 30px;
}

.pricing-popular-top {
  height: 30px;
  width: 100%;
  background-color: colors.$secondThemeColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem 0.6rem 0 0;
  text-transform: uppercase;
  font-weight: 500;

  .pricing-popular-top__box {
    background-color: colors.$mainBackgroundColor;
    border-radius: 50px;

    .pricing-popular-top__text {
      color: colors.$secondThemeColor;
      padding: 0.3rem 1rem;
    }
  }
}
