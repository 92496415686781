@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.features-profits {
  width: 100%;
  height: 100%;
  background-color: colors.$featuresProfitsBackgroundColor;
  display: flex;
  justify-content: center;
  padding: 6rem 0 1rem 0;
  color: colors.$mainFontColor;

  @include mixins.lg {
    padding: 6rem 0;
  }

  .features-profits-container {
    width: 90vw;
    height: 100%;
    max-width: 1200px;

    @include mixins.lg {
      display: flex;
      gap: 5rem;
    }

    .features-profits__profits-list-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mixins.lg {
        width: 40%;
        height: 100%;
      }

      .profits-list__header {
        min-height: 100px;
        .profits-list__title {
          font-size: clamp(2rem, 2.5vw, 2.2rem);
          font-weight: 700;
          text-align: center;
          margin-bottom: 4rem;

          @include mixins.lg {
            text-align: left;
          }
        }
      }
      .profits-list__list-wrapper {
        @include mixins.lg {
          //   height: 100%;
          min-height: 450px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .profits-item {
          background-color: colors.$mainBackgroundColor;
          margin-bottom: 2.1rem;
          box-shadow: 0px 4px 32px -9px rgba(38, 38, 38, 0.1);
          border-radius: 4px;
          display: flex;
          padding: 1.5rem;
          align-items: center;
          gap: 1rem;

          @include mixins.lg {
            margin-bottom: 0;
            padding: 2rem 1.5rem;
          }

          .profits-item__icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            position: relative;

            .profits-item__icon {
              width: 20px;
              height: 20px;
              color: colors.$mainBackgroundColor;
              z-index: 1;
            }
            &::after {
              content: '';
              position: absolute;
              top: 0;
              background-color: colors.$firstThemeColor;
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          .profits-item__text-container {
            width: 100%;

            .profits-item__title {
              display: none;

              @include mixins.lg {
                display: block;
                font-size: 1.6rem;
                font-weight: 700;
                margin-bottom: 0.2rem;
              }
            }
            .profits-item__content {
              font-size: 1.4rem;
              font-weight: 300;

              span {
                font-weight: 700;
              }

              @include mixins.lg {
                font-size: 1.2rem;
                font-weight: 400;
                color: #656565;

                span {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .features-profits__profits-read-also-container {
      @include mixins.lg {
        width: 60%;
      }
      .profits-read-also__header {
        min-height: 100px;
        .profits-read-also__title {
          font-size: clamp(2rem, 2.5vw, 2.2rem);
          font-weight: 700;
          text-align: center;
          margin: 5rem 0;

          @include mixins.lg {
            margin-top: 0;
            margin-bottom: 4rem;
            text-align: left;
          }
        }
      }
      .profits-read-also__list-wrapper {
        @include mixins.lg {
          min-height: 450px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .profits-read-also__profits-read-also-item--link {
          .profits-read-also-item {
            width: 100%;
            background: colors.$mainBackgroundColor;
            box-shadow: 0px 4px 32px -9px rgba(38, 38, 38, 0.1);
            border-radius: 1px;
            margin-bottom: 3rem;
            min-height: 130px;
            transition: 0.2s ease-in-out;

            @include mixins.lg {
              display: flex;
              align-items: center;
              margin-bottom: 0;

              &:hover {
                transform: scale(1.01);
              }
            }

            .profits-read-also-item__picture-container {
              @include mixins.lg {
                display: flex;
                align-items: center;
                padding: 0 1.2rem;
                width: 35%;
                min-width: 150px;
                height: fit-content;
              }
              .profits-read-also-item__image {
                width: 100%;

                @include mixins.md {
                  width: 100%;
                }
              }
            }

            .profits-read-also-item__text-container {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 1.4rem;

              @include mixins.lg {
                width: 70%;
              }

              .profits-read-also-item__title {
                font-size: 1.8rem;
                font-weight: 600;
                text-decoration: underline;
                line-height: 2.3rem;
                margin-bottom: 1rem;

                @include mixins.md {
                  margin-bottom: 0;
                }
              }

              .profits-read-also-item__content {
                font-size: 1.2rem;
                margin-bottom: 2rem;
                color: #656565;

                @include mixins.lg {
                  margin-bottom: 1rem;
                }
              }

              .profits-read-also-item__link {
                font-size: 1.2rem;
                display: block;
                color: colors.$secondThemeColor;
                margin-bottom: 1rem;

                @include mixins.lg {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
