@use '../../../styles/mixins.scss';
@use '../../../styles/colors.scss';

.case-studies-companies {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2rem;
  gap: 5rem;

  @include mixins.lg {
    flex-direction: row;
    gap: 3rem;
  }

  .case-studies-companies__company-case-container-link {
    width: 100%;

    @include mixins.lg {
      width: calc(100% / 2 - 1.5rem);
    }

    .case-studies-companies__company-case-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: colors.$mainBackgroundColor;
      width: 100%;
      height: 500px;
      border-radius: 1.5rem;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
    }
    .case-studies-companies__company-logo-container {
      width: 100%;
      height: 400px;
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 1.5rem;

      .case-studies-companies__company-logo {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }

    .case-studies-content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 350px;
      opacity: 1;
      background-color: colors.$heroInputColor;
      padding: 1rem 2rem 0 2rem;
      opacity: 1;
      transition: 0.2s ease-in-out;
      border-radius: 0 0 1.5rem 1.5rem;
      border-top: 1px solid #d2d2d2;

      .case-studies-content-container__category-industry {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }

      .case-studies-content-container__category-industry-item {
        font-size: 1.3rem;
        font-weight: 600;
        color: colors.$mainBackgroundColor;
        text-transform: uppercase;
        padding: 1rem;
        border-radius: 2rem;
        background-color: colors.$firstThemeColor;
      }

      .case-studies-content-container__title {
        width: 80%;
        font-size: 1.6rem;
        font-size: 2.2rem;
        font-weight: 700;
        color: colors.$mainFontColor;
        margin: 1rem 0;
      }

      .case-studies-content-container__description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 1.4rem;
        font-weight: 300;
        color: colors.$secondaryFontColor;
        margin-bottom: 1rem;
      }

      .case-studies-content-container__read-more {
        font-size: 1.3rem;
        font-weight: 300;
        color: colors.$secondThemeColor;
        margin-bottom: 1rem;
      }
    }

    .case-studies-content-container--active {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 220px;
      background-color: colors.$inputColor;
      padding: 1rem 2rem 0 2rem;
      opacity: 1;
      transition: 0.2s ease-in-out;

      .case-studies-content-container__title {
        width: 80%;
        font-size: 2.2rem;
        font-weight: 700;
        color: colors.$mainFontColor;
        margin-bottom: 1rem;
      }

      .case-studies-content-container__read-more {
        font-size: 1.3rem;
        font-weight: 300;
        color: colors.$secondThemeColor;
        margin-bottom: 1rem;
      }
    }
  }

  .case-studies-companies__no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    color: colors.$mainFontColor;
    margin-top: 2rem;
  }

  .case-studies-companies__no-results-text {
    font-size: 2.4rem;
    font-weight: 600;
    color: colors.$mainFontColor;
  }
}
