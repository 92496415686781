@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.home-page-with-cux {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0;

  @include mixins.lg {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .home-page-with-cux-container {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    @include mixins.lg {
      flex-direction: row;
      justify-content: space-between;
      padding: 15rem 0;
    }

    .home-page-with-cux-text-content {
      width: 100%;

      @include mixins.lg {
        width: 45%;
      }

      .home-page-with-cux-text-content__title {
        font-size: clamp(2rem, 2.5vw, 3.2rem);
        font-weight: 800;
        color: colors.$mainFontColor;
        margin-bottom: 2rem;
      }
      .home-page-with-cux-text-content__subtitle {
        font-size: clamp(1.2rem, 2.5vw, 1.6rem);
        font-weight: 600;
        color: colors.$secondThemeColor;
        text-transform: uppercase;
        margin-bottom: 2rem;
      }
      .home-page-with-cux-text-content__list {
        .home-page-with-cux-text-content__list-item {
          font-size: clamp(1.2rem, 2.5vw, 1.6rem);
          font-weight: 300;
          color: colors.$mainFontColor;
          margin-bottom: 1rem;
          display: flex;
          gap: 1rem;

          .home-page-with-cux-text-content__list-item-icon {
            color: colors.$firstThemeColor;
            width: 20px;
            height: 20px;
          }
          .home-page-with-cux-text-content__list-item-text {
            width: 100%;
          }
        }
      }
    }

    .home-page-with-cux__home-page-with-cux-content {
      width: 100%;

      @include mixins.lg {
        width: 55%;
      }

      .home-page-with-cux__home-page-with-cux-content-container {
        width: 100%;
        height: 0;
        position: relative;
        padding-bottom: 56.25%;
        z-index: 2;

        @include mixins.lg {
          &::before {
            content: url('/blob.svg');
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 150px;
            left: 450px;
            z-index: -1;
          }
          &::after {
            content: url('/blob.svg');
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: -100px;
            left: -120px;
            z-index: -1;
            transform: scale(0.8);
          }
        }

        .home-page-with-cux__home-page-with-cux-content-video {
          position: absolute;
          width: 100%;
          height: 95%;
        }
      }
    }
  }
}
