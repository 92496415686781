@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.job-preview {
  color: colors.$mainFontColor;
  padding: 1rem;
  margin-bottom: 2rem;
  transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: colors.$blogPreviewHoverColor;
  }

  .job-preview__title {
    font-size: clamp(1.6rem, 2.5vw, 2rem);
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.3s ease-in-out;
  }

  .job-preview__description {
    font-size: clamp(1.4rem, 2.5vw, 1.3rem);
    font-weight: 300;
  }

  &:hover > .job-preview__title {
    color: colors.$secondThemeColor;
  }
}
