@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.pricing-questions {
  align-items: center;
  background-color: colors.$pricingQuestionsHeaderBackgroundColor;
  color: colors.$mainFontColor;
  display: flex;
  flex-direction: column;
  width: 100%;

  .pricing-questions__header-container {
    background-color: colors.$pricingQuestionsHeaderBackgroundColor;
    display: flex;
    justify-content: center;
    width: 100%;

    .pricing-questions__header {
      max-width: 1200px;
      padding: 2.5rem 0;
      width: 90%;

      .pricing-questions__header-text {
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  .pricing-questions-container {
    margin-bottom: 5rem;
    max-width: 1200px;
    width: 90%;

    .pricing-questions-accordion {
      font-size: 1.6rem;
      height: 100%;
      width: 100%;

      .pricing-questions-accordion__container {
        border-bottom: 1px solid colors.$boxesBorderColor;
        padding: 1.5rem 0;

        .pricing-questions-accordion__question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pricing-questions-accordion__question-text {
            font-weight: 500;
          }

          &:hover {
            cursor: pointer;
          }

          .pricing-questions-accordion__question-icon {
            width: 2rem;
            transition: 0.2s ease-in-out;
          }

          .pricing-questions-accordion__question-icon--active {
            width: 2rem;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }

          &:hover {
            transition: 0.2s linear;
            color: colors.$secondThemeColor;
          }
        }

        .pricing-questions-accordion__answer {
          animation: showAnswer 200ms ease-in-out forwards;
          display: none;

          .pricing-questions-accordion__answer-text {
            font-weight: 300;
          }
        }

        .pricing-questions-accordion__answer--active {
          animation: showAnswer 200ms ease-in-out forwards;
          display: block;

          .pricing-questions-accordion__answer-text {
            margin-top: 3rem;

            .answer-text__bold-text {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@keyframes showAnswer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
