@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.jobs-list__job-name {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem 1rem;
  border-bottom: 1px solid colors.$borderBottomLinesColor;
  width: 100%;
  color: colors.$mainFontColor;
  transition: 0.2s ease-in-out;
  text-align: center;

  &:hover {
    cursor: pointer;
    color: colors.$secondThemeColor;
  }

  @include mixins.lg {
    text-align: left;
  }
}

.jobs-list__job-name--active {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f8f8f8;
  width: 100%;
  color: colors.$secondThemeColor;
  text-align: center;
  transition: 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: colors.$secondThemeColor;
  }

  @include mixins.lg {
    text-align: left;
  }
}
