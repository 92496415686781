@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.container {
  align-items: baseline;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  color: #212121;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  z-index: 9999999999;
  bottom: 24px;
  left: 24px;
  width: calc(100% - 48px);
  margin: 0 24px 0 0;
  max-width: 680px;
  height: auto;
  padding: 20px 26px;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(172, 171, 171, 0.4);
  font-family: 'Open Sans', sans-serif;

  @include mixins.lg {
    width: 440px;
    margin: 0;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 12px 0;
}

.content {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0 0 24px 0;
}

.btn {
  color: #FFFFFF;
  border: 2px solid #08D2D4;
  background-color: #08D2D4;
  text-transform: uppercase;
  max-width: 100%;
  font-size: 14px;
  font-family: inherit;
  line-height: 24px;
  padding: 8px;
  font-weight: 500;
  margin: 0 8px 0 0;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  min-height: 0;
  display: block;
}

.btn.decline {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #ababab;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}