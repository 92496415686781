@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.knowledge-articles-page {
  width: 100%;

  @include mixins.lg {
    width: 30%;
  }

  .knowledge-articles-page-container {
    width: 100%;

    .knowledge-articles-page__knowledge-list-container {
      width: 100%;

      .knowledge-list {
        .knowledge-list-item {
          font-size: 1.6rem;
          font-weight: 600;

          .knowledge-list-item__category {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.5rem 0.5rem;

            .knowledge-list-item__category-icon {
              transition: 0.2s ease-in-out;
              width: 1.5rem;
            }

            .knowledge-list-item__category-icon--active {
              transition: 0.2s ease-in-out;
              width: 1.5rem;
              transform: rotate(180deg);
            }

            &:hover {
              cursor: pointer;
              transition: 0.2s ease-in-out;
              background-color: colors.$blogPreviewHoverColor;
            }
          }

          .knowledge-list-dropdown {
            width: 100%;
            animation: showAnswer 0.3s ease-in-out forwards;
            color: colors.$mainFontColor;
            display: none;
          }

          .knowledge-list-dropdown--active {
            animation: showAnswer 0.3s ease-in-out forwards;
            display: block;
          }
        }
      }
    }
  }
}

@keyframes showAnswer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
