@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.become-partner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  color: colors.$mainFontColor;
  overflow: hidden;

  .become-partner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.become-partner-top {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  padding: 3rem 0;
  color: colors.$mainFontColor;
  overflow: hidden;

  @include mixins.lg {
    padding: 4rem;
  }

  .become-partner-top__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .breadcrumbs {
      width: 90vw;
      max-width: 1200px;
      text-align: left;
      font-size: clamp(1.3rem, 2vw, 1.5rem);
      font-weight: 300;
      margin-bottom: 2rem;
      text-align: left;

      @include mixins.lg {
        margin-top: 0;
      }
    }

    .become-partner-top__header {
      display: flex;
      gap: 1rem;
      width: 90vw;
      max-width: 1200px;

      .become-partner-top__header-icon {
        width: clamp(2.5rem, 3vw, 4rem);
        color: colors.$secondThemeColor;
      }

      .become-partner-top__header-title {
        font-size: clamp(2rem, 2.5vw, 3rem);
        font-weight: 700;
      }
    }

    .become-partner-top__description {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: clamp(1.4rem, 1.5vw, 1.6rem);
      font-weight: 300;
      margin-top: 3rem;
      line-height: 150%;
      width: 90vw;
      max-width: 1200px;

      @include mixins.md {
        flex-direction: row;
        gap: 7rem;
      }

      .become-partner-top__description-content {
        width: 100%;

        @include mixins.md {
          width: 50%;
        }

        span {
          font-weight: 700;
        }

        br {
          display: block;
          margin-top: 3rem;
          content: '';
        }
      }
      .become-partner-top__badge-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 2rem;

        @include mixins.md {
          justify-content: flex-start;
          align-items: center;
          width: 50%;
          margin-top: 0;
        }

        .become-partner-top__badge-item {
          width: 100%;
          max-width: 500px;
        }
      }
    }
  }
}
