@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.innovation-fund {
  color: colors.$mainFontColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .innovation-fund-container {
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .innovation-fund__content {
      display: flex;
      width: 90%;
      max-width: 1200px;
      justify-content: center;
      margin: 0 0 5rem 0;

      .innovation-fund__content-text {
        width: 100%;
        text-align: center;

        .innovation-fund__title {
          font-size: clamp(2.4rem, 3vw, 4rem);
          margin-bottom: 2rem;
        }

        .innovation-fund__text {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;
          margin-bottom: 2rem;
        }

        @include mixins.md {
          text-align: left;
        }
        @include mixins.lg {
          margin: 7rem 0;
        }
      }
      .innovation-fund__content-icon-container {
        display: none;

        @include mixins.md {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .innovation-fund__content-icon {
            width: 50%;
            color: colors.$secondThemeColor;
          }
        }
      }
    }

    .innovation-fund__expectations {
      background-color: colors.$subscribedExpectationsBackgroundcolor;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 5rem 0;

      @include mixins.lg {
        padding: 9rem 0;
      }

      .innovation-fund__expectations-container {
        width: 90%;
        max-width: 1200px;
      }
    }
  }
}
