@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.case-studies-types-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 10rem;

  @include mixins.md {
    flex-direction: row;
  }

  .case-studies-types-card__container-a {
    width: 100%;
    height: 100%;
    display: block;

    @include mixins.md {
      width: calc(100% / 4);
    }
    .case-studies-types-card__container {
      width: 100%;
      border-radius: 2rem;
      padding: 4rem 2rem;
      height: 420px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: 0.2s ease-in-out;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);

      &:hover {
        cursor: pointer;
      }

      .case-studies-icon-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .case-studies-icon-container__left-side {
          .case-studies-types-card__icon-aiocado {
            transition: 0.2s ease-in-out;
          }
          .case-studies-types-card__regular-icon {
            width: auto;
            height: 53px;
            transition: 0.2s ease-in-out;
          }
        }
        .case-studies-icon-container__right-side {
          .case-studies-types-card__icon-arrow {
            width: 25px;
            height: 15px;
            color: colors.$mainFontColor;
          }
        }
      }

      .case-studies-card-data {
        .case-studies-card-data__id {
          font-size: 1.3rem;
          font-weight: 700;
          color: colors.$mainBackgroundColor;
        }
        .case-studies-card-data__title {
          font-size: clamp(1.6rem, 3.5vw, 2rem);
          font-weight: 700;
          margin-top: 1rem;
          color: colors.$mainBackgroundColor;
        }
        .case-studies-card-data__description {
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          height: 0;
          font-size: 1.3rem;
          font-weight: 300;
          margin-top: 1rem;
          color: colors.$mainFontColor;
        }
      }

      .case-studies-card-data--active {
        .case-studies-card-data__id {
          font-size: 1.3rem;
          font-weight: 700;
          color: colors.$mainFontColor;
          transform: translateY(-150px);
          transition: 0.2s ease-in-out;
        }
        .case-studies-card-data__title {
          font-size: clamp(1.6rem, 3.5vw, 2rem);
          font-weight: 700;
          margin-top: 1rem;
          color: colors.$mainFontColor;
          transform: translateY(-150px);
          transition: 0.2s ease-in-out;
        }
        .case-studies-card-data__description {
          opacity: 1;
          height: 100%;
          overflow: visible;
          height: 100%;
          font-size: 1.3rem;
          font-weight: 300;
          margin-top: 1rem;
          color: colors.$mainFontColor;
          transform: translateY(-150px);
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
}
