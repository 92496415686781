@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.faq {
  background-color: colors.$mainBackgroundColor;
  color: colors.$mainFontColor;
  display: flex;
  justify-content: center;
  width: 100%;

  .faq-container {
    margin-bottom: 5rem;
    max-width: 1200px;
    width: 90%;

    .faq__title {
      font-size: 2rem;
      font-weight: 700;
      margin: 3rem 0 4rem 0;
    }

    .faq-accordion {
      font-size: 1.6rem;
      height: 100%;
      width: 100%;

      .faq-accordion__container {
        border-bottom: 1px solid colors.$boxesBorderColor;
        padding: 1.5rem 0;

        .faq-accordion__question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .faq-accordion__question-text {
            font-weight: 400;
          }

          &:hover {
            cursor: pointer;
          }

          .faq-accordion__question-icon {
            width: 2rem;
            transition: 0.2s ease-in-out;
          }

          .faq-accordion__question-icon--active {
            width: 2rem;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }

          &:hover {
            transition: 0.2s linear;
            color: colors.$secondThemeColor;
          }
        }

        .faq-accordion__answer {
          animation: showAnswer 0.3s ease-in-out forwards;
          color: colors.$mainFontColor;
          display: none;

          .faq-accordion__answer-text {
            font-weight: 300;
          }
        }

        .faq-accordion__answer--active {
          animation: showAnswer 0.3s ease-in-out forwards;
          display: block;

          .faq-accordion__answer-text {
            margin-top: 3rem;
          }
        }
      }
    }
  }
}

@keyframes showAnswer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
