@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.why-cux-hero {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .why-cux-hero-container {
    width: 90%;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    padding-top: 4rem;

    @include mixins.md {
      padding-top: 2rem;
    }

    .why-cux-hero-header {
      width: 100%;
      color: colors.$secondThemeColor;
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-bottom: clamp(5rem, 3vw, 10rem);

      .why-cux-hero-header__icon {
        width: clamp(1.6rem, 2vw, 3.1rem);
      }

      .why-cux-hero-header__text {
        font-size: clamp(2rem, 2.5vw, 3rem);
        color: colors.$mainFontColor;
      }
    }
  }
}

.why-cux-hero-content {
  margin: 1rem 0 6rem 0;

  .why-cux-hero-content__text-container {
    color: colors.$mainFontColor;
    font-size: 1.6rem;
    font-weight: 300;
  }

  .ratings-container__rating--use {
    width: 108px;
    aspect-ratio: 1;
    position: relative;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 700;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      inset: 0;
      background: radial-gradient(
            farthest-side,
            colors.$whyCuxRatingSpace 98%,
            #0000
          )
          top/22px 22px no-repeat,
        conic-gradient(
          colors.$whyCuxRatingSpace calc(5 * 1%),
          colors.$secondThemeColor 0
        );
      mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - 17px),
        #000 calc(100% - 17px)
      );
      background-size: 0 0, auto;
    }
  }

  .ratings-container__rating--support {
    width: 108px;
    aspect-ratio: 1;
    position: relative;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 700;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      inset: 0;
      background: radial-gradient(
            farthest-side,
            colors.$whyCuxRatingSpace 98%,
            #0000
          )
          top/22px 22px no-repeat,
        conic-gradient(
          colors.$whyCuxRatingSpace calc(4 * 1%),
          colors.$firstThemeColor 0
        );
      mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - 17px),
        #000 calc(100% - 17px)
      );
      background-size: 0 0, auto;
    }
  }

  .ratings-container__rating--setup {
    width: 108px;
    aspect-ratio: 1;
    position: relative;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 700;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      inset: 0;
      background: radial-gradient(
            farthest-side,
            colors.$whyCuxRatingSpace 98%,
            #0000
          )
          top/22px 22px no-repeat,
        conic-gradient(
          colors.$whyCuxRatingSpace calc(10 * 1%),
          colors.$whyCuxRatingSetupColor 0
        );
      mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - 17px),
        #000 calc(100% - 17px)
      );
      background-size: 0 0, auto;
    }
  }
}

.why-cux-hero-content__why-cux-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: colors.$mainFontColor;
  width: 100%;

  .why-cux-rating-container__top {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 6rem 0 4rem 0;
  }

  .why-cux-rating-container__ratings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4rem;

    @include mixins.md {
      flex-direction: row;
      gap: 0;
      justify-content: space-around;
    }

    .ratings-container__raiting-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.6rem;

      .rating-description__main-text {
        font-weight: 700;
        margin-top: 1rem;
      }

      .rating-description__secondary-text {
        font-weight: 300;
        color: colors.$secondaryFontColor;
      }
    }
  }

  .why-cux-rating-container__g2-link {
    margin-top: 3rem;
    font-size: 1.4rem;
    font-weight: 300;
    text-decoration: underline;
    color: colors.$secondaryFontColor;
  }
}
