@use '../../../../../../../styles/colors.scss';
@use '../../../../../../../styles/mixins.scss';

.pricing-variants {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1.5rem 0;

  .pricing-variants__button {
    padding: 0.4rem 0.6rem;
    display: flex;
    background-color: transparent;
    border: none;
    border: 2px solid transparent;
    font-family: 'Open Sans', sans-serif;
    background-color: #f7f7f7;
    border-radius: 0.5rem;

    &:hover {
      color: #fc2a65;
      cursor: pointer;
    }
  }

  .pricing-variants__button--active {
    padding: 0.4rem 0.6rem;
    display: flex;
    background-color: transparent;
    border: none;
    border: 2px solid #fc2a65;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0.5rem;

    &:hover {
      color: #fc2a65;
      cursor: pointer;
    }
  }
}
