@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.contact-page-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @include mixins.md {
        align-items: flex-start;
      }

      .contact-page-form__required-input-fields {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;

        @include mixins.md {
          flex-direction: row;
          margin-bottom: 2rem;
        }

        @include mixins.lg {
          width: 80%;
        }

        @include mixins.xl {
          width: 100%;
        }

        .contact-page-form__input {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$featuresFontColor;
          color: colors.$featuresFontColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
            border-bottom: 1px solid colors.$firstThemeColor;
          }

          &::placeholder {
            color: colors.$featuresFontColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }

        .contact-page-form__input--error {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$newsletterErrorFormColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }
      }

      // Dropdown select menu
      .contact-dropdown {
        background: transparent;
        color: colors.$featuresFontColor;
        font-family: 'Open Sans', sans-serif;
        font-size: clamp(1.3rem, 1.5rem, 1.7rem);
        margin: 0.5rem 0 1.5rem 0;
        width: 100%;
        position: relative;

        @include mixins.md {
          margin-top: 0;
        }

        @include mixins.lg {
          width: 80%;
        }

        @include mixins.xl {
          width: 100%;
        }

        .contact-dropdown__button {
          display: flex;
          justify-content: space-between;
          padding: 1.5rem 0 0.3rem 0;
          border-bottom: 1px solid colors.$featuresFontColor;
          align-items: center;
          font-weight: 300;

          .contact-dropdown__button-arrow {
            max-width: 15px;
            transition: 0.2s ease-in-out;
          }

          .contact-dropdown__button-arrow--active {
            max-width: 15px;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }

          &:hover {
            cursor: pointer;
          }
        }
        .contact-dropdown__button--error {
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;

          .contact-dropdown__button-arrow {
            max-width: 15px;
            transition: 0.2s ease-in-out;
          }

          .contact-dropdown__button-arrow--active {
            max-width: 15px;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .contact-dropdown__button--active {
          background: transparent;
          color: colors.$featuresFontColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          width: 100%;

          .contact-dropdown__button-arrow {
            max-width: 15px;
            transition: 0.2s ease-in-out;
          }

          .contact-dropdown__button-arrow--active {
            max-width: 15px;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .contact-dropdown-content {
          position: absolute;
          top: 100%;
          left: 0;
          display: none;
          background-color: colors.$mainBackgroundColor;
          width: 100%;
          font-weight: 300;

          .contact-dropdown-content__item {
            padding: 1rem;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .contact-dropdown-content--active {
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
          background-color: colors.$mainBackgroundColor;
          width: 100%;
          font-weight: 300;
          border: 1px solid colors.$boxesBorderColor;
          z-index: 3;

          .contact-dropdown-content__item {
            padding: 1rem;
            border-bottom: 1px solid colors.$boxesBorderColor;

            &:hover {
              background-color: colors.$mainBackgroundColor;
              color: colors.$secondThemeColor;
              cursor: pointer;
            }
          }
        }
      }

      .contact-page-form__input-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 2rem;
        width: 100%;

        @include mixins.md {
          flex-direction: row;
        }

        @include mixins.lg {
          width: 80%;
        }

        @include mixins.xl {
          width: 100%;
        }

        .contact-page-form__input {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$featuresFontColor;
          color: colors.$featuresFontColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
            border-bottom: 1px solid colors.$firstThemeColor;
          }

          &::placeholder {
            color: colors.$featuresFontColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }

        .contact-page-form__input--error {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$newsletterErrorFormColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }
      }

      .contact-page-form__submit-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: colors.$secondThemeColor;
        border-radius: 0.5rem;
        color: colors.$mainBackgroundColor;
        margin: 5rem 0 7rem 0;
        max-width: 350px;
        position: relative;

        .contact-page-form__submit-input-fullWidth {
          max-width: 100%;
        }

        .contact-page-form__submit-input-icon {
          display: none;
        }

        .contact-page-form__submit-input-icon-active {
          display: block;
          width: 18px;
          position: absolute;
          left: 75px;
          top: 23%;

          @include mixins.md {
            left: 25px;
          }
        }

        .contact-page-form__submit-input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: transparent;
          border: none;
          color: colors.$mainBackgroundColor;
          font-size: 1.4rem;
          height: 100%;
          padding: 1rem 0;
          width: 100%;
          text-transform: uppercase;
          font-weight: 600;
          z-index: 2;

          &:hover {
            box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
            cursor: pointer;
          }
        }

        @include mixins.md {
          max-width: 240px;
        }

        @include mixins.lg {
          justify-content: flex-start;
          margin: 3rem 0 7rem 0;
        }

        &:hover {
          box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }

      .contact-page-form__submit-container-fullWidth {
        max-width: 100%;
        width: 100%;
      }

      .contact-page-form__submit-container-active {
        position: relative;
        animation: succesBackgroundColor 2.5s ease-in 0s 1 forwards;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:hover {
          box-shadow: none;
          cursor: auto;
        }

        &::before {
          content: '';
          border-radius: 0.5rem;
          position: absolute;
          width: 0;
          height: 100%;
          background-color: colors.$contactPageSendingStateColor;
          left: 0;
          top: 0;
          animation: succesStateAnimation 2.5s ease-in 0s 1 alternate;
          z-index: 0;
        }
      }
    }

@keyframes succesStateAnimation {
  0% {
    width: 0;
  }
  90% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes succesBackgroundColor {
  0% {
    background-color: colors.$secondThemeColor;
  }
  89% {
    background-color: colors.$secondThemeColor;
  }
  90% {
    background-color: colors.$firstThemeColor;
  }
  100% {
    background-color: colors.$firstThemeColor;
  }
}
