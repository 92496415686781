@use '../../../styles/mixins.scss';
@use '../../../styles/colors.scss';

.case-studies-company-details {
  background-color: colors.$doctor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  padding: 4rem;

  @include mixins.md {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 130px;

    .case-studies-company-details__item-icon {
      width: auto;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: colors.$firstThemeColor;
    }

    .case-studies-company-details__item-title {
      margin-top: 1rem;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      color: colors.$mainFontColor;
    }

    .case-studies-company-details__item-value {
      margin-top: 0.5rem;
      font-size: 1.4rem;
      font-weight: 300;
      text-align: center;
    }
  }
}
