.avocado-break-section {
  width: 100%;
  height: 100%;
  padding: 5rem 0;
  background-color: colors.$whiteout;
  display: flex;
  justify-content: center;

  @include mixins.lg {
    padding: 8rem 0;
  }

  .avocado-break-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 90vw;
    max-width: 1200px;

    @include mixins.lg {
      flex-direction: row-reverse;
      position: relative;
      z-index: 2;

      &::before {
        content: url('/blob.svg');
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 100px;
        left: 300px;
        z-index: -1;
        transform: scale(0.7);
      }
      &::after {
        content: url('/blob.svg');
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: -100px;
        left: 700px;
        z-index: -1;
        transform: scale(0.6);
      }
    }

    @include mixins.xl {
      &::before {
        content: url('/blob.svg');
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 100px;
        left: 300px;
        z-index: -1;
        transform: scale(0.7);
      }
      &::after {
        content: url('/blob.svg');
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: -100px;
        left: 860px;
        z-index: -1;
        transform: scale(0.6);
      }
    }

    .avocado-break-box {
      background-color: colors.$mainBackgroundColor;
      border-radius: 4px;
      box-shadow: 0px 1.89958px 9.49789px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      max-width: 650px;

      @include mixins.lg {
        padding: 3rem;
      }

      .avocado-break-box__title {
        font-size: clamp(1.6rem, 2vw, 3.2rem);
        font-weight: 800;
        color: colors.$mainFontColor;
        margin-bottom: 1rem;
      }
      .avocado-break-box__subtitle {
        font-size: clamp(1.2rem, 2vw, 1.8rem);
        font-weight: 300;
        color: colors.$mainFontColor;
        margin-bottom: 1rem;

        @include mixins.lg {
          margin-bottom: 3rem;
        }
      }
      .avocado-break-box__buttons {
        display: flex;
        justify-content: center;
        gap: 2rem;

        @include mixins.lg {
          justify-content: flex-start;
        }
      }
    }

    .avocado-break-image {
      display: flex;
      justify-content: center;
      width: 30%;

      @include mixins.lg {
        width: 40%;
      }
    }
  }
}
