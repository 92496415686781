@use '../../../styles/mixins.scss';
@use '../../../styles/colors.scss';

.case-studies-page {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .case-studies-page-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 20rem 0 10rem 0;

    @include mixins.lg {
      flex-direction: row;
      align-items: flex-start;
      padding: 17rem 0 10rem 0;
    }

    .case-studies-page__links-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      width: 100%;
      text-align: center;
      margin-bottom: 5rem;
      flex-wrap: wrap;

      @include mixins.lg {
        text-align: left;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 10rem 0 0 0;
      }

      .case-studies-page__link {
        width: 100%;
        font-size: clamp(1.6rem, 2.5vw, 2.2rem);
        font-weight: 300;
        border: 1px solid colors.$boxesBorderColor;
        border-radius: 0.8rem;
        padding: 2rem 1rem;
        color: colors.$mainFontColor;
        text-align: center;
        transition: 0.2s ease-in-out;

        @include mixins.lg {
          width: calc(100% / 3.3);
          margin-bottom: 3rem;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$blogPreviewHoverColor;
          color: colors.$secondThemeColor;
          border: 1px solid colors.$secondThemeColor;
        }
      }
    }

    .case-studies-page__content {
      width: 100%;
      color: colors.$mainFontColor;

      .case-studies-page__content-container {
        .case-studies-page__content-breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .case-studies-page__content-breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .case-studies-page__main-header {
          display: flex;
          align-items: center;
          margin-bottom: 5rem;
          gap: 2rem;

          .case-studies-page__main-header-icon {
            width: 30px;
            height: 30px;
            color: colors.$secondThemeColor;
          }

          .case-studies-page__main-header-text {
            font-size: 3rem;
            font-weight: 700;
          }
        }
      }

      .case-studies-main-desc {
        font-size: 1.6rem;
        margin-bottom: 5rem;
        text-align: center;

        @include mixins.lg {
          text-align: left;
        }
      }

      .case-studies-trial {
        margin-top: 5rem;

        @include mixins.lg {
          margin-top: 10rem;
        }

        .case-studies-trial__text {
          font-size: 1.6rem;
          font-weight: 300;
          color: colors.$mainFontColor;
          text-align: center;
          margin-bottom: 3rem;
        }

        .case-studies-trial__buttons {
          display: flex;
          justify-content: center;
          gap: 2rem;
        }
      }
      .case-studies-form-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;

        @include mixins.lg {
          flex-direction: row;
          gap: 1rem;
        }
        .case-studies-dropdown {
          background: transparent;
          color: colors.$featuresFontColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          width: 100%;
          position: relative;

          @include mixins.md {
            margin-top: 0;
          }

          @include mixins.lg {
            width: 50%;
          }

          .case-studies-dropdown__header {
            font-size: 1.6rem;
            font-weight: 600;
            color: colors.$mainFontColor;
            margin-bottom: 1rem;
          }

          .case-studies-dropdown__button {
            display: flex;
            justify-content: space-between;
            padding: 1rem 2rem;
            background-color: colors.$inputColor;
            align-items: center;
            font-weight: 300;

            .case-studies-dropdown__button-arrow {
              max-width: 10px;
              transition: 0.2s ease-in-out;
            }

            .case-studies-dropdown__button-arrow--active {
              max-width: 10px;
              transform: rotate(180deg);
              transition: 0.2s ease-in-out;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .case-studies-dropdown__button--active {
            background: transparent;
            color: colors.$featuresFontColor;
            font-family: 'Open Sans', sans-serif;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            width: 100%;

            .case-studies-dropdown__button-arrow {
              max-width: 10px;
              transition: 0.2s ease-in-out;
            }

            .case-studies-dropdown__button-arrow--active {
              max-width: 10px;
              transform: rotate(180deg);
              transition: 0.2s ease-in-out;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .case-studies-dropdown-content {
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            background-color: colors.$mainBackgroundColor;
            width: 100%;
            font-weight: 300;

            .case-studies-dropdown-content__item {
              padding: 1rem;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .case-studies-dropdown-content--active {
            position: absolute;
            top: 100%;
            left: 0;
            display: block;
            background-color: colors.$mainBackgroundColor;
            width: 100%;
            font-weight: 300;
            border: 1px solid colors.$boxesBorderColor;
            z-index: 3;

            .case-studies-dropdown-content__item {
              padding: 1rem;
              border-bottom: 1px solid colors.$boxesBorderColor;

              &:hover {
                background-color: colors.$mainBackgroundColor;
                color: colors.$secondThemeColor;
                cursor: pointer;
              }
            }
          }
        }

        .case-studies-search {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          @include mixins.lg {
            width: 50%;
          }

          .case-studies-search__header {
            font-size: 1.6rem;
            font-weight: 600;
            color: colors.$mainFontColor;
            margin-bottom: 1rem;
          }

          .case-studies-search-container {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .case-studies-search__icon {
              position: absolute;
              left: 2rem;
              width: 15px;
              height: 15px;
              color: colors.$featuresFontColor;
            }
            .case-studies-search__input {
              width: 100%;
              height: 100%;
              padding: 1rem 2rem 1rem 4rem;
              background-color: colors.$inputColor;
              font-size: clamp(1.3rem, 1.5rem, 1.7rem);
              color: colors.$featuresFontColor;
              font-family: 'Open Sans', sans-serif;
              font-weight: 300;
              border: none;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
