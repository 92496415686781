.features-solutions-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include mixins.md {
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .features-solutions-main-item {
    width: 100%;
    height: 265px;
    background-color: colors.$featuresSolutionsBoxesBackgroundColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 5rem;
    text-align: center;

    @include mixins.md {
      width: calc((100% / 2) - 1.5rem);
      height: 290px;
      padding: 1.5rem;
      justify-content: flex-start;
      gap: 1rem;
      padding: 4rem 2rem;
    }
    @include mixins.lg {
      width: calc((100% / 4) - 1.5rem);
      height: 340px;
    }
  }

  .features-solutions-main-item__icon-container {
    @include mixins.md {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .features-solutions-main-item__icon {
      color: colors.$secondThemeColor;
      height: 61px;
    }
  }

  .features-solutions-main-item__text-container {
    @include mixins.lg {
      margin-top: 2rem;
    }

    .features-solutions-main-item__title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    .features-solutions-main-item__text {
      font-size: 1.6rem;
      font-weight: 300;
    }
  }
  .features-solutions-main-item__read-more-container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    text-decoration: underline;
    color: colors.$secondThemeColor;

    .features-solutions-main-item__read-more {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.4rem;

      .features-solutions-main-item__read-more-icon {
        width: 5px;
      }
    }
  }
}
