@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.home-page-timeline {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;

  @include mixins.lg {
    padding: 10rem 0;
  }

  .home-page-timeline__header {
    margin-bottom: 3rem;

    @include mixins.lg {
      margin-bottom: 8rem;
    }

    .home-page-timeline__header-text {
      font-size: clamp(2rem, 2.5vw, 3.2rem);
      font-weight: 800;
      color: colors.$mainFontColor;
      text-align: center;
    }
  }

  .home-page-timeline-container {
    width: 90vw;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow-y: hidden;

    .home-page-timeline__timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8rem;

      @include mixins.lg {
        gap: 20rem;
      }

      .timeline__timeline-item {
        display: flex;
        flex-direction: column;

        @include mixins.lg {
          flex-direction: row;
          gap: 14rem;
        }

        .timeline-item-text {
          @include mixins.lg {
            width: 50%;
          }
          .timeline-item-text__title {
            color: colors.$secondThemeColor;
            font-weight: 700;
            font-size: clamp(1.8rem, 2.5vw, 2.6rem);
            line-height: 120%;
          }
          .timeline-item-text__content {
            font-size: clamp(1.6rem, 2vw, 1.7rem);
            color: colors.$mainFontColor;
            font-weight: 300;
            margin-top: 2rem;

            li {
              display: flex;
              margin-top: 1.5rem;

              div:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-weight: 700;
                height: 18px;
                width: 18px;
                padding: 1rem 1rem 1.1rem 1.1rem;
                background-color: colors.$mainFontColor;
                color: colors.$mainBackgroundColor;
                margin-right: 1rem;
                font-size: 1.2rem;
              }
            }
          }
        }

        .timeline-item-image {
          @include mixins.lg {
            width: 50%;
          }

          .timeline-item-image__image-container {
            margin-top: 4rem;
            display: flex;
            justify-content: center;

            @include mixins.lg {
              width: 85%;
              margin-top: 0;
            }
          }
        }
      }

      .timeline__timeline-item:nth-child(even) {
        @include mixins.lg {
          flex-direction: row-reverse;
        }
      }
      .home-page-timeline__scroll-indicator-track {
        @include mixins.lg {
          position: absolute;
          top: 0;
          left: 50%;
          width: 5px;
          height: 100%;
          background-color: #d9d9d9;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(217, 217, 217, 1) 10%,
            rgba(217, 217, 217, 1) 50%,
            rgba(217, 217, 217, 1) 90%,
            rgba(255, 255, 255, 1) 100%
          );
          z-index: -1;
        }
      }
      .home-page-timeline__scroll-indicator {
        @include mixins.lg {
          position: absolute;
          top: 0;
          left: 50%;
          width: 5px;
          height: 100%;
          background-color: colors.$firstThemeColor;
          z-index: -1;
          transition: 0.2s ease-in-out;

          &::after {
            content: '';
            position: absolute;
            top: (calc(100% - 5px));
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: colors.$firstThemeColor;
          }
        }
      }
    }
  }
}
