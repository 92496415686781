@use './colors.scss';
@use './mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;

  body {
    font-family: 'Open Sans', sans-serif;

    nav {
      position: fixed;
      width: 100%;
      z-index: 5;
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.063));
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    ul {
      list-style: none;
    }

    main {
      align-items: center;
      background-color: #ffffff;
      color: #8d8d8d;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: calc(50px + 80px);

      @include mixins.lg {
        padding-top: calc(50px + 80px);
      }
    }
  }
}

@import '../pages/about/why-cux.scss';
@import '../pages/blog/blogArticle.scss';
@import '../pages/features/features.scss';
@import '../pages/jobs/job.scss';
@import '../pages/knowledge-base/knowledgeArticles.scss';
@import '../pages/legal/legal.scss';
@import '../pages/case-studies/caseStudies.scss';
@import '../pages/solutions/solutions.scss';
@import '../src/components/AvocadoBreakSection/AvocadoBreakSection.scss';
@import '../src/components/BasicButton/BasicButton.scss';
@import '../src/components/BecomePartnerBrochure/BecomePartnerBrochure.scss';
@import '../src/components/BecomePartnerBrochure/BecomePartnerBrochure.scss';
@import '../src/components/BecomePartnerMembers/BecomePartnerMembers.scss';
@import '../src/components/BecomePartnerMembers/BecomePartnerMembers.scss';
@import '../src/components/BecomePartnerPerks/BecomePartnerPerks.scss';
@import '../src/components/BecomePartnerPerks/BecomePartnerPerks.scss';
@import '../src/components/BecomePartnerSlider/BecomePartnerSlider.scss';
@import '../src/components/BecomePartnerTop/BecomePartnerTop.scss';
@import '../src/components/BecomePartnerTop/BecomePartnerTop.scss';
@import '../src/components/BlogArticlePreview/BlogArticlePreview.scss';
@import '../src/components/BlogArticlesListOnCategory/BlogArticlesListOnCategory.scss';
@import '../src/components/BlogCategoriesList/BlogCategoriesList.scss';
@import '../src/components/BlogListOnArticle/BlogListOnArticle.scss';
@import '../src/components/BookDemoPage/BookDemoPage.scss';
@import '../src/components/BottomMenu/BottomMenu.scss';
@import '../src/components/Breadcrumbs/Breadcrumbs.scss';
@import '../src/components/CaseStudiesTypesCards/CaseStudiesTypesCards.scss';
@import '../src/components/CaseStudiesCategoryPage/CaseStudiesCategoryPage.scss';
@import '../src/components/CaseStudiesCompanyDetails/CaseStudiesCompanyDetails.scss';
@import '../src/components/CaseStudiesCompaniesList/CaseStudiesCompaniesList.scss';
@import '../src/components/CaseStudiesRelatedList/CaseStudiesRelatedList.scss';
@import '../src/components/ContactForm/ContactForm.scss';
@import '../src/components/CompareTabs/CompareTabs.scss';
@import '../src/components/ContactPage/ContactPage.scss';
@import '../src/components/ContactPageForm/ContactPageForm.scss';
@import '../src/components/DownloadEbookPage/DownloadEbookPage.scss';
@import '../src/components/Faq/Faq.scss';
@import '../src/components/FeaturesBookDemo/FeaturesBookDemo.scss';
@import '../src/components/FeaturesForWho/FeaturesForWho.scss';
@import '../src/components/FeaturesForWhoSingle/FeaturesForWhoSingle.scss';
@import '../src/components/FeaturesMovie/FeaturesMovie.scss';
@import '../src/components/FeaturesProfits/FeaturesProfits.scss';
@import '../src/components/FeaturesRating/FeaturesRating.scss';
@import '../src/components/FeaturesSlider/FeaturesSlider.scss';
@import '../src/components/FeaturesSolutionsMain/FeaturesSolutionsMain.scss';
@import '../src/components/FeaturesSolutionsMain/FeaturesSolutionsMain.scss';
@import '../src/components/FeaturesTabs/FeaturesTabs.scss';
@import '../src/components/Footer/Footer.scss';
@import '../src/components/Hero/Hero.scss';
@import '../src/components/HomePageFeatures/HomePageFeatures.scss';
@import '../src/components/HomePageReviews/HomePageReviews.scss';
@import '../src/components/HomePageStories/HomePageStories.scss';
@import '../src/components/HomePageTimeline/HomePageTimeline.scss';
@import '../src/components/HomePageWithCux/HomePageWithCux.scss';
@import '../src/components/HomePageVideo/HomePageVideo.scss';
@import '../src/components/InnovationFund/InnovationFundPage.scss';
@import '../src/components/JobPreview/JobPreview.scss';
@import '../src/components/JobsList/JobsList.scss';
@import '../src/components/JobsPage/JobsPage.scss';
@import '../src/components/KnowledgeArticlePreview/KnowledgeArticlePreview.scss';
@import '../src/components/KonowledgeListOnArticle/KonowledgeListOnArticle.scss';
@import '../src/components/LegalPage/LegalPage.scss';
@import '../src/components/LogoSlider/LogoSlider.scss';
@import '../src/components/FoundersSection/FoundersSection.scss';
@import '../src/components/NavBar/NavBar.scss';
@import '../src/components/Newsletter/Newsletter.scss';
@import '../src/components/NewsletterPage/NewsletterPage.scss';
@import '../src/components/OutlineButton/OutlineButton.scss';
@import '../src/components/PageNotFound/PageNotFound.scss';
@import '../src/components/PricingDetails/PricingDetails.scss';
@import '../src/components/PricingHero/CalculatorComponent/src/calculator.styles.scss';
@import '../src/components/PricingHero/CalculatorComponent/src/components/Summary/summary.styles.scss';
@import '../src/components/PricingHero/CalculatorComponent/src/components/TrafficSlider/traffic-slider.styles.scss';
@import '../src/components/PricingHero/CalculatorComponent/src/components/Variants/variants.styles.scss';
@import '../src/components/PricingAddons/PricingAddons.scss';
@import '../src/components/PricingHero/PricingHero.scss';
@import '../src/components/PricingPlans/PricingPlans.scss';
@import '../src/components/PricingQuestions/PricingQuestions.scss';
@import '../src/components/PricingSlider/PricingSlider.scss';
@import '../src/components/PrivacyPolicyPage/PrivacyPolicyPage.scss';
@import '../src/components/PromoNewsletter/PromoNewsletter.scss';
@import '../src/components/RequestDemoPage/RequestDemoPage.scss';
@import '../src/components/SelfGuidedWalkthrough/SelfGuidedWalkthrough.scss';
@import '../src/components/SignedUpPromoPage/SignedUpPromoPage.scss';
@import '../src/components/SolutionsBenefits/SolutionsBenefits.scss';
@import '../src/components/SolutionsLogos/SolutionsLogos.scss';
@import '../src/components/SolutionsStats/SolutionsStats.scss';
@import '../src/components/SolutionsSteps/SolutionsSteps.scss';
@import '../src/components/SubpagesContent/SubpagesContent.scss';
@import '../src/components/SubscribedPage/SubscribedPage.scss';
@import '../src/components/SuccessBanner/SuccessBanner.scss';
@import '../src/components/TermsOfServicePage/TermsOfServicePage.scss';
@import '../src/components/Testimonials/Testimonials.scss';
@import '../src/components/TextInput/TextInput.scss';
@import '../src/components/ToTheTopButton/ToTheTopButton.scss';
@import '../src/components/WhyCuxAwards/WhyCuxAwards.scss';
@import '../src/components/WhyCuxHero/WhyCuxHero.scss';
@import '../src/components/WhyCuxSlider/WhyCuxSlider.scss';
@import '../src/components/WhyCuxTabs/WhyCuxTabs.scss';
