@use '../../styles/mixins.scss';
@use '../../styles/colors.scss';

.features {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  color: colors.$mainFontColor;
  overflow: hidden;

  @include mixins.lg {
    padding-top: 4rem;
  }

  .features-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .breadcrumbs {
      width: 90vw;
      max-width: 1200px;
      text-align: left;
      font-size: clamp(1.3rem, 2vw, 1.5rem);
      font-weight: 300;
      margin-bottom: 2rem;
      text-align: left;

      @include mixins.lg {
        margin-top: 0;
      }
    }

    .features-container__header {
      display: flex;
      gap: 1.5rem;
      width: 90vw;
      max-width: 1200px;

      .features-container__header-icon {
        width: clamp(2.5rem, 3vw, 4rem);
        color: colors.$secondThemeColor;
      }

      .features__header-title {
        font-size: clamp(2rem, 2.5vw, 3rem);
        font-weight: 700;
      }
    }

    .features-content {
      width: 90vw;
      max-width: 1200px;

      .features-content__top-text {
        font-size: clamp(1.6rem, 2vw, 2.4rem);
        margin: 5rem 0;
        font-weight: 300;
      }
    }
  }
}

// user behaviour
.user-behaviour__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .user-behaviour__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .user-behaviour__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .user-behaviour__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// visit recordings
.visit-recordings__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .visit-recordings__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .visit-recordings__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .visit-recordings__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// heatmaps
.heatmaps__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .heatmaps__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .heatmaps__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .heatmaps__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// conversion waterfalls
.conversion-waterfalls__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .conversion-waterfalls__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .conversion-waterfalls__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .conversion-waterfalls__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// goal oriented analysis
.goal-oriented-analysis__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .goal-oriented-analysis__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .goal-oriented-analysis__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .goal-oriented-analysis__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// pre-analysis alerts
.pre-analysis__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .pre-analysis__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .pre-analysis__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .pre-analysis__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// pre-analysis alerts
.auto-capture__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .auto-capture__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .auto-capture__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .auto-capture__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// pre-analysis alerts
.retroactive-analysis__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  font-weight: 300;
  margin-top: 3rem;
  line-height: 150%;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    flex-direction: row;
    gap: 7rem;
  }

  .retroactive-analysis__description-content {
    width: 100%;

    @include mixins.md {
      width: 50%;
    }

    span {
      font-weight: 700;
    }

    br {
      display: block;
      margin-top: 3rem;
      content: '';
    }
  }
  .retroactive-analysis__description-badges {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
    height: 100%;
    margin-top: 4rem;

    @include mixins.md {
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      margin-top: 0;
    }

    .retroactive-analysis__medal-image {
      width: calc(90vw / 3 - 2rem);
      max-width: 160px;

      @include mixins.md {
        width: calc(100% / 3 - 2rem);
        max-width: 130px;
      }
    }
  }
}

// Features main page

.features-page {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .features-page-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 20rem 0 10rem 0;

    @include mixins.lg {
      flex-direction: row;
      align-items: flex-start;
      padding: 17rem 0 10rem 0;
    }

    .features-page__links-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      width: 100%;
      text-align: center;
      margin-bottom: 5rem;
      flex-wrap: wrap;

      @include mixins.lg {
        text-align: left;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 10rem 0 0 0;
      }

      .features-page__link {
        width: 100%;
        font-size: clamp(1.6rem, 2.5vw, 2.2rem);
        font-weight: 300;
        border: 1px solid colors.$boxesBorderColor;
        border-radius: 0.8rem;
        padding: 2rem 1rem;
        color: colors.$mainFontColor;
        text-align: center;
        transition: 0.2s ease-in-out;

        @include mixins.lg {
          width: calc(100% / 3.3);
          margin-bottom: 3rem;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$blogPreviewHoverColor;
          color: colors.$secondThemeColor;
          border: 1px solid colors.$secondThemeColor;
        }
      }
    }

    .features-page__content {
      width: 100%;
      color: colors.$mainFontColor;

      .features-page__content-container {
        .features-page__content-breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .features-page__content-breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .features-page__header {
          display: flex;
          align-items: center;
          margin-bottom: 5rem;
          gap: 2rem;

          .features-page__header-icon {
            width: 30px;
            height: 30px;
            color: colors.$secondThemeColor;
          }

          .features-page__header-text {
            font-size: 4rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
