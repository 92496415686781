.summary__title {
  font-weight: 700;
  margin-bottom: 1rem;
}

.summary__price-container {
  display: flex;
  width: 100%;
  min-width: 200px;

  .summary__price {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #262626;
    width: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
    padding-left: 1rem;
  }
}

.summary__button-create-account {
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  background-color: #fc2a65;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  border: 2px solid transparent;
  transition: 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    border: 2px solid #fc2a65;
    color: #fc2a65;
  }
}

.Mui-checked span {
  color: #fc2a65;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #fc2a65;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
  font-size: clamp(1.4rem, 2.5vw, 1.5rem);
  font-weight: 300;
}
