@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.outline-button-container {
  .outline-button {
    background-color: colors.$mainBackgroundColor;
    border: none;
    border-radius: 5px;
    outline: 1px solid colors.$secondThemeColor;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: colors.$cascadingWhite;
    }

    .outline-button__link {
      display: block;

      text-transform: uppercase;
      padding: 1.5rem 2rem;
      color: colors.$secondThemeColor;
      font-family: 'Open Sans', sans-serif;
      font-size: 1.1rem;
      font-weight: 500;

      @include mixins.lg {
        padding: 1.5rem 2.5rem;
        font-size: 1.4rem;
      }
    }
  }
}
