@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.contact-page-form {
  // background-color: colors.$contactPageBoxColor;
  display: flex;
  justify-content: center;
  width: 100%;
  color: colors.$featuresFontColor;

  .contact-page__container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 3rem 0;
    width: 90%;
    margin: 0 auto;

    @include mixins.lg {
      background-image: url(../../../public/contact-form-photo.png);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      min-height: 850px;
    }

    .contact-page__text-container {
      margin: 11rem 0 4rem 0;
      width: 100%;
      color: colors.$mainFontColor;

      @include mixins.lg {
        margin: 20rem 0 3rem 0;
        width: 50%;
      }

      .contact-page__text {
        font-size: clamp(3rem, 3rem, 3.8rem);
        font-weight: 400;
        text-align: left;

        @include mixins.lg {
          text-align: left;
        }

        .contact-page__text--bold {
          font-weight: 700;
        }
      }
    }
  }
  .contact-page-form__container {
    width: 100%;

    @include mixins.lg {
      width: 50%;
    }
  }
}