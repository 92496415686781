@use '../../styles/mixins.scss';
@use '../../styles/colors.scss';

.case-studies-page {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .case-studies-page-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 20rem 0 10rem 0;

    @include mixins.lg {
      flex-direction: row;
      align-items: flex-start;
      padding: 17rem 0 10rem 0;
    }

    .case-studies-page__links-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      width: 100%;
      text-align: center;
      margin-bottom: 5rem;
      flex-wrap: wrap;

      @include mixins.lg {
        text-align: left;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 10rem 0 0 0;
      }

      .case-studies-page__link {
        width: 100%;
        font-size: clamp(1.6rem, 2.5vw, 2.2rem);
        font-weight: 300;
        border: 1px solid colors.$boxesBorderColor;
        border-radius: 0.8rem;
        padding: 2rem 1rem;
        color: colors.$mainFontColor;
        text-align: center;
        transition: 0.2s ease-in-out;

        @include mixins.lg {
          width: calc(100% / 3.3);
          margin-bottom: 3rem;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$blogPreviewHoverColor;
          color: colors.$secondThemeColor;
          border: 1px solid colors.$secondThemeColor;
        }
      }
    }

    .case-studies-page__content {
      width: 100%;
      color: colors.$mainFontColor;

      .case-studies-page__content-container {
        .case-studies-page__content-breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .case-studies-page__content-breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .case-studies-page__header {
          display: flex;
          align-items: center;
          margin-bottom: 5rem;
          gap: 2rem;

          .case-studies-page__header-icon {
            width: 30px;
            height: 30px;
            color: colors.$secondThemeColor;
          }

          .case-studies-page__header-text {
            font-size: 3rem;
            font-weight: 700;
          }
        }
      }

      .case-studies-main-desc {
        font-size: 1.6rem;
        margin-bottom: 5rem;
        text-align: center;

        @include mixins.lg {
          text-align: left;
        }
      }

      .case-studies-trial {
        margin-top: 5rem;

        @include mixins.lg {
          margin-top: 3rem;
        }

        .case-studies-trial__text {
          font-size: 1.6rem;
          font-weight: 300;
          color: colors.$mainFontColor;
          text-align: center;
          margin-bottom: 3rem;
        }

        .case-studies-trial__buttons {
          display: flex;
          justify-content: center;
          gap: 2rem;
        }
      }
    }
  }
}

// CASE STUDIES ARTICLE PAGE

@import '../../styles/colors.scss';

.case-studies-article {
  width: 100%;
  display: flex;
  justify-content: center;
  color: colors.$mainFontColor;
  position: relative;

  .case-studies-article-container {
    width: 90%;
    height: 100%;
    display: flex;
    max-width: 1200px;
    margin: 6rem 0 8rem 0;
    position: relative;

    @include mixins.md {
      width: 75%;
    }

    .case-studies-article__content {
      width: 100%;
      position: relative;

      .case-studies-article__content-breadcrumbs {
        font-size: clamp(1.3rem, 2vw, 1.5rem);
        font-weight: 300;
        margin: 1rem 0 2rem 0;

        .case-studies-article__content-breadcrumbs-link {
          color: colors.$secondThemeColor;
        }
        .case-studies-article__content-breadcrumbs-link:last-child {
          text-decoration: underline;
        }
      }

      .case-studies-article__top-container {
        background-color: colors.$doctor;
        display: flex;
        flex-direction: column;
        padding: 3rem;
        gap: 5rem;

        @include mixins.lg {
          flex-direction: row;
          gap: 5rem;
        }

        .top-container__left-side-case-studies-post-content {
          width: 100%;

          @include mixins.lg {
            width: 55%;
          }

          .case-studies-post-data {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;

            .case-studies-post-data__category {
              background-color: colors.$firstThemeColor;
              padding: 1rem;
              border-radius: 0.5rem;
              font-size: 1.2rem;
              font-weight: 600;
              color: colors.$mainBackgroundColor;
              text-transform: uppercase;
            }

            .case-studies-post-data__date {
              font-size: 1.2rem;
              color: colors.$secondaryFontColor;
            }

            .top-container__content-article-reading-time-container {
              display: flex;
              align-items: center;
              font-size: 1.2rem;

              .content-article-data__icon {
                width: 12px;
                height: 12px;
                color: colors.$secondaryFontColor;
              }

              .content-article-data__reading-time {
                font-size: 1.2rem;
                padding-left: 0.5rem;
                color: colors.$secondaryFontColor;
              }
            }
          }

          .top-container__short-summary {
            font-size: 1.4rem;
            margin: 2rem 0;
            color: colors.$mainFontColor;
          }

          .top-author-section {
            .top-author-section__author-header {
              font-size: 1.5rem;
              font-weight: 700;
              margin: 6rem 0 1rem 0;
              text-transform: uppercase;
              color: colors.$mainFontColor;
            }

            .top-author-section__author-data {
              display: flex;
              align-items: center;
              gap: 1rem;
              font-size: 1.5rem;
              font-weight: 700;
              margin-bottom: 1rem;
              color: colors.$mainFontColor;

              .author-data__author-image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
              }

              .author-data__author-position {
                font-weight: 300;
              }
            }
          }
        }

        .top-container__right-side-case-studies-post-image {
          width: 100%;
          display: flex;
          align-items: center;

          @include mixins.lg {
            width: 45%;
          }

          .case-studies-article__content-image {
            width: 100%;
            max-height: 400px;
            object-fit: contain;
          }
        }

        .case-studies-article__content-title {
          font-size: 3.2rem;
          font-weight: 700;
          color: colors.$mainFontColor;
          margin: 2rem 0;
        }

        .case-studies-article__download-ebook-button {
          background-color: colors.$secondThemeColor;
          border: none;
          border: 2px solid transparent;
          font-family: 'Open Sans', sans-serif;
          border-radius: 0.5rem;
          color: colors.$mainBackgroundColor;
          transition: 0.2s ease-in-out;
          margin: 2rem 0;

          &:hover {
            color: colors.$secondThemeColor;
            background-color: colors.$mainBackgroundColor;
            border: 2px solid colors.$secondThemeColor;
            cursor: pointer;
          }

          .case-studies-article__download-ebook-link {
            display: block;
            font-weight: 700;
            letter-spacing: 0.1rem;
            width: 100%;
            height: 100%;
            padding: 1rem 1rem;
            text-transform: uppercase;
          }
        }

        .case-studies-article__content-article-data {
          display: flex;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          margin: 1rem 0;
          justify-content: space-between;

          .content-article-data-container {
            display: flex;
            flex-direction: column;

            @include mixins.md {
              flex-direction: row;
            }

            .content-article-data__author {
              @include mixins.md {
                padding-right: 1rem;
                border-right: 1px solid colors.$secondThemeColor;
              }
            }

            .content-article-data__date {
              @include mixins.md {
                padding: 0 1rem;
                border-right: 1px solid colors.$secondThemeColor;
              }
            }

            .content-article-data__date-update {
              font-weight: 600;
              @include mixins.md {
                padding: 0 1rem;
              }
            }
          }
          .content-article-reading-time-container {
            display: flex;
            align-items: center;
            font-size: 1.2rem;

            .content-article-data__icon {
              width: 15px;
              height: 15px;
              color: colors.$secondThemeColor;
            }

            .content-article-data__reading-time {
              font-size: clamp(1.3rem, 2vw, 1.5rem);
              padding-left: 1rem;
              font-weight: 500;
              color: colors.$secondaryFontColor;
            }
          }
        }
      }

      .case-studies-article__content-summary {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 170%;

        a {
          color: colors.$secondThemeColor;
        }
      }

      .case-studies-article__content-with-anchor-list-container {
        display: flex;
        flex-direction: column;
        margin-top: 5rem;

        @include mixins.lg {
          flex-direction: row;
          gap: 5rem;
        }

        .case-studies-article__content-summary-with-text-container {
          @include mixins.lg {
            width: 70%;
          }
        }

        .case-studies-article__share-socials-container {
          display: flex;
          flex-direction: column;
          border-top: 1px dashed colors.$boxesBorderColor;
          padding-top: 2rem;
          margin: 2rem 0 10rem 0;

          @include mixins.lg {
            margin-bottom: 0;
          }

          .case-studies-article__share-socials-header {
            font-size: 1.2rem;
            color: colors.$secondaryFontColor;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }

          .case-studies-article__share-socials {
            display: flex;
            gap: 0.5rem;
          }
        }
      }

      .case-studies-article__anchor-list {
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;
        border-radius: 0.8rem;

        @include mixins.lg {
          width: 30%;
        }

        .case-studies-article__anchor-list-title {
          font-weight: 700;
          font-size: 1.8rem;
          margin-bottom: 1rem;
          text-transform: uppercase;
          color: colors.$secondThemeColor;
          display: block;
        }

        .case-studies-article__anchor-list-container--empty,
        .case-studies-article__anchor-list-title--empty {
          display: none;
        }

        .case-studies-article__anchor-list-item-container {
          display: flex;
          gap: 1rem;

          .case-studies-article__anchor-list-icon {
            width: 15px;
            height: 15px;
          }
          .case-studies-article__anchor-list-item {
            width: 100%;
            margin-bottom: 1rem;
            text-underline-offset: 0.1rem;
            color: colors.$mainFontColor;
            transition: 0.2s ease-in-out;

            &:hover {
              color: colors.$secondThemeColor;
            }
          }
          .case-studies-article__anchor-list-number {
            font-weight: 700;
          }
        }
      }

      .case-studies-article__content-text,
      .case-studies-article__content-text--summary {
        font-size: 1.6rem;
        font-weight: 400;
        margin-top: 3rem;
        line-height: 170%;

        ul {
          list-style: disc;
          margin-left: 4rem;

          li {
            margin: 1rem 0;
          }
        }

        ol {
          position: relative;
          margin-left: 4rem;

          li {
            margin: 1rem 0;
          }
        }

        h2 {
          font-size: clamp(2.2rem, 2.5vw, 3rem);
          font-weight: 700;
          color: colors.$secondThemeColor;
        }

        h2:target {
          scroll-margin-top: 150px;
        }

        h3 {
          font-size: clamp(1.6rem, 2.5vw, 2.2rem);
          font-weight: 700;
          margin: 2rem 0;
          color: colors.$mainFontColor;
        }

        a {
          color: colors.$secondThemeColor;
        }

        img {
          display: block;
          margin: 2rem auto;
          max-width: 100%;
          object-fit: cover;
        }

        button {
          background-color: colors.$mainBackgroundColor;
          border: none;
          border: 2px solid colors.$secondThemeColor;
          font-family: 'Open Sans', sans-serif;
          border-radius: 0.5rem;
          color: colors.$secondThemeColor;
          transition: 0.2s ease-in-out;
          margin: 0 auto;
          display: flex;

          &:hover {
            background-color: colors.$secondThemeColor;
            border: 2px solid transparent;
            cursor: pointer;
          }

          a {
            display: block;
            font-weight: 700;
            letter-spacing: 0.1rem;
            width: 100%;
            height: 100%;
            padding: 1rem 1rem;
            text-transform: uppercase;
            transition: 0.2s ease-in-out;

            &:hover {
              color: colors.$mainBackgroundColor;
            }
          }
        }

        iframe {
          margin: 1rem auto;
          max-width: 100%;
        }

        pre {
          width: 100%;
          height: 100%;
          background-color: #f9f9f9;
          padding: 2rem 0;
          margin: 2rem 0;
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;

          code {
            color: #262626;
          }
        }

        p {
          margin: 2rem 0;
        }

        blockquote {
          border-left: 7px solid colors.$secondThemeColor;
          font-style: italic;
          padding-left: 1rem;
        }
      }

      .author-section {
        background-color: colors.$doctor;
        padding: 3rem;
        margin-top: 3rem;

        .author-section__header {
          font-size: 1.6rem;
          font-weight: 700;
          text-transform: uppercase;
          color: colors.$firstThemeColor;
        }

        .author-details-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .author-details-container__author-data {
            .author-data__author-name {
              font-size: 2rem;
              font-weight: 700;
              color: colors.$mainFontColor;
            }
            .author-data__author-position {
              font-size: 1.3rem;
              color: colors.$secondaryFontColor;
            }
          }

          .author-details-container__author-image-container {
            min-width: 100px;
            .author-details-container__author-image {
              width: 100px;
              height: 100px;
              border-radius: 50%;
            }
          }
        }

        .author-section__description {
          font-size: 1.5rem;
          font-weight: 300;
          margin-top: 5rem;
          color: colors.$mainFontColor;
        }
      }

      .case-studies-article__read-also {
        font-size: clamp(1.6rem, 2.5vw, 2rem);
        font-weight: 600;
        margin: 3rem 0 1rem 0;
        padding-top: 3rem;
        border-top: 1px dashed colors.$boxesBorderColor;
      }

      .case-studies-article__read-also-list {
        list-style: none;
        display: flex;
        flex-direction: column;

        @include mixins.lg {
          flex-direction: row;
          gap: 2rem;
        }

        .case-studies-article__read-also-list-item {
          color: colors.$secondThemeColor;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;

          @include mixins.lg {
            width: calc(100% / 3);
          }

          .case-studies-article__read-also-image {
            width: 100%;
            object-fit: cover;
            margin-bottom: 1rem;
            border-radius: 0.5rem;
          }

          .read-also-data-container {
            display: flex;
            gap: 4rem;
            margin-bottom: 1rem;

            .read-also-data-container__date,
            .read-also-data-container__read-time {
              display: flex;
              align-items: center;
              gap: 1rem;
              font-weight: 400;
            }

            .read-also-data-container__date-icon,
            .read-also-data-container__read-time-icon {
              width: 12px;
              height: 12px;
              color: colors.$secondThemeColor;
            }

            .read-also-data-container__date,
            .read-also-data-container__read-time {
              font-size: 1.2rem;
              color: colors.$secondaryFontColor;
            }
          }

          .case-studies-article__read-also-title {
            font-size: 1.4rem;
            font-weight: 700;
            color: colors.$mainFontColor;
            margin-bottom: 1rem;
            min-height: 50px;
          }
          .case-studies-article__read-also-short-summary {
            font-size: 1.2rem;
            font-weight: 300;
            color: colors.$mainFontColor;
            margin-bottom: 1rem;
            min-height: 70px;
          }
          .case-studies-article__read-also-link {
            font-size: 1.4rem;
            font-weight: 300;
            transition: 0.2s ease-in-out;

            &:hover {
              color: colors.$firstThemeColor;
            }
          }

          &::marker {
            font-size: 1.5rem;
            color: colors.$mainFontColor;
          }
        }
      }

      .case-studies-article__book-demo-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10rem 0 1rem 0;

        .case-studies-article__book-demo-button {
          background-color: colors.$mainBackgroundColor;
          border: none;
          border: 2px solid colors.$secondThemeColor;
          font-family: 'Open Sans', sans-serif;
          border-radius: 0.5rem;
          color: colors.$secondThemeColor;
          transition: 0.2s ease-in-out;

          &:hover {
            color: colors.$mainBackgroundColor;
            background-color: colors.$secondThemeColor;
            border: 2px solid transparent;
            cursor: pointer;
          }

          .case-studies-article__book-demo-button-link {
            display: block;
            font-weight: 700;
            letter-spacing: 0.1rem;
            width: 100%;
            height: 100%;
            padding: 1rem 1rem;
            text-transform: uppercase;
          }
        }
      }
    }

    .case-studies-article__content-title {
      font-size: 3.2rem;
      font-weight: 700;
      color: colors.$mainFontColor;
      margin: 2rem 0;
    }

    .author-section__company-logo {
      .author-section__company-logo-picture {
        width: 100%;
        height: 100%;
        object-fit: contain;

        .author-section__company-logo-image {
          width: 150px;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .case-studies-trial {
      margin-top: 5rem;

      @include mixins.lg {
        margin-top: 6rem;
      }

      .case-studies-trial__text {
        font-size: 1.6rem;
        font-weight: 300;
        color: colors.$mainFontColor;
        text-align: center;
        margin-bottom: 3rem;
      }

      .case-studies-trial__buttons {
        display: flex;
        justify-content: center;
        gap: 2rem;
      }
    }
  }

  .case-studies-article__related-container {
    .case-studies-article__related-text {
      font-size: 2.6rem;
      font-weight: 700;
      margin: 8rem 0 1rem 0;
      padding-top: 3rem;
      border-top: 1px dashed colors.$boxesBorderColor;
    }
  }

  .case-studies-article__content-text {
    border-bottom: 1px dashed colors.$boxesBorderColor;
  }

  .case-studies-article__scroll-indicator {
    position: fixed;
    top: 0;
    left: 0;
    height: 181px;
    width: 100%;
    background-color: colors.$secondThemeColor;
    z-index: 1;

    @include mixins.mobileMd {
      height: 165px;
    }

    @include mixins.mobileLg {
      height: 138px;
    }

    @include mixins.md {
      height: 138px;
    }

    @include mixins.lg {
      height: 136px;
    }
  }
}

.blog {
  width: 100%;
  color: colors.$mainFontColor;
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  min-height: 100vh;

  @include mixins.md {
    padding-top: 0;
  }

  .case-studies-container {
    max-width: 1200px;
    width: 90%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;

    @include mixins.lg {
      flex-direction: row-reverse;
    }
  }
}

.case-studies-article__anchor-list {
  @include mixins.lg {
    position: sticky;
    top: 150px;
    height: 10%;
  }
}

.case-studies-article__anchor-list--static {
  @include mixins.lg {
    position: static;
    top: 0;
    height: 10%;
  }
}

.blog-article__contact {
  margin: 4rem 0 0;
  position: sticky !important;
  top: 150px;
  right: 0;
}

.blog-article__contact-header {
  font-size: 1.2rem;
  color: colors.$secondaryFontColor;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.blog-article__contact-item {
  margin: 1.2rem 0 1rem;
  font-size: 1.4rem;
  color: colors.$secondThemeColor;
}

.blog-article__contact__icon {
  width: 1.4rem;
  height: 1.4rem;
  color: colors.$secondThemeColor;
  margin-right: 1rem;
}
