@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.home-page-features {
  width: 100%;
  height: 100%;
  padding: 5rem 0;
  display: flex;
  justify-content: center;

  @include mixins.lg {
    padding: 10rem 0;
  }

  .home-page-features-container {
    width: 90vw;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    @include mixins.lg {
      gap: 20rem;
      position: relative;
      z-index: 2;
    }

    .home-page-features-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      @include mixins.lg {
        flex-direction: row;
        gap: 10rem;
      }

      .home-page-features-item-text {
        @include mixins.lg {
          width: 50%;
        }
        .home-page-features-item-text__title {
          color: colors.$mainFontColor;
          font-weight: 800;
          font-size: clamp(2rem, 2.5vw, 3.2rem);
          margin-bottom: 2rem;
        }
        .home-page-features-item-text__subtitle {
          color: colors.$secondThemeColor;
          font-weight: 600;
          font-size: clamp(1.2rem, 2.5vw, 1.6rem);
          margin-bottom: 1rem;
        }
        .home-page-features-item-text__content {
          color: colors.$mainFontColor;
          font-weight: 300;
          font-size: clamp(1.6rem, 2vw, 1.8rem);
        }
        .home-page-features-item-text__content-button {
          margin-top: 2rem;
        }
        .home-page-features-item-text__privacy-features-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          margin-top: 2rem;
          flex-wrap: wrap;

          .home-page-features-item-text__privacy-features {
            display: flex;
            align-items: center;
            color: colors.$luckyGray;
            gap: 0.5rem;

            .home-page-features-item-text__privacy-features-icon {
              width: 10px;
            }
            .home-page-features-item-text__privacy-features-text {
              font-size: 1.2rem;
            }
          }
        }
      }
      .home-page-features-item-image {
        @include mixins.lg {
          width: 50%;
        }
        .home-page-features-item-image__container {
          width: 100%;
          height: 100%;

          .home-page-features-item-image__video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            outline: 1px solid colors.$mainBackgroundColor;
            outline-offset: -1px;

            .home-page-features-item-image__video-source {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .home-page-features-item:nth-child(even) {
      @include mixins.lg {
        flex-direction: row-reverse;
      }
    }
  }
}
