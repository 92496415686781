@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.features-for-who {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  color: colors.$mainFontColor;

  @include mixins.md {
    padding: 10rem 0 15rem 0;
  }

  .features-for-who-container {
    width: 90vw;
    height: 100%;
    max-width: 1200px;

    @include mixins.md {
      gap: 5rem;
    }

    .features-for-who__header {
      margin-bottom: 4rem;

      .features-for-who__title {
        color: colors.$mainFontColor;
        font-size: clamp(2rem, 2.5rem, 2.2rem);
        font-weight: 700;
        text-align: center;
      }
    }

    .features-for-who__features-for-who-list-container {
      width: 100%;
      height: 100%;

      @include mixins.md {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }

      .for-who-item {
        margin-bottom: 2rem;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 4.5rem 1rem;
        border-radius: 0.1rem;

        @include mixins.md {
          margin-bottom: 0;
          width: 25%;
        }

        .for-who-item__icon-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          height: 55px;

          .for-who-item__icon {
            color: colors.$secondThemeColor;
          }
        }

        .for-who-item__title-container {
          height: 55px;
          margin: 3rem 0 2rem 0;

          .for-who-item__title {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
          }
        }

        .for-who-item__checked-list {
          align-self: flex-start;
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;

          .for-who-item__checked-list-icon {
            width: 18px;
            height: 14px;
          }

          .for-who-item__checked-list-text {
            font-size: clamp(1.2rem, 2vw, 1.6rem);
            font-weight: 300;
            width: 100%;
          }
        }
      }
    }
  }
}
