@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.jobs-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: colors.$mainFontColor;

  .jobs-page-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    min-height: 50vh;
    max-width: 1200px;
    margin: 5rem 0;

    @include mixins.lg {
      flex-direction: row;
      gap: 2rem;
    }

    .jobs-page__jobs-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-bottom: 5rem;

      @include mixins.lg {
        width: 30%;
        height: 100%;
        margin-bottom: 0;
      }
    }

    .jobs-page__jobs-full-list {
      width: 100%;

      @include mixins.lg {
        width: 70%;
      }

      .jobs-page__content-breadcrumbs {
        font-size: clamp(1.3rem, 2vw, 1.5rem);
        font-weight: 300;
        margin: 1rem 0 2rem 0;

        @include mixins.lg {
          margin-top: 0;
        }

        .jobs-page__content-breadcrumbs-link {
          color: colors.$secondThemeColor;
        }
      }

      .jobs-page__jobs-full-list-title {
        font-size: clamp(2.4rem, 3vw, 3.6rem);
        font-weight: 600;
        margin-bottom: 3rem;
      }
    }
  }
}
