@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.bottom-menu {
  background-color: colors.$bottomMenuBackgroundColor;
  color: colors.$bottomMenuColorText;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding: 5rem 0;

  .bottom-menu__container {
    display: flex;
    flex-wrap: wrap;
    font-weight: 300;
    gap: 2rem;
    justify-content: space-between;
    max-width: 1200px;
    width: 90%;

    .bottom-menu__list {
      width: 40%;

      @include mixins.md {
        width: auto;
      }

      .bottom-menu__list-header {
        .header-text {
          font-size: 1.6rem;
        }
      }
      .header-text {
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        font-weight: 600;
      }

      .header-text--second-adress {
        margin-top: 3rem;
      }

      .bottom-menu__list-item {
        .bottom-menu__list-item-link {
          display: block;
          width: 100%;
          padding: 1rem 0;

          @include mixins.md {
            padding: 0 0 1rem 0;
          }

          transition: 0.2s ease-in-out;
          &:hover {
            color: colors.$secondThemeColor;
          }
        }
      }

      .bottom-menu__list-item--second-adress {
        margin-top: 1.5rem;
      }

      .bottom-menu__list-item-text--phone {
        margin-top: 1rem;
      }

      .company__item {
        margin-top: 1rem;
        transition: 0.2s ease-in-out;

        &:hover {
          color: colors.$secondThemeColor;
        }
      }

      .company__icons-container {
        display: flex;
        gap: 2rem;
        margin-top: 2rem;
        width: 100%;
        width: auto;
        flex-wrap: wrap;

        .company__icon {
          height: 25px;
          width: 25px;

          .company__item-icon {
            height: 100%;
            width: 100%;
            transition: 0.2s ease-in-out;
            &:hover {
              color: colors.$secondThemeColor;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
