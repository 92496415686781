@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.pricing-addons {
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  width: 100%;
  color: colors.$mainFontColor;

  .pricing-addons-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 3rem;

    @include mixins.md {
      gap: 5rem;
      padding: 8rem 0 2rem 0;
      width: 90%;
    }
    @include mixins.lg {
      padding: 8rem 0 2rem 0;
      width: 90%;
    }

    .pricing-addons__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 1rem;

      .pricing-addons__header-main {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .pricing-addons__header-main-icon {
          width: 2.2rem;
          height: 2.2rem;
          color: colors.$secondThemeColorDark;
        }
        .pricing-addons__header-main-text {
          font-size: 2.2rem;
          font-weight: 700;
          color: colors.$mainFontColor;
        }
      }

      .pricing-addons__header-secondary-text {
        font-size: 1.6rem;
        font-weight: 300;
        color: colors.$mainFontColor;
        text-align: center;
      }
    }

    .addons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      width: 100%;

      @include mixins.md {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .addon-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: colors.$doctor;
        padding: 3rem 2rem;
        border-radius: 2rem;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

        @include mixins.md {
          width: 48%;
          height: 350px;
        }

        @include mixins.lg {
          width: 48%;
          height: 250px;
        }

        .addon-item__header {
          @include mixins.md {
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          @include mixins.lg {
            height: 130px;
          }
          .addon-item__header-icon {
            width: 5rem;
            max-height: 5rem;
            color: colors.$firstThemeColor;
          }
          .addon-item__header-text {
            font-size: 2.4rem;
            font-weight: 700;
            color: colors.$mainFontColor;
            padding: 1.5rem 0;
            border-bottom: 1px solid colors.$spanishGrey;
          }
        }
        .addon-item__description {
          font-size: 1.4rem;
          font-weight: 300;
          color: colors.$mainFontColor;
          margin-top: 1.5rem;
        }
      }
    }
  }
}
