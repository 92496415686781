@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.testimonials {
  align-items: center;
  background-color: colors.$testimonialsBackgroundcolor;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5rem 0;
  width: 100%;

  .testimonials__header {
    height: 100%;
    margin-bottom: 5rem;
    max-width: 1200px;
    width: 90%;

    .testimonials__header-text {
      color: colors.$mainFontColor;
      font-size: 3.6rem;
      font-weight: 300;
      text-align: center;

      @include mixins.md {
        text-align: left;
      }
    }
  }

  .testimonials-container {
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: 1200px;
    width: 90%;

    .testimonials__testimonial-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 5rem;
      margin-bottom: 6rem;
      text-align: center;

      @include mixins.md {
        flex-direction: row;
        gap: 0;
        text-align: left;
      }

      .testimonial-item__image-container {
        align-items: flex-start;
        display: flex;
        justify-content: center;

        @include mixins.md {
          width: 35%;
        }

        .testimonial-item__picture {
          align-items: center;
          background-color: #ffffff;
          border-radius: 50%;
          display: flex;
          height: 150px;
          justify-content: center;
          overflow: hidden;
          width: 150px;

          .testimonial-item__image {
            height: 100%;
            max-height: 120px;
            max-width: 120px;
            position: relative;
            width: 100%;
          }
        }
      }

      .testimonial-item__testimonial-content {
        color: colors.$mainFontColor;
        font-size: 1.5rem;

        .testimonial-content__author {
          font-weight: 300;
        }
        .testimonial-content__position {
          font-weight: 600;
        }
      }

      .testimonial-item__testimonial-content {
        width: 100%;

        @include mixins.md {
          width: 65%;
        }
      }

      .testimonial-content__testimonial-content {
        font-size: 1.6rem;
        font-weight: 300;
        margin-top: 2rem;
      }
    }
  }

  .swiper-pagination-bullet {
    background-color: colors.$secondThemeColor;
    height: 12px;
    width: 12px;
  }
}
