@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.footer {
  background-color: colors.$footerBackgroundColor;
  display: flex;
  height: 60px;
  justify-content: center;

  .footer__container {
    align-items: center;
    color: colors.$mainBackgroundColor;
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    justify-content: space-between;
    max-width: 1200px;
    padding: 1rem 0;
    width: 90%;

    .statistics__text-icon {
      color: colors.$secondThemeColor;
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}
