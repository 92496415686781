@use '../../styles/colors.scss';
@use '../../styles/mixins.scss';

.current-job {
  width: 100%;
  display: flex;
  justify-content: center;
  color: colors.$mainFontColor;

  .current-job-page-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 6rem 0;

    @include mixins.lg {
      flex-direction: row;
      gap: 2rem;
      margin: 3rem 0;
    }

    .current-job__list {
      width: 100%;
      margin-bottom: 5rem;

      @include mixins.lg {
        width: 30%;
        margin-bottom: 0;
      }
    }

    .current-job__content {
      width: 100%;

      @include mixins.lg {
        width: 70%;
      }

      .current-job__content-breadcrumbs {
        font-size: clamp(1.3rem, 2vw, 1.5rem);
        font-weight: 300;
        margin: 1rem 0 2rem 0;

        @include mixins.lg {
          margin-top: 0;
        }

        .current-job__content-breadcrumbs-link {
          color: colors.$secondThemeColor;
        }

        .current-job__content-breadcrumbs-link:last-child {
          text-decoration: underline;
        }
      }

      .current-job__content-title {
        font-size: 3.2rem;
        font-weight: 700;
        text-transform: uppercase;
      }
      .current-job__content-text-top {
        font-size: 1.6rem;
        font-weight: 400;
        margin-top: 3rem;
      }

      .current-job__content-text {
        font-size: 1.6rem;
        font-weight: 300;
        margin-top: 3rem;

        ul {
          list-style: disc;
          margin-left: 4rem;
        }

        ol {
          position: relative;
          margin-left: 4rem;
        }

        h2 {
          font-size: clamp(2.2rem, 2.5vw, 3rem);
          font-weight: 500;
          margin: 2rem 0;
        }

        h3 {
          font-size: clamp(1.6rem, 2.5vw, 2.2rem);
          font-weight: 500;
          margin: 2rem 0;
          color: colors.$secondThemeColor;
        }

        a {
          color: colors.$secondThemeColor;
        }
      }
    }
  }
}
