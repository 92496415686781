@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.knowledge-article-preview {
  color: colors.$mainFontColor;
  padding: 0.5rem;
  margin: 2rem 0;
  width: 90%;
  .knowledge-article-preview__title {
    font-size: 1.6rem;
    font-weight: 300;
  }

  &:hover {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: colors.$secondThemeColor;
  }
}
