@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.blog__categories-list {
  width: 100%;
  display: flex;

  @include mixins.lg {
    width: 40%;
  }

  .categories-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
    width: 100%;
    color: colors.$mainFontColor;

    @include mixins.lg {
      align-items: flex-end;
    }

    .categories-list__categories-form {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .categories-form__input {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 0.4rem;
        background-color: colors.$inputColor;
        padding: 0.5rem;
        font-family: 'Open Sans', sans-serif;
        font-size: clamp(1.3rem, 2.5vw, 1.5rem);

        @include mixins.lg {
          width: 80%;
        }
      }
      .categories-form__input:focus {
        outline: none;
      }

      .categories-form__input::placeholder {
        outline: none;
      }
    }

    .categories-list__categories-text {
      font-weight: 600;
      margin: 3rem 0 2rem 0;
      color: colors.$secondThemeColor;
      font-size: clamp(1.6rem, 2.5vw, 1.8rem);
    }

    .categories-list__link {
      display: block;
      width: 100%;
      border-bottom: 1px solid colors.$borderBottomLinesColor;
      padding: 1rem 0;
      text-align: center;
      transition: 0.3s ease-in-out;

      @include mixins.lg {
        text-align: right;
        width: 80%;
      }

      &:hover {
        cursor: pointer;
        color: colors.$secondThemeColor;
      }
    }

    .categories-list__link--active {
      display: block;
      width: 100%;
      border-bottom: 1px solid colors.$borderBottomLinesColor;
      padding: 1rem 0;
      text-align: center;
      transition: 0.3s ease-in-out;
      color: colors.$secondThemeColor;
      font-weight: 600;

      @include mixins.lg {
        text-align: right;
        width: 80%;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
