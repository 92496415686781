@import '../../../styles/colors.scss';

.special-subpage {
  width: 100%;
  display: flex;
  justify-content: center;
  color: colors.$mainFontColor;
  position: relative;

  .special-subpage-container {
    width: 90%;
    height: 100%;
    display: flex;
    max-width: 900px;
    margin: 6rem 0 8rem 0;
    position: relative;

    @include mixins.md {
      width: 75%;
    }

    .special-subpage__content {
      width: 100%;

      .special-subpage__content-breadcrumbs {
        font-size: clamp(1.3rem, 2vw, 1.5rem);
        font-weight: 300;
        margin: 1rem 0 2rem 0;
        text-transform: capitalize;

        .special-subpage__content-breadcrumbs-link {
          color: colors.$secondThemeColor;
        }
      }

      .special-subpage__content-title {
        font-size: 3.2rem;
        font-weight: 700;
        text-transform: uppercase;
        color: colors.$secondThemeColor;
        line-height: 150%;
      }

      .special-subpage__content-summary {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 170%;

        a {
          color: colors.$secondThemeColor;
        }
      }

      .special-subpage__text-content-container {
        font-size: 1.6rem;
        font-weight: 300;
        margin-top: 3rem;
        line-height: 170%;

        ul {
          list-style: disc;
          margin-left: 4rem;

          li {
            margin: 1rem 0;
          }
        }

        ol {
          position: relative;
          margin-left: 4rem;

          li {
            margin: 1rem 0;
          }
        }

        h2 {
          font-size: clamp(2.2rem, 2.5vw, 3rem);
          font-weight: 500;
          padding: 20rem 0;
          margin: -20rem 0;
        }

        h3 {
          font-size: clamp(1.6rem, 2.5vw, 2.2rem);
          font-weight: 500;
          margin: 2rem 0;
          color: colors.$secondThemeColor;
        }

        a {
          color: colors.$secondThemeColor;
        }

        img {
          display: block;
          margin: 2rem auto;
          max-width: 100%;
          object-fit: cover;
        }

        iframe {
          margin: 1rem auto;
          width: 100%;
          max-width: 100%;
        }

        pre {
          width: 100%;
          height: 100%;
          background-color: #f9f9f9;
          padding: 2rem 0;
          margin: 2rem 0;
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;

          code {
            color: #262626;
          }
        }

        p {
          margin: 2rem 0;
        }

        blockquote {
          border-left: 7px solid colors.$secondThemeColor;
          font-style: italic;
          padding-left: 1rem;
        }

        button {
          background-color: colors.$mainBackgroundColor;
          border: none;
          border: 2px solid colors.$secondThemeColor;
          font-family: 'Open Sans', sans-serif;
          border-radius: 0.5rem;
          color: colors.$secondThemeColor;
          transition: 0.2s ease-in-out;
          margin: 0 auto;
          display: flex;

          &:hover {
            background-color: colors.$secondThemeColor;
            border: 2px solid transparent;
            cursor: pointer;
          }

          a {
            display: block;
            font-weight: 700;
            letter-spacing: 0.1rem;
            width: 100%;
            height: 100%;
            padding: 1rem 1rem;
            text-transform: uppercase;
            transition: 0.2s ease-in-out;

            &:hover {
              color: colors.$mainBackgroundColor;
            }
          }
        }
      }

      .center {
        text-align: center;
      }
    }

    .special-subpage__book-demo-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10rem 0 1rem 0;
    }
  }
}
