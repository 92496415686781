@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.solutions-steps {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .solutions-steps-container {
    width: 90vw;
    max-width: 900px;
    margin-bottom: 7rem;

    .solutions-steps__title {
      font-size: clamp(1.6rem, 2.5vw, 3.2rem);
      text-align: center;
      margin: 8rem 0;
    }

    .features__rating-video-container {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      margin-top: 7rem;

      .features__rating-video {
        position: absolute;
        width: 100%;
        height: 95%;
        border: 2px solid colors.$borderBottomLinesColor;
      }
    }

    .solutions-steps-list {
      width: 100%;

      .solutions-steps-list-item {
        font-size: clamp(1.4rem, 2vw, 2rem);
        margin-bottom: 4rem;

        .solutions-steps-list-item__title {
          font-weight: 600;
          color: colors.$secondThemeColor;
          margin: 2rem 0;
        }

        .solutions-steps-list-item__description {
          font-weight: 300;

          a {
            color: colors.$secondThemeColor;
          }
        }

        .solutions-steps-list-item__picture {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .solutions-steps-list-item__image {
            width: fit-content;
            max-width: 100%;
            margin: 2rem 0 1rem 0;
          }

          .solutions-steps-list-item__image-description {
            font-size: clamp(1.2rem, 2vw, 1.5rem);
            font-weight: 300;
            text-align: center;
            font-style: italic;
          }
        }
      }
    }
  }
}
