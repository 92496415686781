@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.founders-section {
  align-items: center;
  background-color: colors.$mainBackgroundColor;
  color: colors.$mainFontColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  padding: 5rem 0;
  max-width: 1200px;
  margin-top: 14rem;

  @include mixins.md {
    margin-top: 17%;
  }
  @include mixins.lg {
    margin-top: 5rem;
  }

  @include mixins.lg {
    flex-direction: row;
    align-items: flex-start;
    gap: 5rem;
    padding: 10rem 0;
  }

  .founders-text-content {
    @include mixins.lg {
      width: 45%;
    }

    .founders-text-content__header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 3rem;

      .founders-text-content__subtitle {
        font-size: clamp(1.2rem, 2.5vw, 1.6rem);
        font-weight: 600;
        color: colors.$secondThemeColor;
        text-transform: uppercase;
      }
      .founders-text-content__title {
        font-size: clamp(2rem, 2.5vw, 3.2rem);
        font-weight: 800;
        color: colors.$mainFontColor;
      }
    }

    .founders-text-content__text-paragraph {
      font-size: clamp(1.6rem, 2vw, 1.8rem);
      font-weight: 300;
      margin-top: 3rem;
    }
    .founders-text-content__text-paragraph--special {
      font-size: clamp(1.6rem, 2vw, 1.8rem);
      font-weight: 600;
      margin-top: 3rem;
    }
  }

  .founders-photo-content {
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include mixins.lg {
      width: 55%;
      margin-top: 0;
    }

    .founders-photo-content__image {
      object-fit: contain;
    }
  }
}
