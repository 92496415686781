@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.promo-newsletters {
  background-color: colors.$mainBackgroundColor;
  color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  width: 100%;
  color: colors.$featuresFontColor;
  height: 100%;

  .promo-newsletters__container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 3rem 0;
    width: 90%;

    @include mixins.lg {
      background-image: url(../../../public/booking-form-photo.png);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      min-height: 850px;
    }

    .promo-newsletters__text-container {
      margin: 5rem 0 4rem 0;
      width: 100%;
      color: colors.$mainFontColor;

      @include mixins.md {
        margin: 10rem 0;
      }
      @include mixins.lg {
        margin: 20rem 0 3rem 0;
        width: 50%;
      }

      .promo-newsletters__text {
        font-size: clamp(3rem, 3rem, 3.8rem);
        font-weight: 400;
        text-align: center;

        @include mixins.lg {
          text-align: left;
        }

        .promo-newsletters__text--bold {
          font-weight: 700;
        }
      }
    }
  }
  .promo-newsletters-form__container {
    width: 100%;

    @include mixins.lg {
      width: 50%;
    }

    .promo-newsletters-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .promo-newsletters-form__required-input-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;

        @include mixins.md {
          flex-direction: row;
          margin-bottom: 2rem;
        }
        .promo-newsletters-form__input-second-phone-number {
          display: none;
        }
        .promo-newsletters-form__input {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$featuresFontColor;
          color: colors.$featuresFontColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
            border-bottom: 1px solid colors.$firstThemeColor;
          }

          &::placeholder {
            color: colors.$featuresFontColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }

        .promo-newsletters-form__input--error {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$newsletterErrorFormColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }
      }
      .promo-newsletters-form__input-fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 2rem;
        width: 100%;

        @include mixins.md {
          flex-direction: row;
        }

        .promo-newsletters-form__input-second-phone-number {
          display: none;
        }

        .promo-newsletters-form__input {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$featuresFontColor;
          color: colors.$featuresFontColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
            border-bottom: 1px solid colors.$firstThemeColor;
          }

          &::placeholder {
            color: colors.$featuresFontColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }

        .promo-newsletters-form__input--error {
          background: transparent;
          border: none;
          border-bottom: 1px solid colors.$newsletterErrorFormColor;
          color: colors.$newsletterErrorFormColor;
          font-family: 'Open Sans', sans-serif;
          font-size: clamp(1.3rem, 1.5rem, 1.7rem);
          padding: 1.5rem 0 0.3rem 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: colors.$newsletterErrorFormColor;
            font-size: clamp(1.3rem, 1.5rem, 1.7rem);
            font-weight: 300;
          }
        }
      }
      .promo-newsletters-form__marketing-offers {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 300;

        .marketing-offers__checkbox-container {
          align-items: center;
          background-color: transparent;
          border: 1px solid #d9d9d9;
          display: flex;
          height: 20px;
          justify-content: center;
          position: relative;
          width: 20px;
          &:hover {
            cursor: pointer;
          }

          .marketing-offers__checkbox {
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
          .marketing-offers__checkbox-icon {
            display: none;
            left: 0;
            position: absolute;
            top: 0;
          }
          .marketing-offers__checkbox-icon--active {
            display: block;
            height: 18px;
            width: 18px;
          }
        }

        .marketing-offers__checkbox-container--error {
          align-items: center;
          background-color: transparent;
          border: 1px solid colors.$newsletterErrorFormColor;
          display: flex;
          height: 20px;
          justify-content: center;
          position: relative;
          width: 20px;
          &:hover {
            cursor: pointer;
          }
          .marketing-offers__checkbox {
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
          .marketing-offers__checkbox-icon {
            display: none;
            left: 0;
            position: absolute;
            top: 0;
          }
          .marketing-offers__checkbox-icon--active {
            display: block;
            height: 18px;
            width: 18px;
          }
        }

        .marketing-offers__text {
          width: 90%;
          display: inline;

          span {
            text-decoration: underline;
          }

          @include mixins.md {
            width: 95%;
          }
        }

        .marketing-offers__text--error {
          width: 90%;
          color: colors.$newsletterErrorFormColor;
          display: inline;

          span {
            text-decoration: underline;
          }

          @include mixins.md {
            width: 95%;
          }
        }
      }
      .promo-newsletters-form__submit-container {
        display: flex;
        justify-content: center;
        width: 100%;

        .promo-newsletters-form__submit-input {
          background-color: transparent;
          border-radius: 0.5rem;
          border: none;
          background-color: colors.$secondThemeColor;
          color: colors.$mainBackgroundColor;
          font-size: 1.4rem;
          height: 100%;
          padding: 1rem 2.4rem;
          text-transform: uppercase;
          font-weight: 600;
          margin: 5rem 0 7rem 0;

          @include mixins.lg {
            margin: 3rem 0 7rem 0;
          }

          &:hover {
            box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
            cursor: pointer;
          }
        }

        @include mixins.lg {
          justify-content: flex-start;
        }
      }
    }
  }
}
