@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.contact-page {
  color: colors.$mainFontColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: colors.$mainBackgroundColor;
  overflow-x: hidden;

  .contact-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    gap: 5rem;
    align-items: center;

    .contact-page-content {
      display: flex;
      width: 90%;
      max-width: 1200px;
      justify-content: center;
      margin: 0 0 5rem 0;

      .contact-page__data {
        width: 100%;

        .contact-page__breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .contact-page__breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .contact-page__data-container {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 8rem;

          .contact-page__content-icon {
            width: 35px;
            color: colors.$secondThemeColor;
          }

          .contact-page__data-title {
            font-size: clamp(2.3rem, 3vw, 3rem);
          }

          @include mixins.lg {
            width: 50%;
          }
        }

        .contact-details {
          width: 100%;

          @include mixins.xxl {
            display: flex;
            gap: 2rem;
          }

          .contact-box-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .contact-box-item__contact-photo {
              width: auto;
              height: 354px;
              margin-bottom: -5px;
            }

            .contact-box-content {
              width: 100%;
              height: 100%;
              background-color: colors.$contactPageBoxColor;
              max-width: 600px;
              padding: 3rem;
              border-radius: 0.6rem;
              box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
              margin-bottom: 4rem;

              @include mixins.xxl {
                max-width: 390px;
              }

              .contact-box-content__support-icon-container {
                height: 100px;
                width: 100%;
                display: flex;
                justify-content: center;

                .contact-box-content__support-icon {
                  height: 67px;
                  color: colors.$secondThemeColor;
                }
              }

              .contact-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .contact-info__title {
                  font-size: clamp(1.7rem, 2.5vw, 2rem);
                  font-weight: 700;
                  color: colors.$mainFontColor;
                }

                .contact-info__mail-container {
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  color: colors.$secondThemeColor;
                  margin: 1rem 0;

                  .contact-info__icon {
                    width: 18px;
                  }

                  .contact-info__mail-link {
                    font-size: 1.6rem;
                    font-weight: 300;
                    text-decoration: underline;
                  }
                }

                .contact-info__name {
                  font-weight: 600;
                  font-size: 1.6rem;
                  color: colors.$mainFontColor;
                }
              }

              .contact-box-content__contact-icon-container {
                width: 100%;
                height: 100px;
                margin-top: 2rem;
                display: flex;
                justify-content: center;

                .contact-icon-container__icon {
                  width: 42px;
                  color: colors.$contactQuoteIconColor;
                }

                .contact-icon-container__medal {
                  width: 65px;
                }
              }

              .contact-review-box {
                width: 100%;
                height: 250px;
                background-color: colors.$mainBackgroundColor;
                border-radius: 0.4rem 0.4rem 0.4rem 0px;
                box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
                position: relative;
                z-index: 2;
                padding: 1.5rem 2rem;

                &::after {
                  content: '';
                  position: absolute;
                  bottom: -13px;
                  left: 0;
                  width: 0;
                  height: 0;
                  border-top: 50px solid transparent;
                  border-bottom: 50px solid transparent;
                  border-left: 80px solid colors.$mainBackgroundColor;
                  z-index: -1;
                }

                .contact-review-box__stars-container {
                  display: flex;
                  justify-content: center;
                  margin-bottom: 2rem;

                  .contact-review-box__star-icon {
                    width: 14px;
                    color: colors.$contactStarColor;
                  }
                }

                .contact-review-box__text-content {
                  font-size: 1.6rem;
                  text-align: center;

                  .contact-review-box__review {
                    font-weight: 300;
                    font-style: italic;
                    min-height: 90px;
                  }

                  .contact-review-box__author,
                  .contact-review-box__position {
                    font-weight: 700;
                  }

                  .contact-review-box__position {
                    margin-bottom: 2rem;
                  }
                }

                .g2-box {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.5rem;

                  .g2-box__logo {
                    border-radius: 50%;
                    width: 19px;
                    height: 19px;
                  }

                  .g2-box__details {
                    font-size: 1rem;
                    color: colors.$secondaryFontColor;

                    .g2-box__link {
                      color: #0d6cff;
                    }
                  }
                }
              }
            }
          }
        }

        .cux-contact-details {
          width: 100%;
          display: flex;
          justify-content: center;

          .cux-contact-details-container {
            width: 100%;
            background-color: colors.$contactPageBoxColor;
            max-width: 310px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3rem 0 1.5rem 0;
            filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.1));
            border-radius: 0.6rem;

            .cux-contact-details__icon-container {
              height: 90px;

              .cux-contact-details__icon {
                height: 67px;
                color: colors.$secondThemeColor;
              }
            }

            .cux-contact-details__title {
              font-size: 2rem;
              color: colors.$mainFontColor;
              font-weight: 700;
              text-align: center;
              margin-bottom: 1.5rem;
            }

            .cux-contact-details__contact-details {
              .cux-contact-details__contact-details-text {
                font-size: 1.6rem;
                color: colors.$mainFontColor;
                text-align: center;

                span:hover {
                  transition: 0.2s ease-in-out;
                  color: colors.$secondThemeColor;
                  cursor: pointer;
                }
              }
            }

            .cux-contact-details__copy-icon-button {
              border: none;
              background-color: transparent;
              margin-top: 1rem;

              &:hover {
                cursor: pointer;
              }

              .cux-contact-details__copy-icon {
                width: 18px;
                color: colors.$secondThemeColor;
              }
            }
          }
        }
      }
    }
  }
}
