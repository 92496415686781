@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.features-for-who-single {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  color: colors.$mainFontColor;

  @include mixins.md {
    padding: 10rem 0 15rem 0;
  }

  .features-for-who-single-container {
    width: 90vw;
    height: 100%;
    max-width: 1200px;

    @include mixins.md {
      gap: 5rem;
    }

    .features-for-who-single__header {
      margin-bottom: 4rem;

      .features-for-who-single__title {
        color: colors.$mainFontColor;
        font-size: clamp(2rem, 2.5rem, 2.2rem);
        font-weight: 700;
        text-align: center;
      }
    }

    .features-for-who-single__features-for-who-single-list-container {
      width: 100%;
      height: 100%;

      @include mixins.md {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }

      .for-who-single-item {
        margin-bottom: 2rem;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 4.5rem 1rem;
        border-radius: 0.1rem;

        @include mixins.md {
          margin-bottom: 0;
          width: 50%;
          flex-direction: row;
        }

        .for-who-single-item__icon-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          height: 55px;

          @include mixins.md {
            width: 25%;
            flex-direction: column;
            height: 100%;
            align-items: center;
          }

          .for-who-single-item__icon {
            width: 61px;
            color: colors.$secondThemeColor;

            @include mixins.md {
              width: 50px;
            }
          }
        }

        .for-who-single-item__title-container {
          height: 55px;
          margin: 3rem 0 2rem 0;

          .for-who-single-item__title {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
          }
        }
        .for-who-single-item__checked-list-text-container {
          .for-who-single-item__checked-list-title {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            margin: 3rem 0 4rem 0;

            @include mixins.md {
              text-align: left;
              margin: 0 0 2rem 0;
            }
          }

          .for-who-single-item__checked-list {
            align-self: flex-start;
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;

            .for-who-single-item__checked-list-icon {
              width: 18px;
              height: 14px;
            }

            .for-who-single-item__checked-list-text {
              font-size: clamp(1.2rem, 2vw, 1.6rem);
              font-weight: 300;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
