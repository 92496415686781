@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.page-not-found {
  color: colors.$mainFontColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .page-not-found-container {
    width: 90%;
    margin: 8rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;

    @include mixins.md {
      flex-direction: row;
      position: relative;
    }

    .page-not-found__content {
      display: flex;
      width: 90%;
      justify-content: center;
      margin: 0 0 5rem 0;

      @include mixins.md {
        justify-content: flex-start;
      }

      .page-not-found__content-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mixins.md {
          width: 100%;
        }

        .page-not-found__title-container {
          display: flex;
          align-items: center;
          gap: 1.5rem;

          .page-not-found__title-icon {
            width: clamp(1.9rem, 2.5vw, 3.4rem);
            color: colors.$secondThemeColor;
          }

          .page-not-found__title {
            font-size: clamp(2rem, 2.5vw, 3rem);
          }
        }

        .page-not-found__text {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;
          margin: 3rem 0 2rem 0;
        }

        @include mixins.md {
          text-align: left;
        }
        @include mixins.lg {
          margin: 7rem 0;
        }
      }
    }
    .page-not-found__content-icon-container {
      width: 100%;
      display: flex;
      justify-content: center;

      @include mixins.md {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .page-not-found__content-icon {
          width: 50%;
          color: colors.$secondThemeColor;
        }
      }
    }

    .page-not-found__content-buttons {
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      width: 100%;
      gap: 1.5rem;

      @include mixins.md {
        width: 65%;
        flex-direction: row;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      @include mixins.lg {
        position: absolute;
        bottom: 15%;
        left: 0;
      }

      .content-buttons__button {
        width: 100%;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 600;
        border: none;
        border-radius: 0.4rem;
        border: 1px solid colors.$secondThemeColor;
        background-color: transparent;

        .content-buttons__button-link {
          display: block;
          width: 100%;
          padding: 0.6rem 0;
        }
      }

      .content-buttons__button--homepage {
        background-color: colors.$secondThemeColor;
        color: colors.$mainBackgroundColor;
      }

      .content-buttons__button--kb {
        background-color: colors.$mainBackgroundColor;
        color: colors.$secondThemeColor;
      }

      .content-buttons__button--support {
        background-color: colors.$mainBackgroundColor;
        color: colors.$secondThemeColor;
        border: 1px solid colors.$boxesBorderColor;

        .content-buttons__button-link {
          text-decoration: underline;
        }
      }
    }
  }
}
