@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.navbar {
  background-color: colors.$mainBackgroundColor;
  border-bottom: 1px solid colors.$navbarDropdownBorderTopColor;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .navbar-container {
    align-items: center;
    background-color: colors.$mainBackgroundColor;
    display: flex;
    height: 80px;
    justify-content: space-between;
    max-width: 1200px;
    width: 90%;

    .navbar-container__logo {
      width: 115px;
      height: 100px;
    }

    .navbar__hamburger-menu {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;

      .hamburger-menu__hamburger-icon--active {
        color: colors.$navbarHamburgerIconColor;
        height: 50%;
        width: auto;

        &:hover {
          cursor: pointer;
        }
      }

      .hamburger-menu__hamburger-icon--hidden {
        display: none;
      }

      .hamburger-menu__close-icon--active {
        -webkit-animation: showCloseIcon 300ms ease-in-out forwards;
        animation: showCloseIcon 300ms ease-in-out forwards;
        height: 35px;
        position: fixed;
        right: 25px;
        top: 25px;
        width: 35px;
        z-index: 1;
        &:hover {
          cursor: pointer;
        }
      }

      .hamburger-menu__close-icon--hidden {
        display: none;
      }

      @include mixins.xl {
        display: none;
      }
    }

    .navbar__menu {
      display: flex;
      height: 100vh;
      opacity: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 0;

      @include mixins.xl {
        align-items: center;
        display: flex;
        gap: 3rem;
        height: 100%;
        justify-content: flex-end;
        opacity: 1;
        position: static;
        width: 100%;
        z-index: 1;
      }

      @include mixins.xl {
        .menu__item {
          color: colors.$mainFontColor;
          font-size: 1.6rem;
          font-weight: 600;
          transition: 0.2s ease-in-out;

          &:hover {
            color: colors.$secondThemeColor;
          }

          .menu__item__item-link {
            padding: 40px 0;

            &:hover {
              cursor: pointer;
            }
          }

          .menu__item--button {
            background-color: colors.$secondThemeColor;
            border-radius: 0.4rem;
            border: none;
            display: flex;
            font-family: 'Open Sans', sans-serif;
            padding: 1rem;
            min-width: 100px;
            border: 1px solid colors.$secondThemeColor;

            &:hover {
              box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
              color: colors.$mainBackgroundColor;
              cursor: pointer;
            }

            .menu__item--button-text {
              color: colors.$mainBackgroundColor;
              font-size: 1.4rem;
              font-weight: 600;
              text-transform: uppercase;
              &:hover {
                color: colors.$mainBackgroundColor;
              }
            }
          }

          .menu__item--login-button {
            background-color: #fc2a65;
            border-radius: 0.4rem;
            border: none;
            display: flex;
            font-family: 'Open Sans', sans-serif;
            justify-content: center;
            padding: 1rem;
            min-width: 100px;
            background-color: colors.$mainBackgroundColor;
            border: 1px solid colors.$secondThemeColor;
            &:hover {
              box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
              color: colors.$mainBackgroundColor;
              cursor: pointer;
            }

            .menu__item--login-button-text {
              color: colors.$mainBackgroundColor;
              font-size: 1.4rem;
              font-weight: 600;
              text-transform: uppercase;
              color: colors.$secondThemeColor;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      @include mixins.xl {
        .menu__item--dropdown__item-container {
          align-items: center;
          display: flex;
          font-size: 1.6rem;
          gap: 1rem;

          .menu__item--dropdown__item-link {
            padding: 40px 0;
            line-height: 0;
            &:hover {
              cursor: pointer;
            }
          }

          .menu__item--dropdown__arrow-icon--active {
            height: 15px;
            transform: rotate(180deg);
            transition: 0.3s ease-in-out;
            width: 15px;
          }

          .menu__item--dropdown__arrow-icon {
            height: 15px;
            transition: 0.3s ease-in-out;
            width: 15px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .menu__dropdown {
          display: none;
        }

        .dropdown--active {
          align-items: center;
          -webkit-animation: showDropdown 300ms ease-in-out forwards;
          animation: showDropdown 300ms ease-in-out forwards;
          background-color: colors.$mainBackgroundColor;
          border-bottom: 1px solid colors.$navbarDropdownBorderTopColor;
          display: flex;
          gap: 3rem;
          height: auto;
          justify-content: center;
          left: 0;
          padding: 2rem;
          position: absolute;
          text-align: center;
          top: 100%;
          width: 100%;

          .dropdown-container {
            align-content: flex-start;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            height: 100%;
            max-width: 1200px;
            width: 90%;

            .dropdown__item {
              color: colors.$mainFontColor;
              height: 100%;
              transition: 0.3s ease-in-out;
              width: calc((95% / 3));
              &:hover {
                color: colors.$secondThemeColor;
                cursor: pointer;
              }

              .dropdown__item-container {
                align-items: center;
                display: flex;
                gap: 1rem;
                height: 150px;
                width: 100%;

                .dropdown__item-icon {
                  color: colors.$secondThemeColor;
                  width: 5rem;
                }

                .dropdown__item-text-container {
                  display: flex;
                  flex-direction: column;
                  text-align: start;
                  width: 100%;

                  .dropdown__item-description {
                    color: colors.$navbarDropdownDescriptionFontColor;
                    font-size: 1.4rem;
                    font-weight: 300;
                    width: 100%;
                    padding-right: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar__menu--active {
      transition: all 0.2s ease-in-out;
      overflow: visible;
      display: flex;
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      right: 0;
      opacity: 1;
      background-color: colors.$mainBackgroundColor;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-weight: 200;
      font-size: 1.6rem;
      gap: 1.5rem;

      .menu__item {
        font-weight: 500;
        .menu__item--button {
          background-color: colors.$secondThemeColor;
          border: none;
          padding: 1rem 1.5rem;
          color: colors.$mainBackgroundColor;
          border-radius: 0.5rem;

          .menu__item--button-text {
            font-weight: 200;
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 600;
            font-family: 'Open Sans', sans-serif;
          }
        }
        .menu__item--login-button {
          background-color: colors.$mainBackgroundColor;
          border: none;
          border: 1px solid colors.$secondThemeColor;
          padding: 1rem 1.5rem;
          color: colors.$mainBackgroundColor;
          border-radius: 0.5rem;

          .menu__item--login-button-text {
            font-weight: 200;
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 600;
            font-family: 'Open Sans', sans-serif;
            min-width: 120px;
            color: colors.$secondThemeColor;
            width: 100%;
            height: 100%;
            padding: 1rem 2rem;
          }
        }
      }

      .menu__item--dropdown {
        .menu__item--dropdown__item-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          font-weight: 500;
          .menu__item--dropdown__arrow-icon--active {
            width: 25px;
            height: 25px;
            transform: rotate(180deg);
            transition: 0.3s ease-in-out;
          }
          .menu__item--dropdown__arrow-icon {
            width: 25px;
            height: 25px;
            transition: 0.3s ease-in-out;
          }

          &:hover {
            cursor: pointer;
          }
        }
        .menu__dropdown {
          display: none;
        }
        .dropdown--active {
          display: flex;
          width: 100%;
          height: 100%;

          .dropdown-container {
            display: flex;
            flex-direction: column;

            .dropdown__item-icon {
              display: none;
            }

            .dropdown__item-text-container {
              display: flex;
              justify-content: center;

              .dropdown__item-link {
                display: block;
                padding: 1rem 0;
                &:hover {
                  cursor: pointer;
                }
              }
              .dropdown__item-link {
                font-weight: 300;
              }
              .dropdown__item-description {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes showCloseIcon {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  80% {
    transform: scaleY(1.1);
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes showDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
