@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.features__rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 1200px;

  @include mixins.md {
    align-items: flex-start;
  }

  .features__rating-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;

    @include mixins.md {
      justify-content: flex-start;
    }

    .rating-g2 {
      display: flex;
      align-items: center;
      line-height: 90%;

      .rating-g2__logo {
        width: 30px;
        height: 30px;
        margin-right: 0.8rem;
      }

      .rating-g2__rating-container {
        .rating-g2__company-name {
          font-size: 1.2rem;
          font-weight: 700;
        }

        .rating-g2__stars-container {
          display: flex;
          margin-top: 0.5rem;

          .rating-g2__stars-container--g2 {
            width: 12px;
            color: #ff492b;
          }

          .rating-g2__rating-numbers {
            margin-left: 1rem;
            font-size: 1.2rem;
            font-weight: 700;
          }
        }
      }
    }

    .rating-capterra {
      display: flex;
      align-items: center;
      line-height: 90%;
      margin-left: 3rem;

      .rating-capterra__logo {
        width: 25px;
        height: 25px;
        margin-right: 0.8rem;
      }

      .rating-capterra__rating-container {
        .rating-capterra__company-name {
          font-size: 1.2rem;
          font-weight: 700;
        }

        .rating-capterra__stars-container {
          display: flex;
          margin-top: 0.5rem;

          .rating-capterra__stars-container--g2 {
            width: 12px;
            color: #044d80;
          }

          .rating-capterra__rating-numbers {
            margin-left: 1rem;
            font-size: 1.2rem;
            font-weight: 700;
          }
        }
      }
    }
  }
  .features__rating-video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin-top: 7rem;

    .features__rating-video {
      position: absolute;
      width: 100%;
      height: 95%;
      border: 2px solid colors.$borderBottomLinesColor;
    }
  }
  .features__rating-button-video {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .features__rating-button {
    background-color: colors.$secondThemeColor;
    border: none;
    border: 2px solid transparent;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0.5rem;
    color: colors.$mainBackgroundColor;
    margin: 4rem 0 3rem 0;
    transition: 0.2s ease-in-out;

    &:hover {
      color: colors.$secondThemeColor;
      background-color: colors.$mainBackgroundColor;
      border: 2px solid colors.$secondThemeColor;
      cursor: pointer;
    }

    .features__rating-link {
      display: block;
      font-weight: 700;
      letter-spacing: 0.1rem;
      width: 100%;
      height: 100%;
      padding: 1rem 1rem;
      text-transform: uppercase;
    }
  }
}
