@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.why-cux-slider {
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 1rem 0 6rem 0;
  background: linear-gradient(
    180deg,
    colors.$mainBackgroundColor 0%,
    colors.$mainBackgroundColor 55%,
    colors.$pricingQuestionsHeaderBackgroundColor 55%,
    colors.$pricingQuestionsHeaderBackgroundColor 100%
  );

  .why-cux-slider-container {
    width: 90%;
    max-width: 1200px;
    // padding: 3rem 0;
    background-color: colors.$mainBackgroundColor;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
    position: relative;

    @include mixins.md {
      // padding: 5rem 0 8rem 0;
    }

    &::before {
      content: '';
      width: 6%;
      height: 100%;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &::after {
      content: '';
      width: 6%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }

    .why-cux-slider__text-content {
      padding: 0 2.5rem;

      @include mixins.md {
        margin-bottom: 1rem;
      }

      .why-cux-slider__text {
        text-align: center;
        font-size: clamp(1.6rem, 2vw, 1.8rem);
        font-weight: 300;
        color: colors.$mainFontColor;
        margin-bottom: 4rem;

        .why-cux-slider__text-special {
          font-weight: 700;
        }
      }

      .why-cux-slider__text--bottom {
        padding: 0 2rem;

        .why-cux-slider__text-special {
          color: colors.$secondThemeColor;
        }
      }
    }

    .why-cux-slider__slider {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: hidden;

      .why-cux-slider__slider-container {
        width: auto;
        position: relative;
        margin-bottom: 3rem;

        .why-cux-slider__slider-handler {
          animation: scrollAnimation 45s linear infinite;
          display: flex;
          align-items: center;
          width: calc(276px * 15);

          .slider-handler__slide {
            width: 276px;
            height: 96px;

            .slider-handler__logo {
              width: 276px;
              height: 96px;
            }
          }
        }
      }
    }
  }
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-276px * 6));
  }
}
