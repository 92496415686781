@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.become-partner-perks {
  background-color: colors.$becomePartnerFaqPerksBackground;
  color: colors.$mainFontColor;
  display: flex;
  justify-content: center;
  width: 100%;

  .become-partner-perks-container {
    margin-bottom: 5rem;
    max-width: 1200px;
    width: 90%;

    .become-partner-perks__title {
      font-size: 2rem;
      font-weight: 700;
      margin: 4rem 0;
      text-align: center;
      font-size: clamp(1.8rem, 2.5vw, 2.2rem);
    }

    .become-partner-perks-accordion {
      font-size: 1.6rem;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .become-partner-perks-accordion__container {
        background-color: colors.$mainBackgroundColor;
        box-shadow: 0px 4px 32px -9px rgba(38, 38, 38, 0.1);
        width: 100%;

        .become-partner-perks-accordion__question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .become-partner-perks-accordion__question-container {
            display: flex;
            align-items: center;
            gap: 2rem;
            width: 100%;
            height: 100%;
            padding: 3.5rem 2rem;

            .become-partner-perks-accordion__icon {
              width: 42px;
              color: colors.$secondThemeColor;
            }

            .become-partner-perks-accordion__question-text {
              font-size: clamp(1.6rem, 2.5vw, 2rem);
              font-weight: 700;
            }

            &:hover {
              cursor: pointer;
            }

            .become-partner-perks-accordion__question-subtitle {
              display: none;
              height: 0;
            }

            .become-partner-perks-accordion__question-subtitle--active {
              @include mixins.md {
                display: block;
                height: 100%;
                animation: showAnswer 0.3s ease-in-out forwards;
                font-size: 1.6rem;
                font-weight: 300;
                color: colors.$secondaryFontColor;
              }
            }
          }
          .become-partner-perks-accordion__question-icon {
            width: 2.5rem;
            margin-right: 2rem;
            transition: 0.2s ease-in-out;
          }

          .become-partner-perks-accordion__question-icon--active {
            width: 2.5rem;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }

          &:hover {
            cursor: pointer;
            transition: 0.2s linear;
            color: colors.$secondThemeColor;
          }
        }

        .become-partner-perks-accordion__perks-dropdown-container {
          animation: showAnswer 0.3s ease-in-out forwards;
          color: colors.$mainFontColor;
          background-color: colors.$becomePartnerFaqPerksBackground;
          display: none;

          .perks-dropdown-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 1.5rem;
            gap: 1rem;
            background-color: colors.$mainBackgroundColor;
            box-shadow: 0px 4px 32px -9px rgba(38, 38, 38, 0.1);

            .perks-dropdown-item__icon {
              width: 30px;
              height: 30px;
              color: colors.$firstThemeColor;
            }

            .perks-dropdown-item__icon--star {
              color: colors.$secondThemeColor;
            }

            .perks-dropdown-item__text {
              font-size: 1.6rem;
              font-weight: 300;
              width: 90%;
            }
          }

          .perks-dropdown-container__checklist-summary {
            font-size: 1.4rem;
            font-weight: 300;
            padding: 2rem;
            font-style: italic;
          }
        }

        .become-partner-perks-accordion__perks-dropdown-container--active {
          animation: showAnswer 0.3s ease-in-out forwards;
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      .become-partner-perks__perks-return-container {
        .perks-return__top {
          text-align: center;
          font-size: clamp(1.8rem, 2.5vw, 2.2rem);
          font-weight: 700;
          margin: 8rem 0 4rem 0;
        }

        .perks-return {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;

          @include mixins.md {
            flex-direction: row;
            justify-content: space-between;
          }

          .perks-return-item {
            width: 100%;
            height: 265px;
            background-color: colors.$mainBackgroundColor;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            box-shadow: 0px 4px 32px -9px rgba(38, 38, 38, 0.1);
            padding: 5rem;
            text-align: center;

            @include mixins.md {
              padding: 1.5rem;
              justify-content: flex-start;
            }
          }

          .perks-return-item__icon-container {
            @include mixins.md {
              height: 100px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
            .perks-return-item__icon {
              color: colors.$firstThemeColor;
              height: 61px;
            }
          }

          .perks-return-item__text-container {
            @include mixins.lg {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
}

@keyframes showAnswer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
