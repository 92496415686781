@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.blog-article-preview {
  color: colors.$mainFontColor;
  padding: 0.5rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.5rem;
  transition: 0.3s ease-in-out;
  border: 1px solid transparent;
  height: fit-content;
  padding: 1rem;

  &:hover {
    cursor: pointer;
    border: 1px solid colors.$boxesBorderColor;
    background-color: colors.$blogPreviewHoverColor;
  }
  &:hover .blog-article-preview__title {
    transition: 0.4s ease-in-out;
    color: colors.$secondThemeColor;
  }

  .blog-article-preview-container {
    height: 100%;
    // min-height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .blog-article-preview__image-container {
      width: 100%;

      .blog-article-preview__categories {
        margin-bottom: 1rem;
        color: colors.$firstThemeColor;
        width: 100%;
        display: flex;

        .blog-article-preview__categories-text {
          font-weight: 300;
          text-transform: uppercase;
          font-size: 1.4rem;
          width: auto;
          padding: 0.4rem;
          border-radius: 0.5rem;
          transition: 0.3 ease-in-out;
          background-color: transparent;

          &:hover {
            background-color: colors.$firstThemeColor;
            color: colors.$mainBackgroundColor;
          }
        }
      }

      .blog-article-preview__picture {
        border-radius: 0.5rem;
        width: 100%;
        height: 100%;

        .blog-article-preview__image {
          width: 100%;
          height: auto;
          border-radius: 0.5rem;
        }
      }
    }

    .blog-article-preview__data-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .blog-article-preview__preview-data {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        justify-content: space-between;

        .preview-data__item {
          display: flex;
          gap: 1rem;
        }
        .preview-data__icon {
          width: 1.2rem;
          color: colors.$secondThemeColor;
        }
        .preview-data__text {
          font-weight: 400;
          font-size: clamp(1.3rem, 2vw, 1.4rem);
          color: colors.$secondaryFontColor;
        }
      }

      .blog-article-preview__title-container {
        width: 100%;
        height: fit-content;
        margin: 1rem 0;

        @include mixins.lg {
          min-height: 100px;
        }
        @include mixins.xl {
          min-height: 80px;
        }
        @include mixins.xxl {
          min-height: 60px;
        }
        .blog-article-preview__title {
          font-size: 1.5rem;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .blog-article-preview__body-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mixins.md {
          height: 100%;
          min-height: 140px;
        }
        @include mixins.lg {
          min-height: 260px;
        }
        @include mixins.xl {
          min-height: 210px;
        }
        @include mixins.xxl {
          min-height: 185px;
        }

        .blog-article-preview__body {
          font-weight: 200;
          width: 100%;
          height: 100%;
          line-height: 155%;
        }

        .blog-article-preview__body--read-more-container {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: colors.$secondThemeColor;
          margin-top: 1.5rem;

          .blog-article-preview__body--read-more {
            font-size: 1.4rem;
            font-weight: 300;
          }

          .blog-article-preview__body--read-more-icon {
            width: 5px;
            height: 10px;
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
}

@include mixins.lg {
  .blog-article-preview-link:nth-child(1) {
    grid-column: span 3;

    .blog-article-preview-container {
      display: flex;
      flex-direction: row;
      min-height: auto;
      gap: 3rem;

      .blog-article-preview__image-container {
        width: 60%;

        .blog-article-preview__picture {
          .blog-article-preview__image {
            width: 100%;
            height: auto;

            border-radius: 0.5rem;
          }
        }
      }
      .blog-article-preview__data-container {
        width: 40%;
        margin-top: 3rem;

        .blog-article-preview__title-container {
          height: fit-content;
          min-height: 60px;
        }

        .blog-article-preview__body-container {
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          // height: 100%;

          // @include mixins.md {
          //   height: 100%;
          //   min-height: 140px;
          // }
          @include mixins.lg {
            min-height: 60px;
          }

          .blog-article-preview__body {
            height: 100%;
          }

          .blog-article-preview__body--read-more-container {
            height: fit-content;
          }
        }
      }
    }
  }
}
