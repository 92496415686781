@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

main {
  width: 100%;
  height: 100%;

  .hero {
    background-color: colors.$mainBackgroundColor;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    @include mixins.lg {
      padding: 14rem 0 9rem 0;
    }

    .hero-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 100%;
      max-width: 1200px;

      @include mixins.lg {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .hero__text-box-wrapper {
      width: 100%;
      @include mixins.xl {
        width: 60%;
      }
    }
    /* MEET WITH EXPERT BUTTON */
    .hero__meet_with_expert-container {
      margin: 2rem 0;

      @include mixins.mobileSm {
        display: flex;
        justify-content: center;
      }

      @include mixins.md {
        display: flex;
        justify-content: center;
      }

      @include mixins.lg {
        margin: 4rem 0 1rem 0;
        justify-content: flex-start;
      }
    }

    .hero__main-text {
      text-align: center;
      grid-area: text-box;

      @include mixins.lg {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      @include mixins.xl {
        width: 100%;
      }

      .hero__title {
        font-size: clamp(2.6rem, 4vw, 4.2rem);
        font-weight: 800;
        margin-top: 7rem;
        color: colors.$mainFontColor;
        animation: showTitle 0.6s ease-in-out forwards;

        @include mixins.lg {
          margin-top: 0;
        }
      }

      .hero__subtitle {
        font-size: clamp(1.2rem, 2.5vw, 1.8rem);
        font-weight: 300;
        margin: 2rem 0;
        color: colors.$mainFontColor;
        animation: showSubtitle 0.8s ease-in-out forwards;

        @include mixins.lg {
          margin: 4rem 0 1rem 0;
        }
      }
    }
    .hero__image-container {
      width: 100%;
      height: 100%;
      margin-top: 3rem;
      justify-content: center;
      display: none;

      @include mixins.xl {
        width: 40%;
        margin-top: 0;
        display: flex;
      }

      .hero__image {
        object-fit: contain;
      }
    }
  }
}

@keyframes showTitle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes showSubtitle {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hero__title--transition-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  @include mixins.lg {
    display: inline-block;
    margin-left: 1rem;
  }
}

/* MEET WITH EXPERT BUTTON CONTAINER */
.meet_with_expert__button-container {
  @include mixins.mobileSm {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
  }

  @include mixins.md {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
  }

  @include mixins.lg {
    margin-top: 3rem;
    justify-content: flex-start;
  }
}
