@use '../../../../../../../styles/colors.scss';
@use '../../../../../../../styles/mixins.scss';

.pricing__slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;

  .slider {
    color: #fc2a65;
    width: 90%;
  }
  .pricing__slider-value {
    color: #fc2a65;
    font-weight: 500;
    font-size: clamp(1.5rem, 2.5vw, 1.9rem);
    margin: 2rem 0 1rem 0;
    text-align: left;

    @include mixins.lg {
      margin: 2rem 0;
    }
  }
}
