@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.subscribed {
  color: colors.$mainFontColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .subscribed-container {
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .subscribed__content {
      display: flex;
      width: 90%;
      max-width: 1200px;
      justify-content: center;
      margin: 0 0 5rem 0;

      .subscribed__content-text {
        width: 100%;
        text-align: center;

        .subscribed__title {
          font-size: clamp(2.4rem, 3vw, 4rem);
          margin-bottom: 2rem;
        }

        .subscribed__text {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;
          margin-bottom: 2rem;
        }

        .subscribed__text--unsub {
          font-size: clamp(1.6rem, 2vw, 1.8rem);
          font-weight: 300;

          a {
            color: colors.$secondThemeColor;
          }
        }

        @include mixins.md {
          text-align: left;
        }
        @include mixins.lg {
          margin: 7rem 0;
        }
      }
      .subscribed__content-icon-container {
        display: none;

        @include mixins.md {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .subscribed__content-icon {
            width: 40%;
            color: colors.$secondThemeColor;
          }
        }
      }
    }

    .subscribed__expectations {
      background-color: colors.$subscribedExpectationsBackgroundcolor;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 5rem 0;

      @include mixins.lg {
        padding: 9rem 0;
      }

      .subscribed__expectations-container {
        width: 90%;
        max-width: 1200px;

        .subscribed__expectations-title {
          font-size: clamp(1.6rem, 2vw, 2.2rem);
          margin-bottom: 3rem;
        }

        .subscribed__expectations-list {
          font-size: clamp(1.4rem, 2vw, 1.6rem);
          font-weight: 300;

          .expectations-list__item {
            display: flex;
            gap: 1rem;
            margin-bottom: 2rem;

            .expectations-list__item-icon {
              color: colors.$secondThemeColor;
              min-width: 25px;
              max-width: 25px;
            }
            .expectations-list__item-text {
              span {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
