@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.solutions-benefits {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: colors.$firstThemeColor;

  .solutions-benefits-container {
    width: 90vw;
    max-width: 1200px;
    padding: 7rem 0 10rem 0;

    .solutions-benefits__title {
      font-size: clamp(1.6rem, 2.5vw, 2.8rem);
      color: colors.$mainBackgroundColor;
      text-align: center;
      margin-bottom: 4rem;
    }

    .solutions-benefits-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 4rem;
      justify-content: center;

      @include mixins.md {
        flex-direction: row;
      }

      .solutions-benefits-list-item {
        background-color: colors.$mainBackgroundColor;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
        padding: 4rem 2rem;
        gap: 2rem;
        border-radius: 0.8rem;

        @include mixins.md {
          min-height: 250px;
          width: calc(100% / 3 - 4rem);
          flex-wrap: wrap;
        }

        @include mixins.lg {
          min-height: 230px;
        }

        @include mixins.xl {
          min-height: 210px;
        }

        .solutions-benefits-list-item__icon-container {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;

          .solutions-benefits-list-item__icon {
            width: 50px;
            height: 50px;
            color: colors.$secondThemeColor;
          }
        }

        .solutions-benefits-list-item__content {
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          text-align: center;
          font-weight: 300;

          span {
            font-weight: 600;
          }
        }
      }
    }

    .solutions-benefits-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10rem;

      .solutions-benefits-button__button {
        background-color: colors.$secondThemeColor;
        border: none;
        border: 2px solid transparent;
        font-family: 'Open Sans', sans-serif;
        border-radius: 0.5rem;
        color: colors.$mainBackgroundColor;
        transition: 0.2s ease-in-out;

        &:hover {
          color: colors.$secondThemeColor;
          background-color: colors.$mainBackgroundColor;
          border: 2px solid colors.$secondThemeColor;
          cursor: pointer;
        }

        .solutions-benefits-button__button-link {
          display: block;
          font-weight: 700;
          letter-spacing: 0.1rem;
          width: 100%;
          height: 100%;
          padding: 1rem 1rem;
          text-transform: uppercase;
        }
      }
    }
  }
}
