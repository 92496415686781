@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.blog-articles-page {
  width: 100%;
  display: flex;
  // justify-content: space-between;

  .blog-articles-page__breadcrumbs {
    font-size: clamp(1.3rem, 2vw, 1.5rem);
    font-weight: 300;
    margin: 4rem 0 2rem 0;

    @include mixins.lg {
      margin-top: 0;
    }

    .blog-articles-page__breadcrumbs-link {
      color: colors.$secondThemeColor;
    }

    .blog-articles-page__breadcrumbs-text {
      text-transform: capitalize;
    }
  }

  .blog-articles-page__header--category {
    .blog-articles-page__header-text--category {
      color: colors.$mainFontColor;
      font-weight: 700;
      font-size: clamp(2.4rem, 3.5vw, 4.2rem);
      text-align: center;
      margin-top: 3rem;
      text-transform: capitalize;

      @include mixins.lg {
        margin-top: 0;
        margin-bottom: 2rem;
        text-align: left;
      }

      span {
        color: colors.$secondThemeColor;
      }
    }
  }

  .blog-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;

    @include mixins.md {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    @include mixins.lg {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .blog-list__no-results {
      grid-column: span 3;
      display: flex;
      margin-top: 5rem;
      text-align: center;

      .blog-list__no-results-text {
        font-size: clamp(1.8rem, 3vw, 3.2rem);
        font-weight: 500;
        width: 100%;
      }
    }
  }
}
