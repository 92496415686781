@use '../../styles/mixins.scss';
@use '../../styles/colors.scss';

.why-cux-page {
  width: 100%;
  height: 100%;
  background-color: colors.$mainBackgroundColor;
  display: flex;
  justify-content: center;

  .why-cux-page-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 20rem 0;

    @include mixins.lg {
      flex-direction: row;
      align-items: flex-start;
      padding: 17rem 0 20rem 0;
    }

    .why-cux-page__links-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      width: 100%;
      text-align: center;
      margin-bottom: 5rem;
      flex-wrap: wrap;

      @include mixins.lg {
        text-align: left;
        flex-direction: row;
        justify-content: space-between;
        margin: 10rem 0 0 0;
      }

      .why-cux-page__link {
        width: 100%;
        font-size: clamp(1.6rem, 2.5vw, 2.2rem);
        font-weight: 300;
        border: 1px solid colors.$boxesBorderColor;
        border-radius: 0.8rem;
        padding: 2rem 1rem;
        color: colors.$mainFontColor;
        text-align: center;
        transition: 0.2s ease-in-out;

        @include mixins.lg {
          width: calc(100% / 3.3);
          margin-bottom: 3rem;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$blogPreviewHoverColor;
          color: colors.$secondThemeColor;
          border: 1px solid colors.$secondThemeColor;
        }
      }
    }

    .why-cux-page__content {
      width: 100%;
      color: colors.$mainFontColor;

      .why-cux-page__content-container {
        .why-cux-page__content-breadcrumbs {
          text-align: left;
          font-size: clamp(1.3rem, 2vw, 1.5rem);
          font-weight: 300;
          margin: 1rem 0 2rem 0;

          @include mixins.lg {
            margin-top: 0;
          }

          .why-cux-page__content-breadcrumbs-link {
            color: colors.$secondThemeColor;
          }
        }
        .why-cux-page__header {
          display: flex;
          align-items: center;
          margin-bottom: 5rem;
          gap: 2rem;

          .why-cux-page__header-icon {
            width: 30px;
            height: 30px;
            color: colors.$secondThemeColor;
          }

          .why-cux-page__header-text {
            font-size: 4rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
