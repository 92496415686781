@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.book-demo {
  color: colors.$mainFontColor;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .book-demo-container {
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .breadcrumbs {
      width: 90%;
      max-width: 1200px;
    }

    .book-demo__title {
      text-align: center;
      font-size: clamp(2.4rem, 3vw, 4rem);
      margin: 2rem 0;
      width: 90%;

      span {
        color: colors.$secondThemeColor;
      }
    }

    .book-demo__subtitle {
      font-size: clamp(1.6rem, 2vw, 1.8rem);
      font-weight: 300;
      width: 90%;
      text-align: center;
      margin-bottom: 3rem;

      @include mixins.lg {
        margin-bottom: 0;
      }
    }

    .book-demo__calendly-container {
      width: 90%;
      max-width: 1200px;
      height: 100%;
      min-height: 1000px;

      @include mixins.xl {
        min-height: 800px;
      }
    }

    .book-demo__return-home {
      background-color: colors.$secondThemeColor;
      margin: 3rem 0 6rem 0;
      border: none;
      border: 2px solid colors.$secondThemeColor;
      border-radius: 0.4rem;
      transition: 0.2s ease-in-out;

      .book-demo__return-home-link {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 2rem;
        font-weight: 300;
        color: colors.$mainBackgroundColor;
        font-size: clamp(1.6rem, 2vw, 1.8rem);
        text-transform: uppercase;
        border-radius: 0.4rem;
        transition: 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          color: colors.$secondThemeColor;
          background-color: colors.$mainBackgroundColor;
        }
      }
    }
  }
}
